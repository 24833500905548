
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useTeacherService, { useBasicFilterTeacher } from './services/Teacher';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IEntityNameId, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, FormHelperText, Link, ToggleButtonGroup, ToggleButton } from '@mui/material';
import NumberFormat from 'react-number-format';

import { ITeacher, ITeacherSheet, defaultTeacher } from './models/Teacher';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, Enum_REGISTRATION_STATUS, Enum_STUDY_TYPE,
  Enum_DEFAULT_LANGUAGE, Enum_TOWN, Enum_COUNTRY, Enum_PERSON_IDENTIFICATION, Enum_EMPLOYEE_TYPE,
    Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_TEACHER_STATUS, Enum_TEACHER_DIPLOMA, Enum_TEACHER_SPECIALIZATION, Enum_TEACHER_FUNCTION, Enum_TEACHER_SERVICE } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';


import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';
import { ISchoolYear, ISchoolYearClass } from 'features/setup/models/SchoolYear';
import { DatePicker } from '@mui/x-date-pickers';
import { IClassTemplate } from 'features/setup/models/ClassTemplate';
import useClassTemplateService, { useBasicFilterClassTemplate } from 'features/setup/services/ClassTemplate';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { IBilling, defaultBilling } from 'features/finance/models/Billing';
import { IPerson } from 'features/production/models/Person';
import { useBasicFilterPerson } from 'features/production/services/Person';
import { IBillingType } from 'features/setup/models/BillingType';
import { useBasicFilterBillingType } from 'features/setup/services/BillingType';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';
import EnhancedTable from 'components/ui/EnhancedTable';


export const TeacherForm: FC<ITeacher> = (props: ITeacher = defaultTeacher) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();

  const {language: lg} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  const { createTeacher, updateTeacher } = useTeacherService();

  const { getSchoolYears, getSchoolYearClasses } = useSchoolYearService();

  const { getClassTemplates } = useClassTemplateService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const refSchoolYears = useRef<ISchoolYear[]>();

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterTeacher = useBasicFilterTeacher( 
    (event: React.MouseEvent<unknown>, row: ITeacher) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
  const basicFilterPerson = useBasicFilterPerson( 
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, firstName, lastName, birthDate, birthPlace, particularSign} = row;
        
        setValue('personId', id);
        setValue('firstName', firstName);
        setValue('lastName', lastName);  
        setValue('birthDate', birthDate);   
        setValue('birthPlace', birthPlace);                    
        setValue('particularSign', particularSign);  
                          
        setOpenPersonFilter(false);
      }
  );

    
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  
  const emptyFunc = (obj: any) => {}
  
  const methods = useForm<ITeacher>({defaultValues:defaultTeacher});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchBase64Picture = watch('base64Picture');

  const watchStatus = watch('status');
  const watchLastDiploma = watch('lastDiploma');
  const watchSpecialization = watch('specialization');
  const watchServiceCode = watch('serviceCode');
  const watchFunctionCode = watch('functionCode');
  const watchEmployeeType = watch('employeeType');
  

   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ITeacher>,Error,ITeacher>(
      _id>0?updateTeacher:createTeacher, {   
        onSuccess: (data: IResult<ITeacher>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Teacher',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<ITeacher>(['Teacher', _id], () => retrieveEntity('Teacher',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    
    const {data: enumItems, refetch: refetchEnumerationItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Teacher'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_TEACHER_STATUS, Enum_TEACHER_DIPLOMA, Enum_TEACHER_SPECIALIZATION, Enum_TEACHER_SERVICE, Enum_TEACHER_FUNCTION,
          Enum_EMPLOYEE_TYPE, Enum_DEFAULT_LANGUAGE, Enum_COUNTRY, Enum_PERSON_IDENTIFICATION,
              Enum_PERSON_GENDER, Enum_PERSON_CIVILITY ] ));

    const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );

    type TeacherInfoView = 'absence' | 'discipline' | 'observation';
    const [teacherInfoView, setTeacherInfoView] = useState<TeacherInfoView>('absence');
    const handleChangeTeacherInfoView = ( event: React.MouseEvent<HTMLElement>, newView: TeacherInfoView) => {  

      if (newView === null) 
        return;
      
      setTeacherInfoView(newView);           
    }


    const handleClickOpenPerson = async (event: any) => {
      setOpenPersonFilter(true);
    }
        
  
    function openFileDialog() {
      (document as any).getElementById("file-upload").click();
    }
  
    const setFile = (_event: any) => {
      let f = _event.target.files![0];
      var reader = new FileReader();
  
      reader.onload = function () {
  
          var binaryString = reader.result as string;
      
          const base64String = binaryString
                                      .replace('data:', '')
                                      .replace(/^.+,/, '');
  
          setValue("base64Picture", base64String);
          
        };
    
        reader.onerror = function () {
          console.log("File load failed");
        };
    
        reader.readAsDataURL(f);    
    };

    const refEnumItems = useRef<IEnumerationItem[]>([]);    
    useEffect( () => {   
        refEnumItems.current = enumItems || [];
      
    }, [enumItems]);

      useEffect( () => {        
        setCurrentFormNameAtom(t('Teacher'));
        setCurrentBasicTextFilterProps(basicFilterTeacher);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Teacher',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultTeacher);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
        if(!checkEntitySaveAuthorization('Teacher', _id)) {
          setIsSaveLoading(false);
             return;
        }
        
        
          const data = getValues(); 
          if(data.firstName.trim() === '' && data.lastName.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate(data);
      }

    const [selectedAbsenceIds, setSelectedAbsenceIds] = useState<number[]>([]);
    const [selectedDisciplineIds, setSelectedDisciplineIds] = useState<number[]>([]);
    const [selectedObservationIds, setSelectedObservationIds] = useState<number[]>([]);
    
    const actionData = async (event: MouseEvent<HTMLButtonElement>) => {

      const arrDisciplinaries = selectedDisciplineIds.map( id => ({entityName: 'TeacherDisciplinarySheet', entityId: id}) );
      const arrAbsences = selectedAbsenceIds.map( id => ({entityName: 'TeacherAbsenceSheet', entityId: id}) );
      const arrObservations = selectedObservationIds.map( id => ({entityName: 'TeacherObservationSheet', entityId: id}) );
 
      setContextualItems([...arrDisciplinaries, ...arrAbsences, ...arrObservations]);
      openEntityActionDrawer('Teacher', _id, contextualItems);      
    }

    const [teacherDisciplinarySheets, setTeacherDisciplinarySheets] = useState<ITeacherSheet[]>([]);    
    const [teacherAbsenceSheets, setTeacherAbsenceSheets] = useState<ITeacherSheet[]>([]);    
    const [teacherObservationSheets, setTeacherObservationSheets] = useState<ITeacherSheet[]>([]); 


    const [contextualItems, setContextualItems] = useState<IEntityNameId[]>([]);

    const handleTeacherSheetDoubleClick = (event: React.MouseEvent<unknown>,row: ITeacherSheet) => {
      
    } 

    const handleTeacherSheetSelected = (event: React.MouseEvent<unknown>,row: ITeacherSheet) => {
      const {id} = row;
      const arr = contextualItems.filter(x => x.entityName !== 'TeacherSheet');
      //setContextualItems( [...arr, {entityName: 'RegistrationSheet', entityId: id}] );
      setContextualItems( [{entityName: 'TeacherSheet', entityId: id}] );
    }
    
      // const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
      //   openEntityActionDrawer('Teacher', _id);
      // }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={8} lg={8} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 0.5, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} />
                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Regis. Number')} {...register('registrationNumber')} />
                                <Controller control={control}
                                  name='hiringDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Hiring date')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(15% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                    /> )}
                                /> 
                                <Controller name='status' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="status"
                                        label={t('Status')} inputProps={ {readOnly: false}} 
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_TEACHER_STATUS, 
                                                                  `${t('Teacher')} - ${t('Status')}`, `${t('Teacher')} - ${t('Status')}`)}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchStatus) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_TEACHER_STATUS, `${t('Teacher')} - ${t('Status')}`, `${t('Teacher')} - ${t('Status')}`,
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_TEACHER_STATUS && x.code === watchStatus)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        } >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_TEACHER_STATUS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller control={control}
                                  name='statusDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Status date')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(15% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                    /> )}
                                /> 
                                <Controller name='serviceCode' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="serviceCode"
                                        label={t('Service')} inputProps={ {readOnly: false}} 
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_TEACHER_SERVICE, 
                                                                  `${t('Teacher')} - ${t('Service')}`, `${t('Teacher')} - ${t('Service')}`)}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchServiceCode) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_TEACHER_SERVICE, `${t('Teacher')} - ${t('Service')}`, `${t('Teacher')} - ${t('Service')}`,
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_TEACHER_SERVICE && x.code === watchServiceCode)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        } >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_TEACHER_SERVICE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />                                                             
                                <Controller name='employeeType' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="employeeType"
                                        label={t('Empl. type')} inputProps={ {readOnly: false}} 
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_EMPLOYEE_TYPE, 
                                                                  `${t('Employee')} - ${t('Type')}`, `${t('Employee')} - ${t('Type')}`)}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchEmployeeType) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_EMPLOYEE_TYPE, `${t('Employee')} - ${t('Type')}`, `${t('Employee')} - ${t('Type')}`,
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_EMPLOYEE_TYPE && x.code === watchEmployeeType)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        } >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_EMPLOYEE_TYPE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                            </Box>   
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='lastDiploma' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="lastDiploma"
                                        label={t('Last diploma')} inputProps={ {readOnly: false}} 
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_TEACHER_DIPLOMA, 
                                                                  `${t('Diploma')}`, `${t('Diploma')}`)}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchLastDiploma) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_TEACHER_DIPLOMA, `${t('Diploma')}`, `${t('Diploma')}`,
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_TEACHER_DIPLOMA && x.code === watchLastDiploma)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        } >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_TEACHER_DIPLOMA ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller control={control}
                                  name='diplomaDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Diploma date')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(15% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                    /> )}
                                />
                                <Controller name='specialization' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="specialization"
                                        label={t('Specialization')} inputProps={ {readOnly: false}} 
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_TEACHER_SPECIALIZATION, 
                                                                  `${t('Specialization')}`, `${t('Specialization')}`)}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchSpecialization) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_TEACHER_SPECIALIZATION, `${t('Specialization')}`, `${t('Specialization')}`,
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_TEACHER_SPECIALIZATION && x.code === watchSpecialization)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        } >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_TEACHER_SPECIALIZATION ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller name='functionCode' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="functionCode"
                                        label={t('Function')} inputProps={ {readOnly: false}} 
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_TEACHER_FUNCTION, 
                                                                  `${t('Teacher')} - ${t('Function')}`, `${t('Teacher')} - ${t('Function')}`)}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchFunctionCode) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_TEACHER_FUNCTION, `${t('Teacher')} - ${t('Function')}`, `${t('Teacher')} - ${t('Function')}`,
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_TEACHER_FUNCTION && x.code === watchFunctionCode)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        } >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_TEACHER_FUNCTION ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                  label={t('Teacher ?')}
                                  control={
                                  <Controller
                                      name='hasSubject'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                                  <FormControlLabel sx={{width:'calc(15% - 8px)'}}
                                    label={t('Driver ?')}
                                    control={
                                    <Controller
                                        name='isDriver'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                    />} />
                              
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Identity'))} `}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(15% - 8px)'}} id="personId" label={t('Teacher Id')} 
                                {...register('personId')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">                                            
                                      <IconButton color="primary" onClick={handleClickOpenPerson}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton>                                                                                               
                                  </InputAdornment>
                                ) 
                              }} />
                              <TextField sx={{width:'calc(35% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                                {...register(lg.startsWith('fr')?'lastName':'firstName')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                                {...register(lg.startsWith('fr')?'firstName':'lastName')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />  
                              <TextField sx={{width:'calc(15% - 8px)'}} id="particularSign" label={t('Part. sign')} 
                                {...register('particularSign')}  />                              
                                { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {setOpenPersonFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPerson } />
                              </FormDialog> }
                            </Box>                                                   
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='gender' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="gender"
                                        label={t('Gender')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_GENDER ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller name='civility' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="civility"
                                        label={t('Civility')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_CIVILITY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                              <Controller control={control}
                                  name='birthDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Birth date')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    /> )}
                                />
                              <TextField sx={{width:'calc(50% - 8px)'}} id="birthPlace" label={t('Birth place')} 
                                  {...register('birthPlace')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} > 
                              <TextField sx={{width:'calc(15% - 8px)'}} id="portable1" label={`${t('Portable')} 1`} 
                                {...register('portable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                              <TextField sx={{width:'calc(15% - 8px)'}} id="portable2" label={`${t('Portable')} 2`} 
                                {...register('portable2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="email1" label={`${t('Email')} 1`} 
                                {...register('email1')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                              <TextField sx={{width:'calc(35% - 8px)'}} id="email2" label={`${t('Email')} 2`} 
                                {...register('email2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>                                                    
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Stack flexDirection='row'>                
                                <Box sx={{ mt: 1, width: '50%' }} >
                                  <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                                    accept="image/*" />
                                  <div key={'step.id'}>
                                    <Box
                                      component="img"
                                      sx={carouselImage}
                                      //src={`data:image/png;base64,${watchBase64Picture}`}
                                      src={isFalsy(watchBase64Picture)?`/images/person.jpg`:`data:image/png;base64,${watchBase64Picture}`}
                                      onClick={openFileDialog}
                                      //alt={step.id}
                                    ></Box>
                                  </div>
                                  {/* <img  width='100%'
                                    onClick={openFileDialog} height='100%' maxHeight='250px' /> */}
                                </Box>
                                <Box sx={{ mt: 1, width: '50%' }} >
                                  <Stack flexDirection='column'>                
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <Controller name='defaultLanguage' control={control}                                     
                                            render={ ({field: {onChange, value}}) => (
                                              <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="defaultLanguage"
                                                label={t('Language')} inputProps={ {readOnly: false}} focused >
                                                {enumItems && enumItems.filter( e => 
                                                      e.enumerationCode === Enum_DEFAULT_LANGUAGE ).map( 
                                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                }
                                              </TextField>
                                            )}
                                        />                                    
                                      <Controller name='nationality' control={control}                                     
                                            render={ ({field: {onChange, value}}) => (
                                              <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="nationality"
                                                label={t('Nationality')} inputProps={ {readOnly: false}} focused >
                                                {enumItems && enumItems.filter( e => 
                                                      e.enumerationCode === Enum_COUNTRY ).map( 
                                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                }
                                              </TextField>
                                            )}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <Controller name='identificationType' control={control}                                     
                                            render={ ({field: {onChange, value}}) => (
                                              <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="defaultLanguage"
                                                label={t('Identification type')} inputProps={ {readOnly: false}} focused >
                                                {enumItems && enumItems.filter( e => 
                                                      e.enumerationCode === Enum_PERSON_IDENTIFICATION ).map( 
                                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                }
                                              </TextField>
                                            )}
                                        />
                                        <TextField sx={{width:'calc(50% - 8px)'}} id="identificationNumber" label={`${t('Number')}`}
                                          {...register('identificationNumber')}  />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="identificationDelivrancePlace" label={`${t('Delivrance place')}`}
                                          {...register('identificationDelivrancePlace')}  />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <Controller control={control}
                                        name='identificationStartDate' 
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                          <DatePicker label={t('Start date')} 
                                            onChange={onChange}                     
                                            value={new Date(value)}
                                            slotProps={{ textField: { sx: {width:'calc(50% - 8px)'}  }} }
                                            //renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                                          /> )}
                                      />
                                      <Controller control={control}
                                      name='identificationEndDate' 
                                      render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <DatePicker label={t('End date')} 
                                          onChange={onChange}                     
                                          value={new Date(value)}
                                          slotProps={{ textField: { sx: {width:'calc(50% - 8px)'}  }} }
                                          //renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                                        /> )}
                                    />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                        
                                    </Box>
                                  </Stack>
                                </Box>
                              </Stack>
                            </Box>
                            
                            
                            
                            
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={4} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>                
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <ToggleButtonGroup size="small"
                              color="primary"
                              value={teacherInfoView}
                              exclusive
                              onChange={ (event, newView) => handleChangeTeacherInfoView(event, newView)}
                              aria-label="Small sizes" fullWidth
                            > 
                              <ToggleButton value="absence">{t('Absences')}</ToggleButton>
                              <ToggleButton value="discipline">{t('Disciplines')} </ToggleButton>                              
                              <ToggleButton value="observation">{t('Observations')} </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        { teacherInfoView === 'discipline' &&<Box sx={{ mt: 1, width: '100%' }} >
                          <EnhancedTable<ITeacherSheet> rows={teacherDisciplinarySheets} 
                              headCells={[            
                                {id:'id', label : t('Id'),  display: false, type: 'numeric', },
                                {id:'disciplinaryType', label : t('Type'),  display: true, type: 'string', },
                                {id:'disciplinaryEventSummary', label : t('Summary'),  display: true, type: 'string', },
                                
                                {id:'isCancelled', label : t('Cancelled ?'),  display: true, type: 'boolean', },
                                //{id:'cancellationPurpose', label : t('Date'),  display: true, type: 'string', },
                                
                              ]} 
                              title={t(`Disciplinaries`)} objKey='id' 
                              stateSelected={[selectedDisciplineIds, setSelectedDisciplineIds]} 
                              onRowSelected={handleTeacherSheetSelected} rowCheckedMode='single'
                              onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                              onRowDoubleClick={handleTeacherSheetDoubleClick} 
                              rowActionIcon={undefined}
                              toolbarActions={[
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                              ]}
                            /> 
                        </Box> }
                        { teacherInfoView === 'absence' && <Box sx={{ mt: 1, width: '100%' }} >
                          <EnhancedTable<ITeacherSheet> rows={teacherAbsenceSheets} 
                              headCells={[            
                                {id:'id', label : t('Id'),  display: false, type: 'numeric', },
                                {id:'absenceType', label : t('Type'),  display: true, type: 'string', },
                                {id:'absencePurpose', label : t('Purpose'),  display: true, type: 'string', },
                                
                                {id:'justifedAbsence', label : t('Justified ?'),  display: true, type: 'boolean', },
                                {id:'isCancelled', label : t('Cancelled ?'),  display: true, type: 'boolean', },
                                //{id:'cancellationPurpose', label : t('Date'),  display: true, type: 'string', },
                                
                              ]} 
                              title={`${t('_Leave_')}(s)`} objKey='id' 
                              stateSelected={[selectedAbsenceIds, setSelectedAbsenceIds]} 
                              onRowSelected={handleTeacherSheetSelected} rowCheckedMode='single'
                              onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                              onRowDoubleClick={handleTeacherSheetDoubleClick} 
                              rowActionIcon={undefined}
                              toolbarActions={[
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                              ]}
                            /> 

                        </Box>}
                        { teacherInfoView === 'observation' && <Box sx={{ mt: 1, width: '100%' }} >
                          <EnhancedTable<ITeacherSheet> rows={teacherObservationSheets} 
                              headCells={[            
                                {id:'id', label : t('Id'),  display: false, type: 'numeric', },
                                {id:'observationPurpose', label : t('Purpose'),  display: true, type: 'string', },
                                {id:'observationDescription', label : t('Description'),  display: true, type: 'string', },
                                {id:'observationDate', label : t('Date'),  display: true, type: 'date', },
                                
                                {id:'isCancelled', label : t('Cancelled ?'),  display: true, type: 'boolean', },
                                //{id:'cancellationPurpose', label : t('Date'),  display: true, type: 'string', },                                
                              ]} 
                              title={t(`Observations`)} objKey='id' 
                              stateSelected={[selectedObservationIds, setSelectedObservationIds]} 
                              onRowSelected={handleTeacherSheetSelected} rowCheckedMode='single'
                              onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                              onRowDoubleClick={handleTeacherSheetDoubleClick} 
                              rowActionIcon={undefined}
                              toolbarActions={[
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                              ]}
                            /> 
                        </Box>}

                        { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                              {...{open: displayEnumerationItemDialog, 
                                    title: '', 
                                    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                    afterSave: async () => {await refetchEnumerationItems()}}} 
                              />
                          }
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

