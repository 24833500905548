import { IGroupUser } from "./Group"

export interface IUser {
    id: number,

    accessFailedCount: number,
    concurrencyStamp: string,
    email: string,
    emailConfirmed: boolean,
    lockoutEnabled: boolean,
    lockoutEnd: Date,
    normalizedEmail: string,
    normalizedUserName: string,
    passwordHash: string,
    phoneNumber: string,
    phoneNumberConfirmed: boolean,
    securityStamp: string,
    twoFactorEnabled: boolean,
    userName: string,
    isActive: boolean,

    activeFrom: Date,
    activeTo: Date,

    salt: string,
    serviceCode: string,
    personId: number,
    userDescription: string,
    alias: string,
    
    refreshToken: string,
    refreshTokenExpiryTime: Date,

    isExtractAndExportAllowed: boolean,
    shouldResetPassword: boolean,

    passwordModificationDate: Date,
    passwordNeverExpired: boolean,

    isRootUser: boolean,

    intermediaryCode: string,

    password: string,
    passwordMD5: string,
    isPasswordChanged: boolean,

    

    personName: string,

    userRoles: IUserRole[],
    groupUsers: IGroupUser[],

    
    userPasswordHistories: any[]
  }

  export interface IUserRole {
    id: number,
    
    userId: number,
    roleId: number,

    roleName: string,
    roleDescription: string,
  }

  export interface IUserBusinessApplication {
    id: number,
    
    userId: number,
    businessApplicationId: number,
    businessApplicationUserName: string,

    businessApplicationName: string,
    businessApplicationDescription: string,
  }

  export interface IUserRequestType {
    id: number,
    
    userId: number,
    requestTypeId: number,

    requestUserName: string,
    requestUserPassword: string,
    accessCode: string,
    applicantCode: string,
    intermediaryAccess: string,

    accesCondition: string,
    priority: number,

    requestTypeName: string,
    requestTypeDescription: string,
  }


  export const defaultUser : IUser = {
    id: 0,
    accessFailedCount: 0,
    concurrencyStamp: '',
    email: '',
    emailConfirmed: false,
    lockoutEnabled: false,
    lockoutEnd: new Date(),
    normalizedEmail: '',
    normalizedUserName: '',
    passwordHash: '',
    phoneNumber: '',
    phoneNumberConfirmed: false,
    securityStamp: '',
    twoFactorEnabled: false,
    userName: '',
    isActive: false,

    activeFrom: new Date(),
    activeTo: new Date(),

    salt: '',
    serviceCode: '',
    personId: 0,
    userDescription: '',
    alias: '',
    
    refreshToken: '',
    refreshTokenExpiryTime: new Date(),

    isExtractAndExportAllowed: false,
    shouldResetPassword: false,
    passwordModificationDate: new Date(),
    passwordNeverExpired: false,

    isRootUser: false,

    intermediaryCode: '',

    password: '',
    passwordMD5: '',
    isPasswordChanged: false,

    personName: '',

    userRoles: [],
    groupUsers: [],

    userPasswordHistories: []
  }

  export interface IUserSearch {
  
  userName: string,
  userDescription: string,  
}

