
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';

import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, 
    isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useRegistrationService, { useBasicFilterRegistration } from './services/Registration';

import useUtils from 'library/utils';

import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IEntityNameId, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IRegistration, IRegistrationSanction, IRegistrationComplaint, IRegistrationSheet, defaultRegistration } from './models/Registration';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, Enum_REGISTRATION_STATUS, Enum_STUDY_TYPE,
    Enum_DEFAULT_LANGUAGE, Enum_TOWN, Enum_COUNTRY, Enum_PERSON_IDENTIFICATION,
    Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';


import { justifyCenter, typographyGroupBoxStyling, typographyBigGroupBoxStyling, carouselImage } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';
import { ISchoolYear, ISchoolYearClass, ISchoolYearClassTemplate } from 'features/setup/models/SchoolYear';
import { DatePicker } from '@mui/x-date-pickers';
import { IClassTemplate } from 'features/setup/models/ClassTemplate';
import useClassTemplateService, { useBasicFilterClassTemplate } from 'features/setup/services/ClassTemplate';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { IBilling, defaultBilling } from 'features/finance/models/Billing';
import { IPerson } from './models/Person';
import usePersonService, { useBasicFilterPerson, useBasicFilterPersonSearch } from './services/Person';
import { IBillingType } from 'features/setup/models/BillingType';
import { useBasicFilterBillingType } from 'features/setup/services/BillingType';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { debounce } from 'lodash';
import { GrClose, GrSearch, GrSearchAdvanced } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';
import { RegistrationMarkForm } from './RegistrationMarkForm';
import { ViewCarousel } from '@mui/icons-material';


export const StudentForm: FC<IRegistration> = (props: IRegistration = defaultRegistration) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();

  const {language: lg, schoolYears, applicationSetup, roleEntities} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer,
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  const { createRegistration, updateRegistration, generateIntialRegistration, evaluateAddedBillings, getStudentSheets } = useRegistrationService();

  const { getSchoolYearClasses, getSchoolYearClassTemplates } = useSchoolYearService();

  const { getPersons, getPersonsSearchCount } = usePersonService();

  const { capitalizeFirstLetter } = useUtils();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const refSchoolYears = useRef<ISchoolYear[]>();

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterRegistration = useBasicFilterRegistration( 
    (event: React.MouseEvent<unknown>, row: IRegistration) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
  const basicFilterPerson = useBasicFilterPerson( 
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, firstName, lastName, birthDate, birthPlace, particularSign, gender, civility} = row;
        
        setValue('personId', id);
        setValue('firstName', firstName);
        setValue('lastName', lastName);  
        setValue('birthDate', birthDate);   
        setValue('birthPlace', birthPlace);                    
        setValue('particularSign', particularSign);  
        setValue('gender', gender);  
        setValue('civility', civility);  
                          
        setOpenPersonFilter(false);
      }
  );

  

  const [selectedFilterBillingTypes, setSelectedFilterBillingTypes] = useState<number[]>([]);
  const [filteredBillingTypes, setFilteredBillingTypes] = useState<IBillingType[]>([]);
  
  const [openBillingTypeFilter, setOpenBillingTypeFilter] = useState(false);
  const basicFilterBillingType = useBasicFilterBillingType( 
      async (event: React.MouseEvent<unknown>, row: IBillingType) => {
          const {name, description, id} = row;

        if(getValues().billings.some( b => b.billingTypeId === id))
          return;

        (refAppendBillings.current??emptyFunc)(
          {id:0, registrationId: _id, billingTypeId: id, 
            isObligatory: false, isCancelled: false, cancellationPurpose: '', cancellationDate: null,
            billingTypeName: name,  
            amount: 0, amountPaid : 0, unpaidAmount: 0, dueAmount: 0 });
                           
          setOpenBillingTypeFilter(false);
      }, {
        rowCheckedMode: 'multiple', 
        stateSelected: [selectedFilterBillingTypes, setSelectedFilterBillingTypes],
        stateFiltered: [filteredBillingTypes, setFilteredBillingTypes],
    }
  );

  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  
  const emptyFunc = (obj: any) => {}
  
  const methods = useForm<IRegistration>({defaultValues:defaultRegistration});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  
  const watchSchoolYearId = watch('schoolYearId');
  const watchStudyType = watch('studyType');
  const watchStudyLanguage = watch('studyLanguage');

  const watchSchoolYearClassTemplateId = watch('schoolYearClassTemplateId');

  
  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const watchParticularSign = watch('particularSign');
  const watchPersonId = watch('personId');

  const watchRegistrationId = watch('id');

  const watchBase64Picture = watch('base64Picture');


   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
   

  const [openPersonSearchFilter, setOpenPersonSearchFilter] = useState(false);
  const basicFilterPersonSearch = useBasicFilterPersonSearch( 
      () => {
        const {firstName, lastName, particularSign} = getValues();
        return {firstName, lastName, particularSign};
        //return refPersonSearch.current || {firstName: '', lastName: '', particularSign: ''};
      },      
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, firstName, lastName, birthDate, birthPlace, particularSign} = row;
        
        setValue('personId', id);
        setValue('firstName', firstName);
        setValue('lastName', lastName);  
        setValue('birthDate', birthDate);   
        setValue('birthPlace', birthPlace);                    
        setValue('particularSign', particularSign);  
                          
        setOpenPersonSearchFilter(false);
      }
  );

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IRegistration>,Error,IRegistration>(
      _id>0?updateRegistration:createRegistration, {   
        onSuccess: (data: IResult<IRegistration>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Registration',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IRegistration>(['Registration', _id], () => retrieveEntity('Registration',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: extensionEnumerationItems } = useQuery<IEnumerationItem[]>(['SchoolYear','EnumerationItem', watchSchoolYearId], () =>
      getExtensionEnumerationItemsBySchoolYear(watchSchoolYearId) );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Registration'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_REGISTRATION_STATUS, Enum_TOWN, Enum_COUNTRY, Enum_PERSON_IDENTIFICATION,
              Enum_STUDY_TYPE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY ] ));

    //const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );

    
    const [schoolYearClassTemplates, setSchoolYearClassTemplates] = useState<ISchoolYearClassTemplate[]>([]);
    const [classes, setClasses] = useState<ISchoolYearClass[]>([]);

    const [openBillingExtension, setOpenBillingExtension] = useState<boolean>(false);

    type StudentInfoView = 'absence' | 'sanction' | 'disciplinary' | 'observation' | 'complaint';
    const [studentInfoView, setStudentInfoView] = useState<StudentInfoView>('absence');
    const handleChangeStudentInfoView = ( event: React.MouseEvent<HTMLElement>, newView: StudentInfoView) => {  

      if (newView === null) 
        return;
      
        setStudentInfoView(newView);           
    }

    const getInputAdornmentBillingExtension = (row: IBilling, cellId: keyof IBilling)  => ({
 
      toolTip: 'Details',
      icon: MoreIcon,
      onClickIcon: (event: any, index: number, row: IBilling ) => {    
        setBillingIndex(index);
        setCurrentBilling(row);
        setOpenBillingExtension(true);
      }  
    })

    const [headBillingCells, setHeadBillingCells]  = useState<HeadCell<IBilling>[]>([]);
    useEffect(() => {
    setHeadBillingCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'billingTypeName', label : t('Billing'),  display: true, type: 'string', width: 40,
        getInputAdornment: getInputAdornmentBillingExtension  },
      {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 15},
      {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 15},
      {id:'unpaidAmount', label : t('Unpaid amount'),  display: true, type: 'numeric', width: 15},
      {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 15},
    ]  )
  }, [t,i18n])

  const refAppendBillings = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateBilling = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
  const refRemoveBilling = useRef<(index: number ) => void>(null);

  const [currentBilling, setCurrentBilling] = useState<IBilling>(defaultBilling);
  const [billingIndex, setBillingIndex] = useState<number>(-1);
  const billingRowActionIcon = ( billing: IBilling) : ActionIconTableRow<IRegistration,IBilling> => {
  
    const res: ActionIconTableRow<IRegistration,IBilling> = {
      toolTip: 'Remove',
      icon: RemoveCircleIcon,
      hasAction: (index: number,row: IBilling) => !row.isObligatory, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IBilling) => {
        
        (refRemoveBilling.current??emptyFunc)(index);            
      }
    }
    return res;
  }

  
  
  const studentSheetRowActionIcon = ( tellerOperation: IRegistrationSheet) : ActionIconTableRow<IRegistration,IRegistrationSheet> => {
  
    const res: ActionIconTableRow<IRegistration,IRegistrationSheet> = {
      toolTip: t('Action'),
      icon: SystemUpdateAltIcon,
      hasAction: (index: number,row: IRegistrationSheet) => {
        //console.log(roleEntities); console.log(roleEntities.find(re => re.entityName === 'RegistrationAbsenceSheet'));

        const entityName = `Registration${capitalizeFirstLetter(studentInfoView)}Sheet`;
        const roleEntityRegistrationAbsenceSheet = roleEntities.find(re => re.entityName === entityName);
        return !isFalsy(roleEntityRegistrationAbsenceSheet);
      }, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IRegistrationSheet) => {

        const entityName = `Registration${capitalizeFirstLetter(studentInfoView)}Sheet`;
        openEntityActionDrawer( entityName, row.id);
      }
    }
    return res;
  }

const studentComplaintRowActionIcon = ( tellerOperation: IRegistrationComplaint) : ActionIconTableRow<IRegistration,IRegistrationComplaint> => {
  
    const res: ActionIconTableRow<IRegistration,IRegistrationComplaint> = {
      toolTip: t('Action'),
      icon: SystemUpdateAltIcon,
      hasAction: (index: number,row: IRegistrationComplaint) => {
        //console.log(roleEntities); console.log(roleEntities.find(re => re.entityName === 'RegistrationAbsenceSheet'));

        const roleEntityRegistrationComplaint = roleEntities.find(re => re.entityName === 'RegistrationComplaint');
        return !isFalsy(roleEntityRegistrationComplaint);
      }, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IRegistrationComplaint) => {
        openEntityActionDrawer('RegistrationComplaint', row.id);
        //(refRemoveBilling.current??emptyFunc)(index);            
      }
    }
    return res;
  }

  const studentSanctionRowActionIcon = ( tellerOperation: IRegistrationSanction) : ActionIconTableRow<IRegistration,IRegistrationSanction> => {
  
    const res: ActionIconTableRow<IRegistration,IRegistrationSanction> = {
      toolTip: t('Action'),
      icon: SystemUpdateAltIcon,
      hasAction: (index: number,row: IRegistrationSanction) => {
        //console.log(roleEntities); console.log(roleEntities.find(re => re.entityName === 'RegistrationAbsenceSheet'));

        const roleEntityRegistrationSanction = roleEntities.find(re => re.entityName === 'RegistrationSanction');
        return !isFalsy(roleEntityRegistrationSanction);
      }, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IRegistrationSanction) => {
        openEntityActionDrawer('RegistrationSanction', row.id);
        //(refRemoveBilling.current??emptyFunc)(index);            
      }
    }
    return res;
  }
  


  const [openRegistrationMark, setOpenRegistrationMark] = useState<boolean>(false);

  function openFileDialog() {
    (document as any).getElementById("file-upload").click();
  }

  const setFile = (_event: any) => {
    let f = _event.target.files![0];
    var reader = new FileReader();

    reader.onload = function () {

        var binaryString = reader.result as string;
    
        const base64String = binaryString
                                    .replace('data:', '')
                                    .replace(/^.+,/, '');

        setValue("base64Picture", base64String);
        //setValue("fileName", f.name);
      };
  
      reader.onerror = function () {
        console.log("File load failed");
      };
  
      reader.readAsDataURL(f);    
  };
  
  const debouncedNameChange = useRef(
    debounce( async () => {      
      const {personId, firstName, lastName, particularSign} = getValues();

      if(personId>0) return;

      if( (isFalsy(firstName) || firstName === '') && (isFalsy(lastName) || lastName === '' ))
        return;

      const count = await getPersonsSearchCount(firstName, lastName, particularSign);

      if(!isFalsy(refPersonSnackbarId.current)) closeSnackbar(refPersonSnackbarId.current!);
      
      if(count > 0) 
        refPersonSnackbarId.current = enqueueSnackbar( `${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
              anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
              action: xActionPersonSearch(firstName,lastName,particularSign) } );             

    }, 2500)
  ).current;

  

  const refPersonSnackbarId = useRef<SnackbarKey>();
  const xActionPersonSearch = (firstName: string, lastName: string, particularSign:string): SnackbarAction => (snackbarId: SnackbarKey) => (
    <>        
        <Button onClick={() => { 
                // setUrlPdf(`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`); 
                
                setOpenPersonSearchFilter(true);
                closeSnackbar(snackbarId); }}>   
            <GrSearch size={24} />
        </Button>
        <Button onClick={() => { closeSnackbar(snackbarId) }}>
            <GrClose size={24} />
        </Button>
    </>
)

  useEffect( () => {
      debouncedNameChange();    
  }, [watchFirstName, watchLastName, watchParticularSign, debouncedNameChange]); 

  useEffect( () => {              
    async function _() {
      const arr = await getSchoolYearClassTemplates(watchSchoolYearId, false, '', '');

      setSchoolYearClassTemplates( arr.filter( x => x.studyLanguage === watchStudyLanguage) );
    }

    _();
  }, [watchSchoolYearId, watchStudyType, watchStudyLanguage]); 

  useEffect( () => {              
    async function _() {
      // const schoolYear = (schoolYears || []).find(sy => sy.id === watchSchoolYearId);
      // const year = isFalsy(schoolYear) ? 0 : schoolYear.year;
      if(watchSchoolYearId > 0 && watchSchoolYearClassTemplateId > 0) {
        const arr = await getSchoolYearClasses({
            schoolYearId: watchSchoolYearId, 
            name: '' ,
            studyType: watchStudyType, 
            studyLanguage:watchStudyLanguage,
            studyLevel: '' });

        
        setClasses(arr.filter(x => x.schoolYearClassTemplateId === watchSchoolYearClassTemplateId) );  
      }    
    }

    _();
  }, [watchSchoolYearId, watchStudyType, watchSchoolYearClassTemplateId, watchStudyLanguage  ]); 


  useEffect( () => {              
    async function _() {

      if(isFalsy(watchRegistrationId) || watchRegistrationId <= 0) return;

      await loadStudentSheets(watchRegistrationId);      
    }

    _();
  }, [watchRegistrationId]); 

  const loadStudentSheets = async (registrationId: number) => {

    const {registrationSanctions,registrationAbsenceSheets,registrationDisciplinarySheets,
      registrationObservationSheets,registrationComplaints} = await getStudentSheets(registrationId);

    setRegistrationSanctions(registrationSanctions);
    setRegistrationAbsenceSheets(registrationAbsenceSheets);
    setRegistrationDisciplinarySheets(registrationDisciplinarySheets); 
    setRegistrationObservationSheets(registrationObservationSheets);   
    setRegistrationComplaints(registrationComplaints);   
  }



      useEffect( () => {        
        setCurrentFormNameAtom(t('Student'));
        setCurrentBasicTextFilterProps(basicFilterRegistration);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Registration',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) { console.log(_data);
            reset({..._data, 
              initialSchoolYearClassId: _data.schoolYearClassId});
        }
        }, [_data]);
    
    const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
      _setId(0);      
      reset({...defaultRegistration, schoolYearId: applicationSetup.currentSchoolYearId});    
    }
    
    const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
      if(!checkEntitySaveAuthorization('Student', _id)){
        setIsSaveLoading(false);
             return;
      }
        

        const data = getValues(); 
        if(data.firstName.trim() === '' && data.lastName.trim() === '') {
            enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              setIsSaveLoading(false);
              return;
          }
    
        //mutate(data);
    }
  
    const [selectedAbsenceIds, setSelectedAbsenceIds] = useState<number[]>([]);
    const [selectedDisciplineIds, setSelectedDisciplineIds] = useState<number[]>([]);
    const [selectedObservationIds, setSelectedObservationIds] = useState<number[]>([]);
    const [selectedSanctionIds, setSelectedSanctionIds] = useState<number[]>([]);

    const actionData = async (event: MouseEvent<HTMLButtonElement>) => {

      const arrDisciplinaries = selectedDisciplineIds.map( id => ({entityName: 'RegistrationDisciplinarySheet', entityId: id}) );
      const arrAbsences = selectedAbsenceIds.map( id => ({entityName: 'RegistrationAbsenceSheet', entityId: id}) );
      const arrSanctions = selectedSanctionIds.map( id => ({entityName: 'RegistrationSanction', entityId: id}) );
      
      setContextualItems([...arrDisciplinaries, ...arrAbsences, ...arrSanctions]);

      openEntityActionDrawer('Student', _id, contextualItems);      
    }

    const [registrationDisciplinarySheets, setRegistrationDisciplinarySheets] = useState<IRegistrationSheet[]>([]);    
    const [registrationAbsenceSheets, setRegistrationAbsenceSheets] = useState<IRegistrationSheet[]>([]);    
    const [registrationObservationSheets, setRegistrationObservationSheets] = useState<IRegistrationSheet[]>([]);   

    const [registrationSanctions, setRegistrationSanctions] = useState<IRegistrationSanction[]>([]);
    const [registrationComplaints, setRegistrationComplaints] = useState<IRegistrationComplaint[]>([]);

    const handleRegistrationSanctionDoubleClick = (event: React.MouseEvent<unknown>,row: IRegistrationSanction) => {
    
    }

    const [contextualItems, setContextualItems] = useState<IEntityNameId[]>([]);

    const handleRegistrationSheetDoubleClick = (event: React.MouseEvent<unknown>,row: IRegistrationSheet) => {
      
    }   

    const handleRegistrationSheetSelected = (event: React.MouseEvent<unknown>,row: IRegistrationSheet) => {
      const {id} = row;
      const arr = contextualItems.filter(x => x.entityName !== 'RegistrationSheet');
      //setContextualItems( [...arr, {entityName: 'RegistrationSheet', entityId: id}] );
      setContextualItems( [{entityName: 'RegistrationSheet', entityId: id}] );
    }

    const handleRegistrationSanctionSelected = (event: React.MouseEvent<unknown>,row: IRegistrationSanction) => {
      const {id} = row;
      const arr = contextualItems.filter(x => x.entityName !== 'RegistrationSanction');
      //setContextualItems( [...arr, {entityName: 'RegistrationSanction', entityId: id}] );
      setContextualItems( [{entityName: 'RegistrationSanction', entityId: id}] );
    }
      
    const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
      openEntityPrintDrawer('Student', _id);
    }

    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
      // queryClient.invalidateQueries(['Student',_id]);        
      // await retrieveData('Student',_id, refetch);        
      // reset(_data);        
      await loadStudentSheets(_id);      
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} > 
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                              <Typography variant="h1" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyBigGroupBoxStyling}}>
                                {`${getValues().studentName} ::-:: ${getValues().schoolYearClassName} - ${getValues().schoolYearName}`}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Stack flexDirection='row'>                
                                <Box sx={{ mt: 1, width: '50%' }} >
                                  <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                                    accept="image/*" />
                                  <div key={'step.id'}>
                                    <Box
                                      component="img"
                                      sx={carouselImage}
                                      src={`data:image/png;base64,${watchBase64Picture}`}
                                      onClick={openFileDialog}
                                      //alt={step.id}
                                    ></Box>
                                  </div>
                                  {/* <img  width='100%'
                                    onClick={openFileDialog} height='100%' maxHeight='250px' /> */}
                                </Box>
                                <Box sx={{ mt: 1, width: '50%' }} >
                                  <Stack flexDirection='column'>                
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <Controller name='defaultLanguage' control={control}                                     
                                            render={ ({field: {onChange, value}}) => (
                                              <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="defaultLanguage"
                                                label={t('Language')} inputProps={ {readOnly: false}} focused >
                                                {enumItems && enumItems.filter( e => 
                                                      e.enumerationCode === Enum_DEFAULT_LANGUAGE ).map( 
                                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                }
                                              </TextField>
                                            )}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <Controller name='nationality' control={control}                                     
                                            render={ ({field: {onChange, value}}) => (
                                              <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="nationality"
                                                label={t('Nationality')} inputProps={ {readOnly: false}} focused >
                                                {enumItems && enumItems.filter( e => 
                                                      e.enumerationCode === Enum_COUNTRY ).map( 
                                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                }
                                              </TextField>
                                            )}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <Controller name='identificationType' control={control}                                     
                                            render={ ({field: {onChange, value}}) => (
                                              <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="defaultLanguage"
                                                label={t('Identification type')} inputProps={ {readOnly: false}} focused >
                                                {enumItems && enumItems.filter( e => 
                                                      e.enumerationCode === Enum_PERSON_IDENTIFICATION ).map( 
                                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                }
                                              </TextField>
                                            )}
                                        />
                                        <TextField sx={{width:'calc(50% - 8px)'}} id="identificationNumber" label={`${t('Number')}`}
                                          {...register('identificationNumber')}  />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="identificationDelivrancePlace" label={`${t('Delivrance place')}`}
                                          {...register('identificationDelivrancePlace')}  />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <Controller control={control}
                                        name='identificationStartDate' 
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                          <DatePicker label={t('Start date')} 
                                            onChange={onChange}                     
                                            value={new Date(value)}
                                            slotProps={{ textField: { sx: {width:'calc(50% - 8px)'}  }} }
                                            //renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                                          /> )}
                                      />
                                      <Controller control={control}
                                      name='identificationEndDate' 
                                      render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <DatePicker label={t('End date')} 
                                          onChange={onChange}                     
                                          value={new Date(value)}
                                          slotProps={{ textField: { sx: {width:'calc(50% - 8px)'}  }} }
                                          //renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                                        /> )}
                                    />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                        
                                    </Box>
                                  </Stack>
                                </Box>
                              </Stack>
                            </Box>
                            
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Student'))} - ${t('Information')} :: ${getValues().studentCode}`}
                              </Typography>                                                       
                            </Box>   
                                                                              
                            <Box sx={{ mt: 0.25, width: '100%' }} >
                              <Controller name='gender' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="gender"
                                        label={t('Gender')} inputProps={ {readOnly: (watchPersonId>0) }} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_GENDER ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller name='civility' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="civility"
                                        label={t('Civility')} inputProps={ {readOnly: (watchPersonId>0) }} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_CIVILITY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                              <Controller control={control}
                                  name='birthDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Birth date')} 
                                      readOnly={(watchPersonId>0)}
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    /> )}
                                />
                              <TextField sx={{width:'calc(50% - 8px)'}} id="birthPlace" label={t('Birth place')} 
                                  {...register('birthPlace')}  inputProps={ {readOnly: (watchPersonId>0) }}/> 
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Class'))} `}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='studyLanguage' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="studyLanguage"
                                        label={t('Study language')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_STUDY_LANGUAGE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                              <Controller name='schoolYearClassTemplateId' control={control} 
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="classTemplateId"
                                        label={t('Class template')} inputProps={ {readOnly: false}} focused >
                                        {schoolYearClassTemplates && schoolYearClassTemplates.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.classTemplateName}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <Controller name='schoolYearClassId' control={control} 
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="schoolYearClassId"
                                        label={t('Class')} inputProps={ {readOnly: false}} focused >
                                        {classes && classes.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />                                

                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(35% - 8px)'}} id="registrationCode" label={t('Code')} 
                                {...register('registrationCode')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <Controller name='status' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                        label={t('Status')} inputProps={ {readOnly: true}} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_REGISTRATION_STATUS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              <Controller control={control}
                                  name='statusDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Status date')} 
                                      onChange={onChange} disableOpenPicker readOnly                    
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(18% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(18% - 8px)'}} />}
                                    /> )}
                                /> 
                                <Controller control={control}
                                  name='validationDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Validation date')} 
                                      onChange={onChange} disableOpenPicker readOnly                    
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(17% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(17% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Registration'))} - ${t('Information')}`}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                  label={t('Bus ?')}
                                  control={
                                  <Controller
                                      name='arriveOrGoesByBus'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                              <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                  label={t('Brunch ?')}
                                  control={
                                  <Controller
                                      name='takeBrunch'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                              <TextField sx={{width:'calc(25% - 8px)'}} id="portable1" label={`${t('Portable')}(1)`} 
                                {...register('portable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <TextField sx={{width:'calc(25% - 8px)'}} id="portable2" label={`${t('Portable')}(2)`} 
                                {...register('portable2')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Parent'))} - ${t('_Tutor')}`}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >                              
                              <TextField sx={{width:'calc(34% - 8px)'}} id="fatherName" label={`${t('_Father')}`} 
                                {...register('fatherName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <TextField sx={{width:'calc(33% - 8px)'}} id="motherName" label={`${t('_Mother')}`} 
                                {...register('motherName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <TextField sx={{width:'calc(33% - 8px)'}} id="tutorName" label={`${t('_Tutor')}`} 
                                {...register('tutorName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                            </Box>
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>    
                        { (getValues().id > 0 && getValues().status === '10') && <Box sx={{ mt: 1, width: '100%' }} >                        
                          <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => { setOpenRegistrationMark(true); }}
                              sx={{ ml: 1, width: '50%'}}>
                              {t('Mark')}
                          </Button>                          
                          { openRegistrationMark && <FormDialog open={openRegistrationMark} maxWidth='md'
                            okText='' cancelText='' title={`${getValues().firstName} ${getValues().lastName} :: ${t('Mark')}`} onCancel={()=> {}} 
                            onClose={()=> {setOpenRegistrationMark(false);}} onOk={()=> {setOpenRegistrationMark(false);}}  >
                                <RegistrationMarkForm {...{registration:getValues(), reportCard: ''}}  />
                          </FormDialog> }
                        </Box> }
                        <Box sx={{ mt: 1, width: '100%' }} > 
                          <ToggleButtonGroup size="small"
                              color="primary"
                              value={studentInfoView}
                              exclusive
                              onChange={ (event, newView) => handleChangeStudentInfoView(event, newView)}
                              aria-label="Small sizes" fullWidth
                            > 
                              <ToggleButton value="absence">{t('Absences')}</ToggleButton>
                              <ToggleButton value="disciplinary">{t('Disciplines')} </ToggleButton>
                              <ToggleButton value="sanction">{t('Sanctions')} </ToggleButton>
                              <ToggleButton value="observation">{t('Observations')} </ToggleButton>
                              <ToggleButton value="complaint">{t('Complaints')} </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>           
                        { studentInfoView === 'sanction' && <Box sx={{ mt: 1, width: '100%' }} >
                          <EnhancedTable<IRegistrationSanction> rows={registrationSanctions} 
                              headCells={[            
                                {id:'id', label : t('Id'),  display: false, type: 'numeric', },
                                {id:'sanctionCategoryCode', label : t('Category'),  display: true, type: 'string', },
                                {id:'description', label : t('Description'),  display: true, type: 'string', },
                                
                                {id:'sanctionDate', label : t('Date'),  display: true, type: 'date', },
                                {id:'isCancelled', label : t('Cancelled ?'),  display: true, type: 'boolean', },
                                //{id:'cancellationPurpose', label : t('Date'),  display: true, type: 'string', },
                                
                              ]} 
                              title={t(`Sanctions`)} objKey='id' 
                              stateSelected={[selectedSanctionIds, setSelectedSanctionIds]} 
                              onRowSelected={handleRegistrationSanctionSelected} rowCheckedMode='single'
                              onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                              onRowDoubleClick={handleRegistrationSanctionDoubleClick} 
                              rowActionIcon={studentSanctionRowActionIcon}
                              toolbarActions={[
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                              ]}
                            />
                        </Box> }
                        { studentInfoView === 'absence' && <Box sx={{ mt: 1, width: '100%' }} >
                          <EnhancedTable<IRegistrationSheet> rows={registrationAbsenceSheets} 
                              headCells={[            
                                {id:'id', label : t('Id'),  display: false, type: 'numeric', },
                                {id:'absenceType', label : t('Type'),  display: true, type: 'string', },
                                {id:'absencePurpose', label : t('Purpose'),  display: true, type: 'string', },
                                
                                {id:'justifedAbsence', label : t('Justified ?'),  display: true, type: 'boolean', },
                                {id:'isCancelled', label : t('Cancelled ?'),  display: true, type: 'boolean', },
                                //{id:'cancellationPurpose', label : t('Date'),  display: true, type: 'string', },
                                
                              ]} 
                              title={`${t('_Leave_')}(s)`} objKey='id' 
                              stateSelected={[selectedAbsenceIds, setSelectedAbsenceIds]} 
                              onRowSelected={handleRegistrationSheetSelected} rowCheckedMode='single'
                              onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                              onRowDoubleClick={handleRegistrationSheetDoubleClick} 
                              rowActionIcon={studentSheetRowActionIcon}
                              toolbarActions={[
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                              ]}
                            /> 

                        </Box>}
                        { studentInfoView === 'disciplinary' && <Box sx={{ mt: 1, width: '100%' }} >
                          <EnhancedTable<IRegistrationSheet> rows={registrationDisciplinarySheets} 
                              headCells={[            
                                {id:'id', label : t('Id'),  display: false, type: 'numeric', },
                                {id:'disciplinaryType', label : t('Type'),  display: true, type: 'string', },
                                {id:'disciplinaryEventSummary', label : t('Summary'),  display: true, type: 'string', },
                                
                                {id:'isCancelled', label : t('Cancelled ?'),  display: true, type: 'boolean', },
                                //{id:'cancellationPurpose', label : t('Date'),  display: true, type: 'string', },
                                
                              ]} 
                              title={t(`Disciplinaries`)} objKey='id' 
                              stateSelected={[selectedDisciplineIds, setSelectedDisciplineIds]} 
                              onRowSelected={handleRegistrationSheetSelected} rowCheckedMode='single'
                              onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                              onRowDoubleClick={handleRegistrationSheetDoubleClick} 
                              rowActionIcon={studentSheetRowActionIcon}
                              toolbarActions={[
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                              ]}
                            /> 
                        </Box>}
                        { studentInfoView === 'observation' && <Box sx={{ mt: 1, width: '100%' }} >
                          <EnhancedTable<IRegistrationSheet> rows={registrationObservationSheets} 
                              headCells={[            
                                {id:'id', label : t('Id'),  display: false, type: 'numeric', },
                                {id:'observationPurpose', label : t('Purpose'),  display: true, type: 'string', },
                                {id:'observationDescription', label : t('Description'),  display: true, type: 'string', },
                                {id:'observationDate', label : t('Date'),  display: true, type: 'date', },
                                
                                {id:'isCancelled', label : t('Cancelled ?'),  display: true, type: 'boolean', },
                                //{id:'cancellationPurpose', label : t('Date'),  display: true, type: 'string', },                                
                              ]} 
                              title={t(`Observations`)} objKey='id' 
                              stateSelected={[selectedObservationIds, setSelectedObservationIds]} 
                              onRowSelected={handleRegistrationSheetSelected} rowCheckedMode='single'
                              onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                              onRowDoubleClick={handleRegistrationSheetDoubleClick} 
                              rowActionIcon={studentSheetRowActionIcon}
                              toolbarActions={[
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                              ]}
                            /> 
                        </Box>}
                        { studentInfoView === 'complaint' && <Box sx={{ mt: 1, width: '100%' }} >
                          <EnhancedTable<IRegistrationComplaint> rows={registrationComplaints} 
                              headCells={[            
                                {id:'id', label : t('Id'),  display: false, type: 'numeric', },
                                {id:'complaintCategoryCode', label : t('Category'),  display: true, type: 'string', },
                                {id:'description', label : t('Description'),  display: true, type: 'string', },

                                {id:'complaintDate', label : t('Date'),  display: true, type: 'date', },
                                
                                //{id:'cancellationPurpose', label : t('Date'),  display: true, type: 'string', },
                                
                              ]} 
                              title={t(`Complaints`)} objKey='id' 
                              //stateSelected={[selectedSanctionIds, setSelectedSanctionIds]} 
                              onRowSelected={handleRegistrationSanctionSelected} rowCheckedMode='single'
                              onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                              //onRowDoubleClick={handleRegistrationSanctionDoubleClick} 
                              rowActionIcon={studentComplaintRowActionIcon}
                              toolbarActions={[
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                              ]}
                            />
                        </Box> }
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

