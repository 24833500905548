import { defaultSchoolYearClass, ISchoolYearClass } from "features/setup/models/SchoolYear"
import { IRegistration } from "./Registration";

export interface IRegistrationMark {
    id: number,

    status: 'N' | 'C' | 'E' | 'M',

    registrationId: number,
    schoolYearTemplateSubjectExamScheduleId: number,
    mark: number,
    

    currentMark: number,
    rank: number,
    markExist: boolean,
    markPurpose: string,
    markAppreciationId: number,

    registrationMarkCompetences: IRegistrationMarkCompetence[],

    firstName: string,
    lastName: string,
    birthDate: Date,    
}

export interface IRegistrationMarkCompetence {
    id: number,

    registrationMarkId: number,

    status: 'N' | 'C' | 'E' | 'M',

    currentMark: number,

    maxMark: number,

    mark: number,
    rank: number,
    markExist: boolean,
    markPurpose: string,
    markAppreciationId: number,

    schoolYearTemplateSubjectExamScheduleCompetenceId: number,
    schoolYearTemplateSubjectCompetenceDescription: string,

    schoolYearTemplateSubjectCompetenceTitle: string, // for manage file upload only
    firstName: string, // for manage file upload only
    lastName: string, // for manage file upload only
    
}

export interface ISchoolYearClassSubjectMark {
    id: number,

    schoolYearTemplateSubjectExamScheduleId: number,

    schoolYearClassId: number,
    schoolYearClassName: string,
    studyType: string,
    schoolYearClassTemplateId: number,

    fullTeacherName: string,
    substituteTeacherName: string,
    classTemplateName: string, 
    studyLanguage: string,

    schoolYearId: number,
    schoolYearStartDate: Date,
    schoolYearEndDate: Date,

    schoolYearPeriodId: number,
    schoolYearPeriodExamId: number,

    schoolYearClassSubjectId: number,
    teacherName: string,
    schoolYearTemplateSubjectLongName: string,

    maxMark: number,
    schoolYearClassSubjectExamScheduleStatus: string,

    registrationMarks: IRegistrationMark[]
}


export interface IRegistrationReportCard {

    registrationId: number,

    schoolYearPeriodExamId: number,
    schoolYearPeriodId: number,

    reportCard: string,
    
    averageMark: number,
    averageMarkAppreciationId: number,
    rank: number,       
}

export type ReportCardType = 'exam' | 'period' | 'year';

export interface ISchoolYearClassReportCard {

    id: number,

    schoolYearClass: ISchoolYearClass,

    type: ReportCardType,

    schoolYearId: number,
    schoolYearStartDate: Date,
    schoolYearEndDate: Date,

    schoolYearPeriodId: number,
    schoolYearPeriodExamId: number,

    studyType: string,
    studyLanguage: string,
    schoolYearClassTemplateId: number,
    schoolYearClassId: number,

    reportCard: string,

    registrationReportCards: IRegistrationReportCard[]
}



export const defaultRegistrationMark : IRegistrationMark = {
    id: 0,

    status: 'N',
    
    registrationId: 0,
    schoolYearTemplateSubjectExamScheduleId: 0,
    currentMark: 0,
    mark: 0,
    rank: 0,

    markExist: true,
    markPurpose: '',
    markAppreciationId: 0,

    registrationMarkCompetences: [],

    firstName: '',
    lastName: '',
    birthDate: new Date(),
}

export const defaultSchoolYearClassSubjectMark : ISchoolYearClassSubjectMark = {
    id: 0,
    schoolYearTemplateSubjectExamScheduleId: 0,

    schoolYearClassId: 0,
    schoolYearClassName: '',
    studyType: '',
    schoolYearClassTemplateId: 0,

    fullTeacherName: '',
    substituteTeacherName: '',
    classTemplateName: '', 
    studyLanguage: '',
    schoolYearId: 0,

    schoolYearStartDate: new Date(),
    schoolYearEndDate: new Date(),

    schoolYearPeriodId: 0,
    schoolYearPeriodExamId: 0,

    schoolYearClassSubjectId: 0,
    teacherName: '',
    schoolYearTemplateSubjectLongName: '',

    maxMark: 20,    
    schoolYearClassSubjectExamScheduleStatus: '00',

    registrationMarks: []
}

export const defaultSchoolYearClassReportCard : ISchoolYearClassReportCard = {
    id: 0,

    schoolYearClass: defaultSchoolYearClass,

    type: 'period',

    schoolYearId: 0,
    schoolYearStartDate: new Date(),
    schoolYearEndDate: new Date(),

    schoolYearPeriodId: 0,
    schoolYearPeriodExamId: 0,

    studyType: '',
    studyLanguage: '',
    schoolYearClassTemplateId: 0,
    schoolYearClassId: 0,

    reportCard: '',

    registrationReportCards: []
}

export const defaultRegistrationMarkCompetence : IRegistrationMarkCompetence = {

    id: 0,

    registrationMarkId: 0,

    status: 'N',

    currentMark: 0,

    maxMark: 0,

    mark: 0,
    rank: 0,
    markExist: true,
    markPurpose: '',
    markAppreciationId: 0,

    schoolYearTemplateSubjectExamScheduleCompetenceId: 0,
    schoolYearTemplateSubjectCompetenceDescription: '',

    schoolYearTemplateSubjectCompetenceTitle: '', // for manage file upload only
    firstName: '', // for manage file upload only
    lastName: '', // for manage file upload only
    
}



export interface IRegistrationMarkSearch {
    firstName: string,
    lastName: string,        
  }
