
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';

import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useTeacherService, { useBasicFilterTeacher } from './services/Teacher';
import useRegistrationService from 'features/production/services/Registration';

import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, ToggleButtonGroup, ToggleButton, FormHelperText, Link } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, Enum_REGISTRATION_STATUS, Enum_STUDY_TYPE, Enum_DAY_WEEK, 
    Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, 
    Enum_TEACHER_STATUS, Enum_SUBJECT, Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS, Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS_PURPOSE } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';


import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';

import useSchoolYearService, { useBasicFilterSchoolYear, useBasicFilterSchoolYearClass, useBasicFilterSchoolYearClassTemplate } from 'features/setup/services/SchoolYear';
import { ISchoolYear, ISchoolYearClassTemplate, ISchoolYearClass, defaultSchoolYearClassTemplate, ISchoolYearTemplateSubject, defaultSchoolYear, defaultSchoolYearTemplateSubject, ISchoolYearTemplateSubjectExamSchedule, ISchoolYearPeriod, ISchoolYearPeriodExam, defaultSchoolYearClass, ISchoolYearClassSubject, ISchoolYearClassSubjectSchedule, defaultSchoolYearClassSubject, ISchoolYearClassSubjectExamSchedule } from 'features/setup/models/SchoolYear';
import { DatePicker } from '@mui/x-date-pickers';
import { IClassTemplate } from 'features/setup/models/ClassTemplate';
import useClassTemplateService, { useBasicFilterClassTemplate } from 'features/setup/services/ClassTemplate';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';


import { IPerson } from 'features/production/models/Person';
import { useBasicFilterPerson } from 'features/production/services/Person';
import { IBillingType } from 'features/setup/models/BillingType';
import { useBasicFilterBillingType } from 'features/setup/services/BillingType';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { ITeacher } from './models/Teacher';
import { IClassroom } from './models/Classroom';
import { useBasicFilterClassroom } from './services/Classroom';
import { getUnixTime } from 'date-fns';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IRegistration } from 'features/production/models/Registration';

import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';

export const SchoolYearClassForm: FC<ISchoolYearClass> = (props: ISchoolYearClass = defaultSchoolYearClass) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();

  const theme = useTheme();

  const {language: lg, schoolYears, applicationSetup, roleEntities} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer,
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  const { getTeachersBySubjectExamSchedule } = useTeacherService();

  const { getRegistrationsBySchoolYearClass } = useRegistrationService();

  const { createSchoolYearClass,updateSchoolYearClass, getSchoolYearClassTemplates, getSchoolYearTemplateSubjects,
      getSchoolYearPeriods, getSchoolYearPeriodExams, getSchoolYearClasses, getSchoolYearTemplateSubjectExamSchedules,
      manageSubjectExamSupervisors, validateSchoolYearClassSubjectExamSchedule, validateBatchSchoolYearClassSubjectExamSchedule,
      unvalidateSchoolYearClassSubjectExamSchedule, cancelSchoolYearClassSubjectExamSchedule,
      cancelPropositionSchoolYearClassSubjectExamSchedule, cancelCancellationSchoolYearClassSubjectExamSchedule,
      getSchoolYearClassSubjectExamSchedules } = useSchoolYearService();

  const { getClassTemplates } = useClassTemplateService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const refSchoolYears = useRef<ISchoolYear[]>();

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterSchoolYearClass = useBasicFilterSchoolYearClass( 
    (event: React.MouseEvent<unknown>, row: ISchoolYearClass) => {
        setIsSearchBoxShow(false);

        setValue('schoolYearId', row.schoolYearId);

        _setId(row.id);
      }
  );


  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();

      
  const [openClassTemplateFilter, setOpenClassTemplateFilter] = useState(false);
  const basicFilterClassTemplate = useBasicFilterClassTemplate( 
      (event: React.MouseEvent<unknown>, row: IClassTemplate) => {
          const {id, name} = row;

             
        setValue('classTemplateId', id);
        setValue('classTemplateName', name);
                                  
        setOpenClassTemplateFilter(false);
      }
  );

  //const inputSchoolYear = useRef();
  const refSchoolYearId = useRef<number>(0);
  
  const [openSchoolYearClassTemplateFilter, setOpenSchoolYearClassTemplateFilter] = useState(false);
  const basicFilterSchoolYearClassTemplate = useBasicFilterSchoolYearClassTemplate( 
      async (event: React.MouseEvent<unknown>, row: ISchoolYearClassTemplate) => {

        const { classTemplateId, classTemplateName, id } = row;
              
        setValue('schoolYearClassTemplateId', id);
        setValue('schoolYearId', watchSchoolYearId);
        setValue('classTemplateId', classTemplateId);
        setValue('classTemplateName', classTemplateName);
                                  
        setOpenSchoolYearClassTemplateFilter(false);
      }, refSchoolYearId, false
  );
  
  type TeacherUsage = 'full' | 'substitute' | 'subject' | 'supervisor' | 'subject-all';
  const [currentTeacherUsage, setCurrentTeacherUsage] = useState<TeacherUsage>('full');

  const [openTeacherFilter, setOpenTeacherFilter] = useState(false);
  const basicFilterTeacher = useBasicFilterTeacher( 
      (event: React.MouseEvent<unknown>, row: ITeacher) => {
          const {id, firstName, lastName, birthDate, registrationNumber, hiringDate, status, statusDate} = row;
          
          const name = `${lastName} ${firstName}`;
          if(currentTeacherUsage === 'full'){
            reset({...getValues(), fullTeacherId: id, fullTeacherName: name });
            // setValue('fullTeacherId', id);
            // setValue('fullTeacherName', `${name}`);
          } else if(currentTeacherUsage === 'substitute') {
            reset({...getValues(), substituteTeacherId: id, substituteTeacherName: name });
            // setValue('substituteTeacherId', id);
            // setValue('substituteTeacherName', `${name}`);
          } else if(currentTeacherUsage === 'subject') {

            const schoolYearClassSubject = getValues().schoolYearClassSubjects.at(schoolYearClassSubjectIndex);
            if(isFalsy(schoolYearClassSubject)) return;

            (refUpdateSchoolYearClassSubject.current??emptyFunc)(schoolYearClassSubjectIndex,
               {...schoolYearClassSubject, teacherId: id, teacherName: `${lastName} ${firstName}`   });
          } else if(currentTeacherUsage === 'subject-all') {

            const { schoolYearClassSubjects } = getValues();

            const arr = schoolYearClassSubjects.map( (x) => ({...x, teacherId: id, teacherName: `${name}`}));
            setValue('schoolYearClassSubjects',arr,  {shouldValidate: true} );
            
          } else if(currentTeacherUsage === 'supervisor') {
            const {currentSchoolYearClassSubjectExamSupervisors} = getValues();

            if(!currentSchoolYearClassSubjectExamSupervisors.some(x => x.id === id)) {
              (refAppendSchoolYearClassSubjectExamSupervisors.current??emptyFunc)( 
                {
                  id, firstName, lastName, birthDate, registrationNumber, hiringDate, status, statusDate
                }
              );
            }

          }
                           
          setOpenTeacherFilter(false);
      }
  );

  const [openClassroomFilter, setOpenClassroomFilter] = useState(false);
  const basicFilterClassroom = useBasicFilterClassroom( 
      (event: React.MouseEvent<unknown>, row: IClassroom) => {
          const {name, id} = row;
        
        setValue('classroomId', id);
        setValue('classroomName', name);                           
        setOpenClassroomFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  
  const emptyFunc = (obj: any) => {}
  
  const methods = useForm<ISchoolYearClass>({defaultValues:defaultSchoolYearClass});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchId = watch('id');
  const watchSchoolYearId = watch('schoolYearId');

  const watchSchoolYearClassTemplateId = watch('schoolYearClassTemplateId');

  const watchCurrentSchoolYearPeriodId = watch('currentSchoolYearPeriodId');
  const watchCurrentSchoolYearPeriodExamId = watch('currentSchoolYearPeriodExamId');
  const watchCurrentSchoolYearTemplateSubjectExamScheduleId = watch('currentSchoolYearTemplateSubjectExamScheduleId');

  const watchCurrentSchoolYearClassSubjectExamStatusPurpose = watch('currentSchoolYearClassSubjectExamStatusPurpose');
   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  
  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ISchoolYearClass>,Error,ISchoolYearClass>(
    _id>0?updateSchoolYearClass:createSchoolYearClass, {   
        onSuccess: (data: IResult<ISchoolYearClass>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['SchoolYearClass',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

  //// Mutation for actions.....
  const {isLoading: isLoadingSubjectExamSupervisors, isError: isErrorSubjectExamSupervisors, 
            isSuccess:isSuccessSubjectExamSupervisors, error: errorSubjectExamSupervisors, 
            mutate: mutateManageSubjectExamSupervisors } = useMutation<IResult<boolean>,Error,any>(
            manageSubjectExamSupervisors, {   
      onSuccess: (data: IResult<boolean>) => {
        enqueueSnackbar( t('_Operation_done'), { variant: 'success',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                
          setOpenSubjectExamSupervisors(false);
          //queryClient.invalidateQueries(['Registration',data.data.id]);
      },
      onError: (err: Error) => {          
        enqueueSnackbar( error?.message, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setOpenSubjectExamSupervisors(false);
      }
    });

    type SubjectExamActionType = 'validate' | 'validate-batch' | 'unvalidate' | 'cancel' | 'cancel-proposition' | 'cancel-cancellation';
    const [currentSubjectExamAction, setCurrentSubjectExamAction] = useState<SubjectExamActionType>('validate');

    const {isLoading: isLoadingValidateSubjectExam, isError: isErrorValidateSubjectExam, 
      isSuccess:isSuccessValidateSubjectExam, error: errorValidateSubjectExam, 
      mutate: mutateValidateSubjectExamSchedule } = useMutation<IResult<boolean>,Error,any>(
      currentSubjectExamAction === 'unvalidate' ? unvalidateSchoolYearClassSubjectExamSchedule : 
      currentSubjectExamAction === 'cancel-proposition' ? cancelPropositionSchoolYearClassSubjectExamSchedule :
      currentSubjectExamAction === 'cancel' ? cancelSchoolYearClassSubjectExamSchedule :
      currentSubjectExamAction === 'cancel-cancellation' ? cancelCancellationSchoolYearClassSubjectExamSchedule :
                  validateSchoolYearClassSubjectExamSchedule, {   
      onSuccess: (data: IResult<boolean>) => {
        enqueueSnackbar( t('_Operation_done'), { variant: 'success',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                
          setOpenSubjectExam(false);
          //queryClient.invalidateQueries(['Registration',data.data.id]);
      },
      onError: (err: Error) => {          
        enqueueSnackbar( error?.message, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setOpenSubjectExam(false);
      }
    });

    const { mutate: mutateValidateBatchSubjectExamSchedule } = useMutation<IResult<boolean>,Error,any>(
      validateBatchSchoolYearClassSubjectExamSchedule, {   
     onSuccess: (data: IResult<boolean>) => {
       enqueueSnackbar( t('_Operation_done'), { variant: 'success',
             anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
               
         setOpenSubjectExam(false);
         //queryClient.invalidateQueries(['Registration',data.data.id]);
     },
     onError: (err: Error) => {          
       enqueueSnackbar( error?.message, { variant: 'error',
           anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
           setOpenSubjectExam(false);
     }
   });

    const { mutate: mutateUnvalidateSubjectExamSchedule } = useMutation<IResult<boolean>,Error,any>(
       unvalidateSchoolYearClassSubjectExamSchedule, {   
      onSuccess: (data: IResult<boolean>) => {
        enqueueSnackbar( t('_Operation_done'), { variant: 'success',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                
          setOpenSubjectExam(false);
          //queryClient.invalidateQueries(['Registration',data.data.id]);
      },
      onError: (err: Error) => {          
        enqueueSnackbar( error?.message, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setOpenSubjectExam(false);
      }
    });


    const {data: _data, refetch} = useQuery<ISchoolYearClass>(['SchoolYearClass', _id], () => retrieveEntity('SchoolYearClass',_id), 
      {refetchOnWindowFocus: false ,enabled: false, } );


    const {data: schoolYearClassRegistrations } = useQuery<ISchoolYearClass>(['SchoolYearClass', 'Registrations', _id], () => retrieveEntity('SchoolYearClass',_id, 'Registrations'), 
      {refetchOnWindowFocus: false ,enabled: getValues().id > 0, } );

    
    const {data: enumItems, refetch: refetchEnumerationItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'SchoolYearClassTemplate'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_REGISTRATION_STATUS, Enum_TEACHER_STATUS, Enum_DAY_WEEK,
              Enum_STUDY_TYPE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_SUBJECT, Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS_PURPOSE,
              Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS ] ));
   
    const [schoolYearPeriods, setSchoolYearPeriods] = useState<ISchoolYearPeriod[]>([]);

    const [schoolYearTemplateSubjects, setSchoolYearTemplateSubjects] = useState<ISchoolYearTemplateSubject[]>([]);

    const [schoolYearPeriodExams, setSchoolYearPeriodExams] = useState<ISchoolYearPeriodExam[]>([]);

    const [schoolYearTemplateSubjectExamSchedules, setSchoolYearTemplateSubjectExamSchedules] = useState<ISchoolYearTemplateSubjectExamSchedule[]>([]);

    const [registrations, setRegistrations] = useState<IRegistration[]>([]);
        
    //const [schoolYear, setSchoolYear] = useState<ISchoolYear>(defaultSchoolYear);

    const handleClickOpenSchoolYearClassTemplate = async (event: any) => {
      if(getValues().id > 0 && getValues().countStudents > 0) {
        enqueueSnackbar( t('Class can not be changed, you have to add new.'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2500 }); 
        return;
      }

      if(getValues().schoolYearId <= 0) {
        enqueueSnackbar( t('You have to select school year before continue.'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2500 }); 
        return;
      }

      setOpenSchoolYearClassTemplateFilter(true);      
    }

    const handleClickOpenClassroom = (event: any) => {
      
      setOpenClassroomFilter(true);      
    }

    const handleClickOpenTeacher = (teacherUsage: TeacherUsage) => (event: any) => {
      
      setCurrentTeacherUsage(teacherUsage);
      setOpenTeacherFilter(true);      
    }

    
  //////
  
  const getInputAdornmentTeacher = (row: ISchoolYearClassSubject, cellId: keyof ISchoolYearClassSubject)  => ({
  
    toolTip: 'Teacher',
    icon: ArrowDropDownCircleIcon,
    onClickIcon: (event: any, index: number, row: ISchoolYearClassSubject ) => {
      setSchoolYearClassSubjectIndex(index);
      setCurrentTeacherUsage('subject');
      setOpenTeacherFilter(true);
    }  
  })

  const getInputAdornmentSubject = (row: ISchoolYearClassSubject, cellId: keyof ISchoolYearClassSubject)  => ({
  
    toolTip: 'Subject',
    icon: RemoveCircleIcon,
    onClickIcon: (event: any, index: number, row: ISchoolYearClassSubject ) => {
      (refRemoveSchoolYearClassSubject.current??emptyFunc)(index);
    }  
  })

  
  const getSubjectList = (row: ISchoolYearClassSubject, cellId: keyof ISchoolYearClassSubject, 
    opts: {value: string, name: string}[]) => {        
    
    return getAsOptions(refEnumItems.current ?? [],Enum_SUBJECT);
  }

  const cellEditableSubject = (row: ISchoolYearClassSubject, cellId: keyof ISchoolYearClassSubject) => {
    return true;
  }

  const [headSchoolYearClassSubjectCells, setHeadSchoolYearClassSubjectCells]  = useState<HeadCell<ISchoolYearClassSubject>[]>([]);
  useEffect( () => {
    setHeadSchoolYearClassSubjectCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },  
      {id:'schoolYearTemplateSubjectLongName', label : t('Long name'),  display: true, type: 'string', width: 50,
        getInputAdornment: getInputAdornmentSubject},
      {id:'teacherName', label : t('Teacher'),  display: true, type: 'string', width: 50, 
        getInputAdornment: getInputAdornmentTeacher },
      //{id:'shortName', label : t('Short name'),  display: true, type: 'string', width: 25, isEditable: cellEditableTemplateSubject, },
      // {id:'coefficient', label : t('Coefficient'),  display: true, type: 'numeric', width: 10, isEditable: cellEditableTemplateSubject,},
      // {id:'reportCard', label : t('Report cart'),  display: true, type: 'string', width: 20, 
      //   isEditable: cellEditableTemplateSubject, getOptions: getReportCardList},
    ]  )
  }, [t,i18n])

  const refAppendSchoolYearClassSubjects = useRef<(value: Partial<FieldArray<ISchoolYearClass>> | Partial<FieldArray<ISchoolYearClass>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateSchoolYearClassSubject = useRef<(index: number,value: Partial<FieldArray<ISchoolYearClass>> ) => void>(null);
  const refRemoveSchoolYearClassSubject = useRef<(index: number ) => void>(null);

  const handleAddSchoolYearClassSubjects = (event: any) => {

    const {schoolYearId, classTemplateId} = getValues();
    
    if(schoolYearId <= 0 || classTemplateId <= 0) return;

    const currentSubjectIds = getValues().schoolYearClassSubjects.map(x => x.schoolYearTemplateSubjectId);

    setCurrentSchoolYearTemplateSubjects( schoolYearTemplateSubjects.filter( x => 
          !currentSubjectIds.includes(x.id) )  );

    setOpenSchoolYearTemplateSubject(true);
  }

  const handleAddAllSchoolYearClassSubjects = (event: any) => {
    const {schoolYearId, classTemplateId} = getValues();    
    if(schoolYearId <= 0 || classTemplateId <= 0) return;

    const currentSubjectIds = getValues().schoolYearClassSubjects.map(x => x.schoolYearTemplateSubjectId);

    const arr = schoolYearTemplateSubjects.filter( x => !currentSubjectIds.includes(x.id) );
    (refAppendSchoolYearClassSubjects.current??emptyFunc)( arr.map( x => 
        ({...defaultSchoolYearClassSubject, schoolYearTemplateSubjectLongName: x.longName,
          schoolYearTemplateSubjectId: x.id, schoolYearClassSubjectSchedules: [],
          schoolYearClassSubjectPeriods: schoolYearPeriods.map( (schoolYearPeriod, idx) => ({
            schoolYearPeriodId: schoolYearPeriod.id, schoolYearPeriodName: schoolYearPeriod.name,
            schoolYearClassSubjectSchedules: []
          }))
        }) )
    );
  }

  const handleSetTeacherForAllSubjects = (event: any) => {
    
    setCurrentTeacherUsage('subject-all');
    setOpenTeacherFilter(true);

  }

  const [currentSchoolYearClassSubject, setCurrentSchoolYearClassSubject] = useState<ISchoolYearClassSubject>(defaultSchoolYearClassSubject)
  const [schoolYearClassSubjectIndex, setSchoolYearClassSubjectIndex] = useState<number>(-1);
  const schoolYearClassSubjectRowActionIcon = ( schoolYearClass: ISchoolYearClassSubject) : ActionIconTableRow<ISchoolYearClass,ISchoolYearClassSubject> => {
  
    const res: ActionIconTableRow<ISchoolYearClass,ISchoolYearClassSubject> = {
      toolTip: 'remove',
      icon: MoreIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any, index: number, row: ISchoolYearClassSubject) => {
        
        const schoolYearClassSubject = getValues().schoolYearClassSubjects.at(index);
        if(isFalsy(schoolYearClassSubject)) return;

        setCurrentSchoolYearClassSubject(schoolYearClassSubject!);

        if( schoolYearClassSubject!.schoolYearClassSubjectPeriods.length > 0)
          setCurrentSchoolYearPeriodId(schoolYearClassSubject!.schoolYearClassSubjectPeriods[0].schoolYearPeriodId);

        setSchoolYearClassSubjectIndex(index);
        setOpenSchoolYearClassSubjectSchedule(true);
        //(refRemoveSchoolYearTemplateSubject.current??emptyFunc)(index);         
      }
    }
    return res;
}

const [filteredSchoolYearTemplateSubjects, setFilteredSchoolYearTemplateSubjects] = useState<number[]>([]);

const [openSchoolYearClassSubjectSchedule, setOpenSchoolYearClassSubjectSchedule] = useState<boolean>(false);


const getSubjectScheduleTypeList = (row: ISchoolYearClassSubjectSchedule, cellId: keyof ISchoolYearClassSubjectSchedule, 
  opts: {value: string, name: string}[]) => {        
  
  return [{value: 'recurrent', name: t('Recurrent')}, {value: 'exceptional', name: t('Exceptional')}];
}

const getSubjectScheduleWeekDayList = (row: ISchoolYearClassSubjectSchedule, cellId: keyof ISchoolYearClassSubjectSchedule, 
  opts: {value: string, name: string}[]) => {        
  
    return getAsOptions(refEnumItems.current ?? [],Enum_DAY_WEEK);
}

const cellEditableClassSubjectSchedule = (row: ISchoolYearClassSubjectSchedule, cellId: keyof ISchoolYearClassSubjectSchedule) => {
  
  const {type} = row;

  return  (cellId === 'scheduledDate') ? (type === 'exceptional') :
            (cellId === 'dayOfWeek') ? (type === 'recurrent') : true;
}

const [headSchoolYearClassSubjectScheduleCells, setHeadSchoolYearClassSubjectScheduleCells]  = useState<HeadCell<ISchoolYearClassSubjectSchedule>[]>([]);
useEffect( () => {
  setHeadSchoolYearClassSubjectScheduleCells([            
    {id:'id', label : t('Id'),  display: false, type: 'numeric', isEditable: cellEditableClassSubjectSchedule },      
    {id:'type', label : t('Type'),  display: true, type: 'string', width: 20, isEditable: cellEditableClassSubjectSchedule,
      getOptions: getSubjectScheduleTypeList },
    {id:'dayOfWeek', label : t('Day'),  display: true, type: 'string', width: 20, 
      isEditable: cellEditableClassSubjectSchedule, getOptions: getSubjectScheduleWeekDayList},
    {id:'scheduledDate', label : t('Date'),  display: true, type: 'date', width: 20, 
      isEditable: cellEditableClassSubjectSchedule },
    {id:'startTime', label : t('Start'),  display: true, type: 'datetime', width: 20, isEditable: cellEditableClassSubjectSchedule, },
    {id:'endTime', label : t('End'),  display: true, type: 'datetime', width: 20, isEditable: cellEditableClassSubjectSchedule,},
    //{id:'maxMark', label : t('Max mark'),  display: true, type: 'numeric', width: 14, isEditable: cellEditableTemplateSubjectExamSchedule,},
  ]  )
}, [t,i18n])

const refAppendSchoolYearClassSubjectSchedules = useRef<(value: Partial<FieldArray<ISchoolYearClass>> | Partial<FieldArray<ISchoolYearClass>>[], options?: FieldArrayMethodProps) => void>(null);
const refUpdateSchoolYearClassSubjectSchedule = useRef<(index: number,value: Partial<FieldArray<ISchoolYearClass>> ) => void>(null);
const refRemoveSchoolYearClassSubjectSchedule = useRef<(index: number ) => void>(null);

const handleAddSchoolYearClassSubjectSchedules = (schoolYearPeriodId: number) => (event: any) => {
  
  const schoolYearPeriod = schoolYearPeriods.find(x => x.id === schoolYearPeriodId);
  
  if(isFalsy(schoolYearPeriod)) return;
  
  const schoolYearClassSubjectPeriod = currentSchoolYearClassSubject.schoolYearClassSubjectPeriods
        .find(x => x.schoolYearPeriodId === schoolYearPeriodId);
  
  if(isFalsy(schoolYearClassSubjectPeriod)) return;

  (refAppendSchoolYearClassSubjectSchedules.current??emptyFunc)({id: 0,
    schoolYearClassSubjectId: currentSchoolYearClassSubject.id,
    schoolYearPeriodId,
    
    startTime: new Date(),
    endTime: new Date(),
    type:	'recurrent',
    dayOfWeek: 'MON',
    scheduledDate: new Date(),

    description: ''});
  
}


const schoolYearClassSubjectScheduleRowActionIcon = ( schoolYearClassSubjectSchedule: ISchoolYearClassSubjectSchedule) : ActionIconTableRow<ISchoolYearClass,ISchoolYearClassSubjectSchedule> => {
  
  const res: ActionIconTableRow<ISchoolYearClass,ISchoolYearClassSubjectSchedule> = {
    toolTip: 'remove',
    icon: RemoveCircleIcon,
    hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
    isActionExecuting: true,
    onRowClickIcon: (event : any,index: number, row: ISchoolYearClassSubjectSchedule) => {
      
      (refRemoveSchoolYearClassSubjectSchedule.current??emptyFunc)(index);         
    }
  }
  return res;
}

const [currentSchoolYearPeriodId, setCurrentSchoolYearPeriodId] = useState<number>(0);
const handleChangeSchoolYearPeriod = ( event: React.MouseEvent<HTMLElement>, newSchoolYearPeriodId: number ) => {  
  if(isFalsy(newSchoolYearPeriodId) ) 
    return;

  setCurrentSchoolYearPeriodId(newSchoolYearPeriodId);
}


const [openSchoolYearTemplateSubject, setOpenSchoolYearTemplateSubject] = useState<boolean>(false);
const [currentSchoolYearTemplateSubjects, setCurrentSchoolYearTemplateSubjects] = useState<ISchoolYearTemplateSubject[]>([]);

const handleSchoolYearTemplateSubjectDoubleClick = (event: React.MouseEvent<unknown>,row: ISchoolYearTemplateSubject) => {  
    
  const {id, longName, } = row;
  if(getValues().schoolYearClassSubjects.some( x => x.schoolYearTemplateSubjectId === id) ){
    return;
  }

  (refAppendSchoolYearClassSubjects.current??emptyFunc)( {...defaultSchoolYearClassSubject,
    schoolYearTemplateSubjectId: id, schoolYearTemplateSubjectLongName: longName, schoolYearClassSubjectSchedules: [],
    schoolYearClassSubjectPeriods: schoolYearPeriods.map( (schoolYearPeriod, idx) => ({
      schoolYearPeriodId: schoolYearPeriod.id, schoolYearPeriodName: schoolYearPeriod.name,
      schoolYearClassSubjectSchedules: []
    }))
  });
  
  setOpenSchoolYearTemplateSubject(false);
}

const handleOkSchoolYearTemplateSubject = (event: React.MouseEvent<unknown>) => {

  const ids = filteredSchoolYearTemplateSubjects.filter( i => 
      !getValues().schoolYearClassSubjects.some(x => x.schoolYearTemplateSubjectId === i) );

  (refAppendSchoolYearClassSubjects.current??emptyFunc)( 
    schoolYearTemplateSubjects.filter(x => ids.includes(x.id)).map( x => 
      ({...defaultSchoolYearClassSubject, schoolYearTemplateSubjectLongName: x.longName,
        schoolYearTemplateSubjectId: x.id, schoolYearClassSubjectSchedules: [],
        schoolYearClassSubjectPeriods: schoolYearPeriods.map( (schoolYearPeriod, idx) => ({
          schoolYearPeriodId: schoolYearPeriod.id, schoolYearPeriodName: schoolYearPeriod.name,
          schoolYearClassSubjectSchedules: []
        }))
      }) )
  );

  setOpenSchoolYearTemplateSubject(false);
}

const studentRowActionIcon = ( registration: IRegistration) : ActionIconTableRow<ISchoolYearClass,IRegistration> => {
  
  const res: ActionIconTableRow<ISchoolYearClass,IRegistration> = {
    toolTip: t('Action'),
    icon: SystemUpdateAltIcon,
    hasAction: (index: number,row: IRegistration) => {
      //console.log(roleEntities); console.log(roleEntities.find(re => re.entityName === 'RegistrationAbsenceSheet'));

      const roleEntityStudent = roleEntities.find(re => re.entityName === 'Student');
      return !isFalsy(roleEntityStudent);
    }, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
    isActionExecuting: true,
    onRowClickIcon: (event : any,index: number, row: IRegistration) => {
      openEntityActionDrawer('Student', row.id);
      //(refRemoveBilling.current??emptyFunc)(index);            
    }
  }
  return res;
}

const handleRegistrationDoubleClick = (event: React.MouseEvent<unknown>,row: IRegistration) => {      
  const roleEntityRegistration = roleEntities.find(re => re.entityName === 'Registration');
  if(isFalsy(roleEntityRegistration)) return;
  
  navigate(`/registration/${row.id}`);
}


const [headSchoolYearClassSubjectExamSupervisorCells, setHeadSchoolYearClassSubjectExamSupervisorCells]  = useState<HeadCell<ITeacher>[]>([]);
useEffect( () => {
  setHeadSchoolYearClassSubjectExamSupervisorCells([            
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },    

    {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string' },
    {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string' },


    {id:'birthDate', label : t('Birth date'),  display: true, type: 'date', width: 10},

    {id:'registrationNumber', label : t('Regis. Number'),  display: true, type: 'string', width: 10},
    {id:'hiringDate', label : t('Hiring date'),  display: true, type: 'string', width: 10},
    {id:'status', label : t('Status'),  display: true, type: 'string', width: 15},
    {id:'statusDate', label : t('Status date'),  display: true, type: 'date', width: 10},
    
  ]  )
}, [t,i18n])

const refAppendSchoolYearClassSubjectExamSupervisors = useRef<(value: Partial<FieldArray<ISchoolYearClass>> | Partial<FieldArray<ISchoolYearClass>>[], options?: FieldArrayMethodProps) => void>(null);
const refUpdateSchoolYearClassSubjectExamSupervisor = useRef<(index: number,value: Partial<FieldArray<ISchoolYearClass>> ) => void>(null);
const refRemoveSchoolYearClassSubjectExamSupervisor = useRef<(index: number ) => void>(null);


const schoolYearClassSubjectExamSupervisorRowActionIcon = ( schoolYearClassSubjectExamSupervisor: ITeacher) : ActionIconTableRow<ISchoolYearClass,ITeacher> => {
  
  const res: ActionIconTableRow<ISchoolYearClass,ITeacher> = {
    toolTip: 'remove',
    icon: RemoveCircleIcon,
    hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
    isActionExecuting: true,
    onRowClickIcon: (event : any,index: number, row: ITeacher) => {
      
      (refRemoveSchoolYearClassSubjectExamSupervisor.current??emptyFunc)(index);         
    }
  }
  return res;
}

const handleAddSchoolYearClassSubjectExamSupervisors = (event: any) => {
  setCurrentTeacherUsage('supervisor');
  setOpenTeacherFilter(true); 
}

// const handleChangeSchoolYearPeriod = ( event: React.MouseEvent<HTMLElement>, newSchoolYearPeriodId: number ) => {  
//   if(isFalsy(newSchoolYearPeriodId) ) 
//     return;

//   //setCurrentParentType(newParentType);
// }

const getRegistrationStatusList = (row: IRegistration, cellId: keyof IRegistration, 
  opts: {value: string, name: string}[]) => {        
  
    return getAsOptions(refEnumItems.current ?? [],Enum_REGISTRATION_STATUS);
}


useEffect( () => {   
  async function _() {
    
    if(watchSchoolYearId <= 0) return;

    const periods = await getSchoolYearPeriods(watchSchoolYearId);    
    setSchoolYearPeriods([...periods]);

    const exams = await getSchoolYearPeriodExams(watchSchoolYearId);    
    setSchoolYearPeriodExams([...exams]);    
  }          

  refSchoolYearId.current = watchSchoolYearId;
  _();

  }, [watchSchoolYearId]);

// useEffect( () => {   
//   async function _() {
    
//     if(currentSchoolYearPeriodId <= 0) return;

//     const periodExams = await getSchoolYearPeriodExams(watchSchoolYearId);    
//     setSchoolYearPeriods([...periods]);

//     // const exams = await getSchoolYearPeriodExams(watchSchoolYearId);    
//     // setSchoolYearPeriodExams([...exams]);    
//   }          

//   refSchoolYearId.current = watchSchoolYearId;
//   _();

//   }, [currentSchoolYearPeriodId]);
  

  useEffect( () => {   
    async function _() {
      
      if(watchSchoolYearClassTemplateId <= 0) return;
  
      const subjects = await getSchoolYearTemplateSubjects(watchSchoolYearClassTemplateId);    
      setSchoolYearTemplateSubjects([...subjects]);  
    }          
  
    _();  
    }, [watchSchoolYearClassTemplateId]);

    const getSubjectExamStatusList = (row: ISchoolYearClassSubjectExamSchedule, cellId: keyof ISchoolYearClassSubjectExamSchedule, 
      opts: {value: string, name: string}[]) => {        
      
      return getAsOptions(refEnumItems.current ?? [],Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS);
    }

    useEffect( () => {   
      async function _() {
        
        if(isFalsy(watchCurrentSchoolYearPeriodExamId)) {
          if(openSubjectsExam) setSchoolYearClassSubjectExamSchedules([]);
          return;
        }

        if(watchCurrentSchoolYearPeriodExamId <= 0 || watchSchoolYearClassTemplateId <= 0){
          if(openSubjectsExam) setSchoolYearClassSubjectExamSchedules([]);
          return;
        } 
    
        const subjectExamSchedules = await getSchoolYearTemplateSubjectExamSchedules(watchCurrentSchoolYearPeriodExamId, watchSchoolYearClassTemplateId);    
        setSchoolYearTemplateSubjectExamSchedules(subjectExamSchedules);

        if(openSubjectsExam && getValues().id > 0 && (watchCurrentSchoolYearPeriodId || 0) >0) {
          const arr = await getSchoolYearClassSubjectExamSchedules(getValues().id, watchCurrentSchoolYearPeriodId || 0 ,watchCurrentSchoolYearPeriodExamId);
          setSchoolYearClassSubjectExamSchedules(arr); 
        }  
        //setSchoolYearTemplateSubjects([...subjectExamSchedules]);  
      }          
    
      _();  
      }, [watchCurrentSchoolYearPeriodExamId]);

      useEffect( () => {   
        async function _() {
          
          if(isFalsy(watchCurrentSchoolYearTemplateSubjectExamScheduleId) ) return;
          if(watchCurrentSchoolYearTemplateSubjectExamScheduleId <= 0) return;

          const {id: schoolYearClassId} = getValues();
      
          const supervisors = await getTeachersBySubjectExamSchedule(schoolYearClassId, watchCurrentSchoolYearTemplateSubjectExamScheduleId);              
          setValue('currentSchoolYearClassSubjectExamSupervisors', supervisors);          


        }          
      
        _();  
        }, [watchCurrentSchoolYearTemplateSubjectExamScheduleId]);

      
    


  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems])

  
  useEffect( () => {        
    setCurrentFormNameAtom(`${t('Class')} - ${t('Student')}`);
    setCurrentBasicTextFilterProps(basicFilterSchoolYearClass);
    //setCurrentBasicTextFilterProps(basicFilterTeacher);
  }, []);    
    
  /********** This use effect call retreive data wich will call refetch and _data will be updated. 
    and the new useEffect will take place ********************/
  useEffect( () => {
      // setCurrentFormName(t('Billing'));        
      
      if(_id > 0)
        retrieveData('SchoolYearClass',_id, refetch);  
    }, [_id] );


  useEffect( () => {        
    if(_data && _data.id > 0) {      
        reset({..._data});          
    }
  }, [_data]);

  // useEffect( () => {        
  //   async function _() {
          
  //     if(watchId <= 0) {
  //       setRegistrations([]);
  //       return;
  //     }
        
  //     const arr = await getRegistrationsBySchoolYearClass(watchId);              
  //     setRegistrations(arr);          
  //   }        
  
  //   _();  
  // }, [watchId]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultSchoolYearClass);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {    
        if(!checkEntitySaveAuthorization('SchoolYearClass', _id)){
          setIsSaveLoading(false);
             return;
        }
        

          const data = getValues(); 
          if(data.schoolYearId <= 0 || data.classTemplateId <= 0) {
              enqueueSnackbar( t('Invalid data !!!'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
            }      

          if(data.fullTeacherId === data.substituteTeacherId && data.fullTeacherId > 0) {
            enqueueSnackbar( `${t('Invalid data !!!')} - ${t('Full teacher')}`, { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
          }

          mutate({...data, schoolYearClassSubjects: data.schoolYearClassSubjects.map( (x) =>
            ({ ...x, schoolYearClassSubjectSchedules: x.schoolYearClassSubjectPeriods.flatMap( (y) => 
              y.schoolYearClassSubjectSchedules ) }) )
          });
      }
    
    const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
      openEntityActionDrawer('SchoolYearClass', _id);
    }
    
    const [openSubjectExamSupervisors, setOpenSubjectExamSupervisors] = useState<boolean>(false);
    const actionDataManageSchoolYearClassSubjectExamSupervisor = async (event: MouseEvent<HTMLButtonElement>) => {
      
      setOpenSubjectExamSupervisors(true);
    }

    const handleOkManageSchoolYearClassSubjectExamSupervisor = async () => {
      const {id, currentSchoolYearTemplateSubjectExamScheduleId, currentSchoolYearClassSubjectExamSupervisors} = getValues(); 
      if(isFalsy(currentSchoolYearTemplateSubjectExamScheduleId))
        return;

      if(isFalsy(currentSchoolYearClassSubjectExamSupervisors)) return;

      mutateManageSubjectExamSupervisors({id, schoolYearTemplateSubjectExamScheduleId: currentSchoolYearTemplateSubjectExamScheduleId||0 
            ,supervisorIds: currentSchoolYearClassSubjectExamSupervisors.map( ({id}) => id ) });
    }

    const [schoolYearClassSubjectExamSchedules, setSchoolYearClassSubjectExamSchedules] = useState<ISchoolYearClassSubjectExamSchedule[]>([]);
    const [filteredSchoolYearClassSubjectExamSchedules, setFilteredSchoolYearClassSubjectExamSchedules] = useState<number[]>([]);

    
    const [subjectExamDialogTitle, setSubjectExamDialogTitle] = useState<string>('');
    const [openSubjectExam, setOpenSubjectExam] = useState<boolean>(false); // for open dialog to select subject exam
    const [openSubjectsExam, setOpenSubjectsExam] = useState<boolean>(false); // for open dialog to select subjects exam in case of batch
    const [displayCurrentSchoolYearClassSubjectExamStatusPurpose, setDisplayCurrentSchoolYearClassSubjectExamStatusPurpose] = useState<boolean>(false);
    
    const actionDataValidateSubjectExamSchoolYearClass = async (event: MouseEvent<HTMLButtonElement>) => {
      setSubjectExamDialogTitle(t('Validate marks of subject'));
      setCurrentSubjectExamAction('validate');
      setDisplayCurrentSchoolYearClassSubjectExamStatusPurpose(false);
      setOpenSubjectExam(true);
    }

    const actionDataValidateBatchSubjectExamSchoolYearClass = async (event: MouseEvent<HTMLButtonElement>) => {
      setSubjectExamDialogTitle(t('Validate marks of subjects'));
      setCurrentSubjectExamAction('validate-batch');
      setDisplayCurrentSchoolYearClassSubjectExamStatusPurpose(false);
      setOpenSubjectsExam(true);
    }
    

    const actionDataCancelPropositionSubjectExamSchoolYearClass = async (event: MouseEvent<HTMLButtonElement>) => {
      setSubjectExamDialogTitle(`${t('Cancel marks of subject')} (Proposition)`);
      setCurrentSubjectExamAction('cancel-proposition');
      setDisplayCurrentSchoolYearClassSubjectExamStatusPurpose(true);
      setOpenSubjectExam(true);
    }

    const actionDataCancelCancellationSubjectExamSchoolYearClass = async (event: MouseEvent<HTMLButtonElement>) => {
      setSubjectExamDialogTitle(`${t('Cancel cancellation marks of subject')} (- => +)`);
      setCurrentSubjectExamAction('cancel-cancellation');
      setDisplayCurrentSchoolYearClassSubjectExamStatusPurpose(true);
      setOpenSubjectExam(true);
    }

    const actionDataCancelSubjectExamSchoolYearClass = async (event: MouseEvent<HTMLButtonElement>) => {
      setSubjectExamDialogTitle(`${t('Cancel marks of subject')}`);
      setCurrentSubjectExamAction('cancel');
      setDisplayCurrentSchoolYearClassSubjectExamStatusPurpose(true);
      setOpenSubjectExam(true);
    }

    const actionDataUnvalidateSubjectExamSchoolYearClass = async (event: MouseEvent<HTMLButtonElement>) => {
      setSubjectExamDialogTitle(`${t('Unvalidate marks of subject')}`);
      setCurrentSubjectExamAction('unvalidate');
      setDisplayCurrentSchoolYearClassSubjectExamStatusPurpose(true);
      setOpenSubjectExam(true);
    }


    const handleOkSubjectExamAction = async () => {
      const {id, currentSchoolYearTemplateSubjectExamScheduleId : schoolYearTemplateSubjectExamScheduleId,
        currentSchoolYearClassSubjectExamStatusPurpose: purpose } = getValues(); 
      if(isFalsy(schoolYearTemplateSubjectExamScheduleId))
        return;

      // here  we validate by template subject schedule exam schedule
      mutateValidateSubjectExamSchedule({id, schoolYearTemplateSubjectExamScheduleId, purpose});
      // else if(currentSubjectExamAction === 'unvalidate')
      //   mutateUnvalidateSubjectExamSchedule({id, schoolYearTemplateSubjectExamScheduleId});
      

    }

    const handleOkSubjectsExamAction = async () => {
      const {id } = getValues(); 
      if(isFalsy(watchCurrentSchoolYearPeriodExamId))
        return;

     if(isFalsy(filteredSchoolYearClassSubjectExamSchedules) || filteredSchoolYearClassSubjectExamSchedules.length === 0){      
      enqueueSnackbar( `${t('Invalid data !!!')} ???`, { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      return;      
   }

     if( filteredSchoolYearClassSubjectExamSchedules.some(x => x<=0)){      
        enqueueSnackbar( `${t('Invalid data !!!')} - ${t('Subject')}, ${t('Status')}`, { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;      
     }
      

     if(currentSubjectExamAction === 'validate-batch') // here  we validate by Class subject schedule exam schedule
        mutateValidateBatchSubjectExamSchedule({id, 
            schoolYearPeriodExamId: watchCurrentSchoolYearPeriodExamId,  
            schoolYearClassSubjectExamScheduleIds: filteredSchoolYearClassSubjectExamSchedules});
            
    }
   
    
    
    const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
      openEntityPrintDrawer('SchoolYearClass', _id);
    }

    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={12} lg={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                                <Button id='btnActionManageSchoolYearClassSubjectExamSupervisor' onClick={actionDataManageSchoolYearClassSubjectExamSupervisor} sx={ {display:'none'}}  />

                                <Button id='btnActionValidateSubjectExamSchoolYearClass' onClick={actionDataValidateSubjectExamSchoolYearClass} sx={ {display:'none'}}  />
                                <Button id='btnActionValidateBatchSubjectExamSchoolYearClass' onClick={actionDataValidateBatchSubjectExamSchoolYearClass} sx={ {display:'none'}}  />

                                
                                <Button id='btnActionCancelCancellationSubjectExamSchoolYearClass' onClick={actionDataCancelCancellationSubjectExamSchoolYearClass} sx={ {display:'none'}}  />
                                <Button id='btnActionCancelPropositionSubjectExamSchoolYearClass' onClick={actionDataCancelPropositionSubjectExamSchoolYearClass} sx={ {display:'none'}}  />

                                <Button id='btnActionCancelSubjectExamSchoolYearClass' onClick={actionDataCancelSubjectExamSchoolYearClass} sx={ {display:'none'}}  />
                                <Button id='btnActionUnvalidateSubjectExamSchoolYearClass' onClick={actionDataUnvalidateSubjectExamSchoolYearClass} sx={ {display:'none'}}  />

                                
                                

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                                <Controller name='schoolYearId' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="schoolYearId"
                                        label={t('School year')} inputProps={ {readOnly: false}} focused >
                                        {schoolYears && schoolYears.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                 
                                <TextField sx={{width:'calc(20% - 8px)'}} id="classTemplateName" label={t('Study level')} 
                                  {...register('classTemplateName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={handleClickOpenSchoolYearClassTemplate}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }} 
                                />
                                <TextField sx={{width:'calc(20% - 8px)'}} id="id" label={t('Name')} {...register('name')} /> 
                                <FormControlLabel sx={{width:'calc(10% - 8px)'}}
                                  label={t('Active ?')}
                                  control={
                                  <Controller
                                      name='isActive'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                    />} 
                                  />
                                <TextField sx={{width:'calc(20% - 8px)'}} id="classroomName" label={t('Room')} 
                                  {...register('classroomName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={handleClickOpenClassroom}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }} 
                                />
                                                                 
                                { openSchoolYearClassTemplateFilter && <FormDialog open={openSchoolYearClassTemplateFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Class template')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenSchoolYearClassTemplateFilter(false);}} onOk={()=> {setOpenSchoolYearClassTemplateFilter(false);}}  >
                                      <BasicTextFilterForm<ISchoolYearClassTemplate> {...basicFilterSchoolYearClassTemplate } />
                                </FormDialog> } 
                              { openClassroomFilter && <FormDialog open={openClassroomFilter} maxWidth='md'
                                okText={t('OK')} cancelText={t('Cancel')} title={t('Classroom filter')} onCancel={()=> {setOpenClassroomFilter(false);}} 
                                onClose={()=> {setOpenClassroomFilter(false);}} onOk={()=> {setOpenClassroomFilter(false);}}  >
                                    <BasicTextFilterForm<IClassroom> {...basicFilterClassroom } />
                              </FormDialog> }
                              { openTeacherFilter && <FormDialog open={openTeacherFilter} maxWidth='md'
                                okText={''} cancelText={t('Cancel')} title={t('Teacher filter')} onCancel={()=> {setOpenTeacherFilter(false);}} 
                                onClose={()=> {setOpenTeacherFilter(false);}} onOk={()=> {}}  >
                                    <BasicTextFilterForm<ITeacher> {...basicFilterTeacher } />
                            </FormDialog> }
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='studyType' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(10% - 8px)'}} id="schoolYearId"
                                        label={t('Study type')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_STUDY_TYPE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <TextField sx={{width:'calc(20% - 8px)'}} id="fullTeacherName" label={t('Full teacher')} 
                                  {...register('fullTeacherName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                      readOnly: true,
                                      endAdornment: (                                      
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={handleClickOpenTeacher('full') }>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton>                                                                                               
                                        </InputAdornment>
                                    ) 
                                  }} 
                                />
                                <TextField sx={{width:'calc(20% - 8px)'}} id="substituteTeacherName" label={t('Substitute teacher')} 
                                  {...register('substituteTeacherName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={handleClickOpenTeacher('substitute') }>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }} 
                                />
                                <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat    
                                        label={`# ${t('Student')}(s)`} sx={{width:'calc(7% - 8px)'}} //disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name={`countStudents`}
                                  control={control}
                              />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat    
                                        label={`# ${t('Boy')}(s)`} sx={{width:'calc(7% - 8px)'}} //disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name={`countBoys`}
                                  control={control}
                              />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat    
                                        label={`# ${t('Girl')}(s)`} sx={{width:'calc(6% - 8px)'}} //disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name={`countGirls`}
                                  control={control}
                              />
                            </Box>   
                            
                            
                        </Stack>                        
                      </Grid>
                      
                  <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                    <Stack flexDirection='column'>                
                      <Box sx={{ mt: 1, width: '100%' }} >
                        <ArrayFieldTableEx<ISchoolYearClass,ISchoolYearClassSubject,'id'> 
                            key={`Report field - IReportTableDefaultParameter ${'reportTable.name'}`}
                            mainObject={getValues()} fieldKey='id' 
                            headCells={headSchoolYearClassSubjectCells} 
                            rowsPathName={`schoolYearClassSubjects`}  
                            title={t(`Subjects`)} rowActionIcon={schoolYearClassSubjectRowActionIcon}  
                            
                            //onRowSelected={handleSchoolYearTemplateSubjectSelected}                                                    
                            refAppend={refAppendSchoolYearClassSubjects as MutableRefObject<(value: Partial<FieldArray<ISchoolYearClass>> | Partial<FieldArray<ISchoolYearClass>>[], options?: FieldArrayMethodProps) => void>}
                            refUpdate={refUpdateSchoolYearClassSubject as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYearClass>>) => void>}
                            refRemove={refRemoveSchoolYearClassSubject as MutableRefObject<(index: number) => void>}
                                              //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                            toolbarActions={[
                              { toolTip: `${t('Add')}...`, onClickIcon: handleAddSchoolYearClassSubjects ,icon: AddCircleIcon,  },
                              { toolTip: `${t('Refresh')}...`, onClickIcon: handleAddAllSchoolYearClassSubjects ,icon: RefreshIcon,  },
                              { toolTip: `${t('Set teacher for all subjects')}...`, onClickIcon: handleSetTeacherForAllSubjects ,icon: ArrowDropDownCircleIcon,  },
                            ]}
                          />
                          
                        { openSchoolYearClassSubjectSchedule && <FormDialog open={openSchoolYearClassSubjectSchedule} maxWidth='md'
                            okText='' cancelText='' title={t('Subjects')} onCancel={()=> {}} 
                            onClose={()=> {setOpenSchoolYearClassSubjectSchedule(false);}} onOk={()=> {setOpenSchoolYearClassSubjectSchedule(false);}}  >
                              <Stack flexDirection='column' key={`exam schedule - `} >
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <ToggleButtonGroup size="small"
                                    color="primary"
                                    value={currentSchoolYearPeriodId}
                                    exclusive
                                    onChange={ (event, newSchoolYearPeriodId) => handleChangeSchoolYearPeriod(event, newSchoolYearPeriodId)}
                                    aria-label="Small sizes" fullWidth
                                  >
                                    {currentSchoolYearClassSubject.schoolYearClassSubjectPeriods.map( 
                                      ({schoolYearPeriodId, schoolYearPeriodName}) => 
                                        (<ToggleButton key={schoolYearPeriodId} value={schoolYearPeriodId}>{schoolYearPeriodName}</ToggleButton>)
                                    )}
                                  </ToggleButtonGroup> 
                                </Box>
                                { 
                                  currentSchoolYearClassSubject.schoolYearClassSubjectPeriods.map( 
                                    ({schoolYearPeriodId, schoolYearPeriodName}, idxPeriod) =>
                                      ( (schoolYearPeriodId === currentSchoolYearPeriodId) && <Box key={`subject schedule - ${idxPeriod}`} sx={{ mt: 4, width: '100%' }} >
                                        <ArrayFieldTableEx<ISchoolYearClass,ISchoolYearClassSubjectSchedule,'id'> 
                                            key={` ${currentSchoolYearClassSubject.schoolYearTemplateSubjectLongName} ${schoolYearPeriodId}`}
                                            mainObject={getValues()} fieldKey='id' 
                                            headCells={headSchoolYearClassSubjectScheduleCells} 
                                            rowsPathName={`schoolYearClassSubjects.${schoolYearClassSubjectIndex}.schoolYearClassSubjectPeriods.${idxPeriod}.schoolYearClassSubjectSchedules`}  
                                            title={` ${t('Scheduling')} - ${schoolYearPeriodName}`} 
                                            rowActionIcon={schoolYearClassSubjectScheduleRowActionIcon}  
                                            
                                            //onRowSelected={handleSchoolYearClassTemplateSelected}                                                    
                                            refAppend={refAppendSchoolYearClassSubjectSchedules as MutableRefObject<(value: Partial<FieldArray<ISchoolYearClassTemplate>> | Partial<FieldArray<ISchoolYearClassTemplate>>[], options?: FieldArrayMethodProps) => void>}
                                            refUpdate={refUpdateSchoolYearClassSubjectSchedule as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYearClassTemplate>>) => void>}
                                            refRemove={refRemoveSchoolYearClassSubjectSchedule as MutableRefObject<(index: number) => void>}
                                                              //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                            toolbarActions={[
                                              { toolTip: `${t('Add')}...`, onClickIcon: handleAddSchoolYearClassSubjectSchedules(schoolYearPeriodId) ,icon: AddCircleIcon,  },
                                            ]}
                                          />
                                      </Box>)                                      
                                    )
                                }
                              </Stack>
                        </FormDialog> }  
                        { openSchoolYearTemplateSubject && <FormDialog open={openSchoolYearTemplateSubject} maxWidth='md'
                                  okText={t('OK')} cancelText='' title={`${t('Subject')} ....`} onCancel={()=> {}} 
                                  onClose={()=> {setOpenSchoolYearTemplateSubject(false);}} onOk={handleOkSchoolYearTemplateSubject}  >
                                    <EnhancedTable<ISchoolYearTemplateSubject> rows={currentSchoolYearTemplateSubjects} 
                                      headCells={[            
                                        {id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                        {id:'longName', label : t('Long name'),  display: true, type: 'string', },
                                        {id:'shortName', label : t('Short name'),  display: true, type: 'string', },
                                        
                                        
                                      ]} 
                                      title={t(`Subjects`)} objKey='id' 
                                      stateSelected={[filteredSchoolYearTemplateSubjects, setFilteredSchoolYearTemplateSubjects]} 
                                      onRowSelected={undefined} rowCheckedMode='multiple'
                                      onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                      onRowDoubleClick={handleSchoolYearTemplateSubjectDoubleClick} 
                                      rowActionIcon={undefined}
                                      toolbarActions={[
                                        // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                      ]}
                                    />
                              </FormDialog> }
                      </Box>
                    </Stack>
                  </Grid>      
                  <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                    <Stack flexDirection='column'>
                      { ((getValues().id || 0) > 0) &&  <Box display="flex" justifyContent="center" flexWrap="wrap" gap={1} sx={{ mt: 1, width: '100%' }} flexDirection='row' >
                            <WidgetSummaryBasic title={`- ${t('Amount')}`} paletteColor={theme.palette['info']} 
                              iconName='IoSendSharp' total={(schoolYearClassRegistrations??defaultSchoolYearClass).amount} />
                            <WidgetSummaryBasic title={`- ${t('Amount paid')} `} paletteColor={theme.palette['success']} 
                              iconName='IoSendSharp' total={(schoolYearClassRegistrations??defaultSchoolYearClass).amountPaid}  />
                            <WidgetSummaryBasic title={`- ${t('Due amount')} `} paletteColor={theme.palette['warning']} 
                              iconName='IoSendSharp' total={(schoolYearClassRegistrations??defaultSchoolYearClass).dueAmount}  />
                            <WidgetSummaryBasic title={`- ${t('Unpaid amount')} `} paletteColor={theme.palette['error']} 
                              iconName='IoSendSharp' total={(schoolYearClassRegistrations??defaultSchoolYearClass).unpaidAmount} />
                          </Box> }
                      <Box sx={{ mt: 3, width: '100%' }} >
                        <EnhancedTable<IRegistration> rows={ ( (schoolYearClassRegistrations??defaultSchoolYearClass).registrations || []).filter(r => r.status === '10') } 
                              headCells={[            
                                {id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                {id:lg.startsWith('fr')?'lastName':'firstName', label : lg.startsWith('fr')?t('Last name'):t('First name'),  display: true, type: 'string', },
                                {id:lg.startsWith('fr')?'firstName':'lastName', label : lg.startsWith('fr')?t('First name'):t('Last name'),  display: true, type: 'string', },
                                {id:'status', label : t('Status'),  display: true, type: 'string', getOptions: getRegistrationStatusList },
                                {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', },
                                {id:'schoolFeesDueAmount', label : `${t('Due amount')} (SC)`,  display: true, type: 'numeric', },
                                {id:'unpaidAmount', label : t('Unpaid amount'),  display: true, type: 'numeric', },
                                {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', },
                                
                              ]} 
                              title={t(`Active students`)} objKey='id' 
                              stateSelected={undefined} 
                              onRowSelected={undefined} rowCheckedMode='single'
                              onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                              onRowDoubleClick={handleRegistrationDoubleClick} 
                              rowActionIcon={studentRowActionIcon}
                              toolbarActions={[
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                              ]}
                            />
                      </Box>

                      { (getValues().registrations || []).some(r => r.status !== '10') &&
                      <Box sx={{ mt: 1, width: '100%' }} >
                        <EnhancedTable<IRegistration> rows={ ((schoolYearClassRegistrations??defaultSchoolYearClass).registrations || []).filter(r => r.status !== '10') } 
                              headCells={[            
                                {id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                {id:lg.startsWith('fr')?'lastName':'firstName', label : lg.startsWith('fr')?t('Last name'):t('First name'),  display: true, type: 'string', },
                                {id:lg.startsWith('fr')?'firstName':'lastName', label : lg.startsWith('fr')?t('First name'):t('Last name'),  display: true, type: 'string', },
                                {id:'status', label : t('Status'),  display: true, type: 'string', getOptions: getRegistrationStatusList },
                                {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', },
                                {id:'schoolFeesDueAmount', label : `${t('Due amount')} (SC)`,  display: true, type: 'numeric', },
                                {id:'unpaidAmount', label : t('Unpaid amount'),  display: true, type: 'numeric', },
                                {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', },
                                
                              ]} 
                              title={t(`Inactive students`)} objKey='id' 
                              stateSelected={undefined} 
                              onRowSelected={undefined} rowCheckedMode='single'
                              onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                              onRowDoubleClick={handleRegistrationDoubleClick} 
                              rowActionIcon={undefined}
                              toolbarActions={[
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                              ]}
                            />
                      </Box> }
                      { openSubjectExamSupervisors && <FormDialog open={openSubjectExamSupervisors} maxWidth='md'
                          okText={` ${t('Save supervisor')}(s)`} cancelText='' title={t('Examination supervisors')} onCancel={()=> {}} 
                          onClose={()=> {setOpenSubjectExamSupervisors(false);}} onOk={handleOkManageSchoolYearClassSubjectExamSupervisor}  >
                              <Stack flexDirection='column'>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller name='currentSchoolYearPeriodId' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(33% - 8px)'}} id="currentSchoolYearPeriodId"
                                        label={t('Period')} inputProps={ {readOnly: false}} focused >
                                        {schoolYearPeriods.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                  />
                                  <Controller name='currentSchoolYearPeriodExamId' control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(33% - 8px)'}} id="currentSchoolYearPeriodExamId"
                                          label={t('Exam')} inputProps={ {readOnly: false}} focused >
                                          {schoolYearPeriodExams.filter( e => e.schoolYearPeriodId === watchCurrentSchoolYearPeriodId ).map( 
                                            (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                          }
                                        </TextField>
                                      )}
                                  />
                                  <Controller name='currentSchoolYearTemplateSubjectExamScheduleId' control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(34% - 8px)'}} id="schoolYearId"
                                          label={t('Subject')} inputProps={ {readOnly: false}} focused >
                                          {schoolYearTemplateSubjectExamSchedules.map( 
                                            (x,idx) => <MenuItem key={x.id} value={x.id}>{x.subjectLongName}</MenuItem> )
                                          }
                                        </TextField>
                                      )}
                                  />
                                </Box>
                                <Box key={`subject supervisor - `} sx={{ mt: 4, width: '100%' }} >
                                  <ArrayFieldTableEx<ISchoolYearClass,ITeacher,'id'> 
                                      key={` supervisor ...`}
                                      mainObject={getValues()} fieldKey='id' 
                                      headCells={headSchoolYearClassSubjectExamSupervisorCells} 
                                      rowsPathName={`currentSchoolYearClassSubjectExamSupervisors`}  
                                      title={` ${t('Teacher')} - 00000`} 
                                      rowActionIcon={schoolYearClassSubjectExamSupervisorRowActionIcon}  
                                      
                                      //onRowSelected={handleSchoolYearClassTemplateSelected}                                                    
                                      refAppend={refAppendSchoolYearClassSubjectExamSupervisors as MutableRefObject<(value: Partial<FieldArray<ISchoolYearClassTemplate>> | Partial<FieldArray<ISchoolYearClassTemplate>>[], options?: FieldArrayMethodProps) => void>}
                                      refUpdate={refUpdateSchoolYearClassSubjectExamSupervisor as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYearClassTemplate>>) => void>}
                                      refRemove={refRemoveSchoolYearClassSubjectExamSupervisor as MutableRefObject<(index: number) => void>}
                                                        //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                      toolbarActions={[
                                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddSchoolYearClassSubjectExamSupervisors ,icon: AddCircleIcon,  },
                                      ]}
                                    />
                                </Box>
                              </Stack>
                          </FormDialog>
                      }
                      { openSubjectExam && <FormDialog open={openSubjectExam} maxWidth='sm' height='50vh'
                          okText={` ${t('Save')}`} cancelText={t('Cancel')} title={subjectExamDialogTitle} onCancel={()=> {setOpenSubjectExam(false);}} 
                          onClose={()=> {setOpenSubjectExam(false);}} onOk={handleOkSubjectExamAction}  >
                              <Stack flexDirection='column'>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller name='currentSchoolYearPeriodId' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} 
                                        id="currentSchoolYearPeriodId"
                                        label={t('Period')} inputProps={ {readOnly: false}} focused >
                                        {schoolYearPeriods.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                  />
                                  <Controller name='currentSchoolYearPeriodExamId' control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} 
                                          id="currentSchoolYearPeriodExamId"
                                          label={t('Exam')} inputProps={ {readOnly: false}} focused >
                                          {schoolYearPeriodExams.filter( e => e.schoolYearPeriodId === watchCurrentSchoolYearPeriodId ).map( 
                                            (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                          }
                                        </TextField>
                                      )}
                                  />
                                  
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller name='currentSchoolYearTemplateSubjectExamScheduleId' control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearId"
                                          label={t('Subject')} inputProps={ {readOnly: false}} focused >
                                          {schoolYearTemplateSubjectExamSchedules.map( 
                                            (x,idx) => <MenuItem key={x.id} value={x.id}>{x.subjectLongName}</MenuItem> )
                                          }
                                        </TextField>
                                      )}
                                  />
                                </Box>       
                                {displayCurrentSchoolYearClassSubjectExamStatusPurpose &&
                                <Box sx={{ mt: 3, width: '100%' }} >
                                  <Controller name='currentSchoolYearClassSubjectExamStatusPurpose' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearId"
                                        label={t('Purpose of status modification')} 
                                          inputProps={ {readOnly: false}} 
                                          helperText={ 
                                            <FormHelperText>                                    
                                              { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(
                                                  Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS_PURPOSE,`${t('Subject exam')} - ${t('Status purpose')}`,
                                                           `${t('Subject exam')} - ${t('Status purpose')}`)}
                                                sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                                {t('Add')}
                                              </Link> }
                                              { (canUpdateEnumerationItem && !isFalsy(watchCurrentSchoolYearClassSubjectExamStatusPurpose) ) && <Link href="#" onClick={(event) => {
                                                     onUpdateItemClick( Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS_PURPOSE,
                                                         `${t('Subject exam')} - ${t('Status purpose')}`,`${t('Subject exam')} - ${t('Status purpose')}`,
                                                            refEnumItems.current?.find(x => x.enumerationCode === Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS_PURPOSE 
                                                                && x.code === watchCurrentSchoolYearClassSubjectExamStatusPurpose)
                                                          ); }}
                                                sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                                {t('Update')}
                                              </Link> }
                                            </FormHelperText>
                                          } >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS_PURPOSE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                  />
                                </Box> }                         
                              </Stack>
                          </FormDialog>
                      }
                      { openSubjectsExam && <FormDialog open={openSubjectsExam} maxWidth='sm' height='80vh'
                          okText={` ${t('Save')}`} cancelText={t('Cancel')} title={subjectExamDialogTitle} onCancel={()=> {}} 
                          onClose={()=> {setOpenSubjectsExam(false);}} onOk={handleOkSubjectsExamAction}  >
                              <Stack flexDirection='column'>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller name='currentSchoolYearPeriodId' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} 
                                        id="currentSchoolYearPeriodId"
                                        label={t('Period')} inputProps={ {readOnly: false}} focused >
                                        {schoolYearPeriods.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                  />
                                  <Controller name='currentSchoolYearPeriodExamId' control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} 
                                          id="currentSchoolYearPeriodExamId"
                                          label={t('Exam')} inputProps={ {readOnly: false}} focused >
                                          {schoolYearPeriodExams.filter( e => e.schoolYearPeriodId === watchCurrentSchoolYearPeriodId ).map( 
                                            (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                          }
                                        </TextField>
                                      )}
                                  />                                  
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <EnhancedTable<ISchoolYearClassSubjectExamSchedule> rows={schoolYearClassSubjectExamSchedules} 
                                        headCells={[            
                                          {id:'id', label : t('Id'),  display: false, type: 'numeric', },
                                          {id:'subjectLongName', label : t('Subject'),  display: true, type: 'string', width: 55},
                                                
                                          {id:'status', label : t('Status'),  display: true, type: 'string', width: 45, getOptions: getSubjectExamStatusList},
                                                                                    
                                        ]} 
                                        title={t(`Subjects`)} objKey='id' 
                                        stateSelected={[filteredSchoolYearClassSubjectExamSchedules, setFilteredSchoolYearClassSubjectExamSchedules]} 
                                        onRowSelected={undefined} rowCheckedMode='multiple'
                                        onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                        //onRowDoubleClick={handleSchoolYearTemplateSubjectDoubleClick} 
                                        rowActionIcon={undefined}
                                        toolbarActions={[
                                          // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                        ]}
                                      />
                                </Box>       
                                {displayCurrentSchoolYearClassSubjectExamStatusPurpose &&
                                <Box sx={{ mt: 3, width: '100%' }} >
                                  <Controller name='currentSchoolYearClassSubjectExamStatusPurpose' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearId"
                                        label={t('Purpose of status modification')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_SCHOOL_CLASS_SUBJECT_EXAM_STATUS_PURPOSE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                  />
                                </Box> }                         
                              </Stack>
                          </FormDialog>
                      }

                          { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                              {...{open: displayEnumerationItemDialog, 
                                    title: '', 
                                    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                    afterSave: async () => {await refetchEnumerationItems()}}} 
                              />
                          }
                      
                    </Stack>
                  </Grid>                              
                </Grid>
            </Box>
        </FormProvider> 
  )
}

