

export interface IResult<TData> {
    data: TData,
    succeeded: boolean,
    messages: string[]
  }

  export interface IFeatureParameter {
    name: string,
    type: 'string' | 'numeric' | 'boolean' | 'date' | 'time' | 'datetime',
    label: string,
    dataType: 'Base' | 'Enumeration' | 'Entity' | 'Period' | 'Base64File',
    value: any,
    dateValue: Date,
    
    options?: {value: string, name: string}[],
    entities?: {id: number, name: string}[],
    
    isRequired: boolean,
    enumerationCode?: string,

    updateEnumerationItems?: boolean,
    enumerationName?: string,
    enumerationDescription?: string,

    isFormatedString?: boolean,
    isMultiline?: boolean,
    multiLineCount?: number,

    fileName?: string,
    fileAccepedExtension?: string 
}

export interface IFeatureDescription {    
  
    saveBeforeAction?: boolean,

    entityId?: number,
    entityContextualItems?: {entityName: string, entityId: number}[],

    name: string,
    label: string,
    entityName?: string,
    entityItemName?: string,
    type?: number,
    inputType?: number,

    params: IFeatureParameter[]
}

export interface IReportDescription {    
  
    reportId: number,
    language: string,
    saveBeforeAction?: boolean,

    entityId?: number,
    isDirectReport: boolean,

    hubConnectionId?: string,

    name: string,
    label: string,
    entityName?: string,

    functionEntityParameters?: {name:string, value: any} []
    //type?: number,
    //inputType?: number,

    // params: IFeatureParameter[]
}

export interface IEntity {
    id: number,
    name?: string,
    description?: string,

    properties: IEntityProperty[]
}

export interface IEntityNameId {
    entityId: number,
    entityName: string,    
}

export interface IEntityProperty {
    name: string,
    description: string,
    type: string,
    isPrimitive: boolean,
    isEnumerable: boolean,

    underlyingType: string
}

export interface  IQueryFeatureDescription { //IQueryMethodFeature
    name: string,
    label: string,

    entityName?: string,
    innerObjectTypeName?: string,
    innerObjectPublicNamespace: string
}

export interface IEntityActionState {
    
    entityStatus : 'Created' | 'Modified' | 'JustModified' | 'Validated' | 'PropositionCancelled' | 'Cancelled' | 'Valid' | 'Invalid',
    
    name: string,
    label: string,
    
}

export const defaultFeatureDescription: IFeatureDescription = {
    name: '',
    label: '',
    params: [],
}

