import React, { useState, useEffect, useRef } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import { useNavigate } from 'react-router-dom';

import { SnackbarAction, SnackbarKey, useSnackbar, VariantType } from 'notistack';
import { useTranslation  } from 'react-i18next';

import { fileTokensAtom, hubConnectionIdAtom, currentUserSessionAtom,
    requestDataNotificationKeyAtom, requestDataItemResponseArrivedIdsAtom,  requestDataSendedIdsAtom, requestDataSendedOkIdsAtom,
    requestDataResponseCheckedIdsAtom, requestDataResponseCheckedOkIdsAtom, requestDataResponseArrivedIdsAtom} from 'library/store';
import { globalConfig } from 'config';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { GrDownload, GrClose, GrPrint, GrLinkNext } from 'react-icons/gr';

import { useDownloadFile } from "library/useDownloadFile";


import axios from 'axios';
import { FormDialog } from './ui/FormDialog';


export const RequestNotification = () => {

    const { t, i18n } = useTranslation();  

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [hubConnectionId, setHubConnectionId] = useRecoilState(hubConnectionIdAtom);

    const [_, setRequestDataNotificationKey] = useRecoilState(requestDataNotificationKeyAtom);

    const {isAuthenticated } = useRecoilValue(currentUserSessionAtom);

    const [fileTokens, setFileTokens] = useRecoilState(fileTokensAtom);
    const [currentFileToken, setCurrentFileToken] = useState<string>('');
    const [currentFileName, setCurrentFileName] = useState<string>('');
    
    const [requestDataSendedIds, setRequestDataSendedIds] = useRecoilState(requestDataSendedIdsAtom);
    const [requestDataItemResponseArrivedIds, setRequestDataItemResponseArrivedIds] = useRecoilState(requestDataItemResponseArrivedIdsAtom);

    const [requestDataSendedOkIds, setRequestDataSendedOkIds] = useRecoilState(requestDataSendedOkIdsAtom);
    const [requestDataResponseCheckedIds, setRequestDataResponseCheckedIds] = useRecoilState(requestDataResponseCheckedIdsAtom);
    const [requestDataResponseCheckedOkIds, setRequestDataResponseCheckedOkIds] = useRecoilState(requestDataResponseCheckedOkIdsAtom);
    const [requestDataResponseArrivedIds, setRequestDataResponseArrivedIds] = useRecoilState(requestDataResponseArrivedIdsAtom);
       

    const [ connection, setConnection ] = useState<HubConnection>();
    const [ chat, setChat ] = useState([]);
    const latestChat = useRef(null);

    // latestChat.current = chat;

    const xActionFileToken = (fileToken: string, fileName: string): SnackbarAction => (snackbarId: SnackbarKey) => (
        <>
            <a href={`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`} ref={refAnchorElement} id="fileDownload" className="hidden" target="__blank" download/>
            <Button onClick={() => { refAnchorElement?.current?.click(); closeSnackbar(snackbarId) }}>   
                <GrDownload size={24} />
            </Button>
            <Button onClick={() => { closeSnackbar(snackbarId) }}>
                <GrClose size={24} />
            </Button>
        </>
    )

    const xActionReportGenerated = (fileToken: string, fileName: string): SnackbarAction => (snackbarId: SnackbarKey) => (
        <>
            
            <Button onClick={() => { 
                    setUrlPdf(`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`); 
                    setOpenPdf(true);
                    closeSnackbar(snackbarId); }}>   
                <GrPrint size={24} />
            </Button>
            <Button onClick={() => { closeSnackbar(snackbarId) }}>
                <GrClose size={24} />
            </Button>
        </>
    )


    const xActionEntityCreated = (entityName: string, entityId: number): SnackbarAction => (snackbarId: SnackbarKey) => (
        <>
            
            <Button onClick={() => { 
                    
                    navigate(`/${entityName.toLowerCase()}/${entityId}`);
                    closeSnackbar(snackbarId); }}>   
                <GrLinkNext size={24} />
            </Button>
            <Button onClick={() => { closeSnackbar(snackbarId) }}>
                <GrClose size={24} />
            </Button>
        </>
    )

    const refAnchorElement = useRef<HTMLAnchorElement | null>(null);
    const action = (snackbarId: SnackbarKey) => (
        <>
            <a href={`${globalConfig.get().apiUrl}/Download/${currentFileToken}/${currentFileName}`} ref={refAnchorElement} id="fileDownload" className="hidden" target="__blank" download/>
            {/* <a
                href={`${globalConfig.get().apiUrl}/Download/${currentFileToken}/${currentFileName}`}
                target="__blank" download>
                <Button onClick={() => { closeSnackbar(snackbarId) }}>   
                Download
                </Button> GrDownload
            </a>            */}
            <Button onClick={() => { refAnchorElement?.current?.click(); closeSnackbar(snackbarId) }}> 
                <GrDownload size={24} />
            </Button> 
            <Button onClick={() => { closeSnackbar(snackbarId) }}>
                <GrClose size={24} />
            </Button>
        </>
      );

    useEffect(() => {

        if(isAuthenticated) {
            const newConnection = new HubConnectionBuilder()
            .withUrl(`${globalConfig.get().apiUrl}/hubs/requestNotifications`)  //'http://localhost:45073/hubs/requestNotifications
            .withAutomaticReconnect()
            .build();

            setConnection(newConnection);
        }
        
    }, [isAuthenticated]);

    useEffect(() => {   
        if (connection) {
            connection.start()
                .then( () => console.log('Connected!'))
                .then( () => { setHubConnectionId(connection?.connectionId || ''); console.log(connection.connectionId); })
                .then( () => {                    
    
                    connection.on('notifyRequestSended', id => { 
                        //console.log(`notifyRequestSended ${id}`);
                        setRequestDataSendedIds([...requestDataSendedIds, Number(id)]);
                        setRequestDataNotificationKey(`notifyRequestSended ${id}`);
                    });

                    connection.on('notifyRequestSendedOk', id => { 
                        //console.log(`notifyResponseChecked ${id}`);
                        setRequestDataSendedOkIds([...requestDataSendedOkIds, Number(id)]);
                        setRequestDataNotificationKey(`notifyRequestSendedOk ${id}`);
                    });

                    connection.on('notifyResponseChecked', id => { 
                        //console.log(`notifyResponseChecked ${id}`);
                        setRequestDataResponseCheckedIds([...requestDataResponseCheckedIds, Number(id)]);
                        setRequestDataNotificationKey(`notifyResponseChecked ${id}`);
                    });

                    connection.on('notifyResponseCheckedOk', id => { 
                        //console.log(`notifyResponseCheckedOk ${id}`);
                        setRequestDataResponseCheckedOkIds([...requestDataResponseCheckedOkIds, Number(id)]);
                        setRequestDataNotificationKey(`notifyResponseCheckedOk ${id}`);
                    });

                    connection.on('notifyResponseArrived', id => { 
                        //console.log(`notifyResponseArrived ${id}`);
                        setRequestDataResponseArrivedIds([...requestDataResponseArrivedIds, Number(id)]);
                        setRequestDataNotificationKey(`notifyResponseArrived ${id}`);
                    });

                    connection.on('notifyItemResponseArrived', id => { 
                        //console.log(`notifyItemResponseArrived ${id}`);
                        setRequestDataItemResponseArrivedIds([...requestDataItemResponseArrivedIds, Number(id)]);
                        setRequestDataNotificationKey(`notifyItemResponseArrived ${id}`);
                    });

                    connection.on('fileToken', ({fileToken,fileName}) => { 

                        setCurrentFileToken(fileToken);
                        setCurrentFileName(fileName);                        

                        enqueueSnackbar( t('You have received new file to download'), { variant: 'info',
                            anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true,
                            action: xActionFileToken(String(fileToken),String(fileName))   } );
                    });

                    connection.on('reportGenerated', ({fileToken,fileName}) => { 

                        setCurrentFileToken(fileToken);
                        setCurrentFileName(fileName);       
                        
                        enqueueSnackbar( `${t('You have received new report')} - ${fileName}`, { variant: 'info',
                            anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
                            action: xActionReportGenerated(String(fileToken),String(fileName))   } );
                    });

                    connection.on('entityCreated', ({entityName,entityId, displayedMessage}) => { 

                        // setCurrentEntityName(entityName);
                        // setCurrentEntityId(entityId as unknown as number);       
                        
                        enqueueSnackbar( `${displayedMessage}`, { variant: 'info',
                            anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
                            action: xActionEntityCreated(String(entityName), entityId as unknown as number)   } );
                    });

                    connection.on('notifyMessage', ({messageType,message}) => { 
                        
                        enqueueSnackbar( `${message} - `, { 
                            variant: String(messageType).toLowerCase() as unknown as VariantType,
                            anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, autoHideDuration : 5000,  } );
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection, enqueueSnackbar, setCurrentFileToken, setCurrentFileName]);


      
    // const sendMessage = async (user, message) => {
    //     const chatMessage = {
    //         user: user,
    //         message: message
    //     };

    //     if (connection.connectionStarted) {
    //         try {
    //             await connection.send('SendMessage', chatMessage);
    //         }
    //         catch(e) {
    //             console.log(e);
    //         }
    //     }
    //     else {
    //         alert('No connection to server yet.');
    //     }
    // }
    const [urlPdf, setUrlPdf] = useState<string>('');
    const [openPdf, setOpenPdf] = useState<boolean>(false);

    return (
        <>
            { openPdf && <FormDialog open={openPdf} maxWidth='md'
                okText='' cancelText='' title={t('Printing')} onCancel={()=> {setOpenPdf(false);}} 
                onClose={()=> {setOpenPdf(false);}} onOk={()=> {setOpenPdf(false);}}  >
                <Box sx={{ mt: 1, width: '100%', height: '100%' }} >
                    <iframe src={urlPdf} width="100%" height="100%" title='kkk'></iframe>
                </Box>
            </FormDialog> }
        </>
    );
};

//export RequestNotification;