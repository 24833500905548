
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import { isNumber, toNumber } from 'lodash';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IClassCouncil, IClassCouncilSearch } from "../models/ClassCouncil";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createClassCouncil = async (classCouncil: IClassCouncil)  =>       
        await (await axios.post('/api/setup/classCouncil/create', classCouncil)).data;       
        
    const updateClassCouncil = async (classCouncil: IClassCouncil)  =>       
        await (await axios.post('/api/setup/classCouncil/update', classCouncil)).data; 
    
    const getClassCouncil = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/classCouncil/get-classCouncil/${id}`));
      return await data;
    }
   

    const getClassCouncils = async (criteria: IClassCouncilSearch) : Promise<IClassCouncil[]> => {

      const {schoolYearId,name, studyType, studyLanguage,studyLevel  } = criteria;
      const {data} = (await axios.get(`/api/setup/classCouncil/get-classCouncils?schoolYearId=${schoolYearId}&name=${name}&studyType=${studyType}` +
          `&studyLanguage=${studyLanguage}&studyLevel=${studyLevel}`));
      return await data;
    }
    
      
    return {    
      createClassCouncil,
      updateClassCouncil,
      getClassCouncil,
      getClassCouncils
      
    } 
}

export default _;

export interface IFilterClassCouncilOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IClassCouncil[], React.Dispatch<React.SetStateAction<IClassCouncil[]>>],
}

const defaultFilterClassCouncilOption: IFilterClassCouncilOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterClassCouncil = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IClassCouncil) => void,
                                            filterOption?: IFilterClassCouncilOption  ) => {

  const { getClassCouncils } = _();

  const { t, i18n } = useTranslation();   

  const {schoolYears, applicationSetup} = useRecoilValue(currentUserSessionAtom);
   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterClassCouncilOption;



  const [headClassCouncilCells, setHeadClassCouncilCells]  = useState<HeadCell<IClassCouncil>[]>([
    {id:'id', label : t('Id'),  display: false, type: 'numeric', },    
    {id:'schoolYearClassName', label : t('Name'),  display: true, type: 'string', },

    {id:'meetingSummary', label : t('Summary'),  display: true, type: 'string', },      
    {id:'meetingDate', label : t('Date'),  display: true, type: 'date', }, 

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [         
      
    {name: 'schoolYearId', text: t('School year'), value: String(applicationSetup.currentSchoolYearId), dataType: 'enumeration', 
      options: //? [{name: t('Selected year'), value: String(schoolYearId)}]: 
        [...schoolYears.map( ({id, name}) => ({value: String(id), name}) )]
    },

    {name: 'studyType', text: t('Study type'), value: ''},
    {name: 'studyLanguage', text: t('Study language'), value: ''},
    {name: 'name', text: t('Name'), value: ''},
    {name: 'studyLevel', text: t('Study level'), value: ''},
      
    ]);    

  const [filteredClassCouncils, ] = useState<IClassCouncil[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IClassCouncil[]> => {
    
    const temp = filterElements.find( elt => elt.name === 'schoolYearId')?.value || '0';
    const _schoolYearId = toNumber(temp);

    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const studyType = filterElements.find( elt => elt.name === 'studyType')?.value || '';
    const studyLanguage = filterElements.find( elt => elt.name === 'studyLanguage')?.value || '';
    const studyLevel = filterElements.find( elt => elt.name === 'studyLevel')?.value || '';

    const arr = await getClassCouncils( {schoolYearId: _schoolYearId, name, studyType, studyLanguage, studyLevel} );
   
    return arr;
  }

  const objKey: keyof IClassCouncil = 'id';

  return {
    title: t('Class council'), headCells: headClassCouncilCells, objKey,
    filterElements, rows: filteredClassCouncils, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
