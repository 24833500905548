import { useCallback } from "react";
import { SnackbarAction, SnackbarKey, useSnackbar, VariantType } from "notistack";
import { Button } from "@mui/material";
import { GrClose, GrDownload } from "react-icons/gr";



// Custom hook that handles both the snackbar display and file download
export const useDownloadTextFile = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Function to download the file
  const downloadFile = useCallback((fileName: string, contentArray: string[]) => {
    // Join the array into a single string with line breaks
    const content = contentArray.join("\n");

    // Create a Blob object with the content
    const blob = new Blob([content], { type: "text/plain" });

    // Create a link element
    const link = document.createElement("a");

    // Create a URL for the Blob and set it as the href attribute
    link.href = URL.createObjectURL(blob);

    // Set the download attribute with the desired file name
    link.download = fileName;

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up by revoking the Blob URL
    URL.revokeObjectURL(link.href);
  }, []);

  const xActionReportGenerated = (fileName: string, contentArray: string[]): SnackbarAction => (snackbarId: SnackbarKey) => (
    <>
        
        <Button onClick={() => { 
                downloadFile(fileName, contentArray);
                closeSnackbar(snackbarId); }}>   
            <GrDownload size={24} />
        </Button>
        <Button onClick={() => { closeSnackbar(snackbarId) }}>
            <GrClose size={24} />
        </Button>
    </>
)

  // Function to display the snackbar with a download button
  const displayDownload = useCallback((caption: string, snackVariant: VariantType  ,fileName: string, contentArray: string[]) => {
    enqueueSnackbar(caption, { variant: snackVariant,
      anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true,
      action: xActionReportGenerated(fileName, contentArray),
    });
  }, [enqueueSnackbar, downloadFile]);

  return { displayDownload };
};