
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';
import DetailsIcon from '@mui/icons-material/Details';

import {ThreeDots, Watch} from "react-loader-spinner";


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, 
      isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useRegistrationService, { useBasicFilterRegistration } from './services/Registration';

import useRegistrationMarkService from './services/RegistrationMark';


import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Slide, Typography, Chip, ToggleButtonGroup, ToggleButton } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IRegistration, IMark, ISubjectMark , defaultRegistration } from './models/Registration';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, Enum_REGISTRATION_STATUS, Enum_STUDY_TYPE,
    Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_ARTICLE_OPTION } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';


import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';
import { ISchoolYear, ISchoolYearClass, ISchoolYearClassTemplate, ISchoolYearPeriod, ISchoolYearPeriodExam } from 'features/setup/models/SchoolYear';

import useBusDestinationService, { useBasicFilterBusDestination } from 'features/setup/services/BusDestination';
import { IBusDestination } from 'features/setup/models/BusDestination';

import { DatePicker } from '@mui/x-date-pickers';
import { IClassTemplate } from 'features/setup/models/ClassTemplate';
import useClassTemplateService, { useBasicFilterClassTemplate } from 'features/setup/services/ClassTemplate';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { IBilling, defaultBilling } from 'features/finance/models/Billing';
import { IPerson } from './models/Person';
import usePersonService, { useBasicFilterPerson, useBasicFilterPersonSearch } from './services/Person';
import { IBillingType } from 'features/setup/models/BillingType';
import useBillingTypeService, { useBasicFilterBillingType, useBasicFilterArticleBillingType, useBasicFilterTransportBillingType } from 'features/setup/services/BillingType';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { debounce } from 'lodash';
import { GrClose, GrSearch, GrSearchAdvanced } from 'react-icons/gr';
import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';

import { Colors } from 'themes/theme';
import EnhancedTable from 'components/ui/EnhancedTable';

export interface RegistrationMarkFormProps {    
    registration: IRegistration,
    reportCard: string  
} 

export const RegistrationMarkForm: FC<RegistrationMarkFormProps> = (props: RegistrationMarkFormProps = {registration: defaultRegistration, reportCard: ''}) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();

  const theme = useTheme();

  const {language: lg, schoolYears, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer,
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  const { createRegistration, updateRegistration } = useRegistrationService();

  const { getMarks } = useRegistrationMarkService();

  const { getSchoolYearClasses, getSchoolYearClassTemplates, getSchoolYearPeriods, getSchoolYearPeriodExams } = useSchoolYearService();

  const { getPersons, getPersonsSearchCount, getParentPortables } = usePersonService();

  const {getAvailableArticleBillingTypes} = useBillingTypeService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);
  //const refSchoolYears = useRef<ISchoolYear[]>();

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterRegistration = useBasicFilterRegistration( 
    (event: React.MouseEvent<unknown>, row: IRegistration) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );
  
  const emptyFunc = (obj: any) => {}
  
  const methods = useForm<{reportCard: string}>({defaultValues:{reportCard: ''}});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  
  
  const watchReportCard = watch('reportCard');


   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
   

  
  const queryClient = useQueryClient();
  
    
    // const {data: _data, refetch} = useQuery<IRegistration>(['Registration', _id], () => retrieveEntity('Registration',_id), 
    //   {refetchOnWindowFocus: false ,enabled: false } );
    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItem', Enum_STUDY_LANGUAGE], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_STUDY_LANGUAGE] ));

    const {data: periods } = useQuery<ISchoolYearPeriod[]>(['SchoolYearPeriods', props.registration.schoolYearId], () => getSchoolYearPeriods(props.registration.schoolYearId), 
      {refetchOnWindowFocus: false ,enabled: props.registration.schoolYearId>0 } );

    const {data: exams } = useQuery<ISchoolYearPeriodExam[]>(['SchoolYearPeriodExams', props.registration.schoolYearId], () => getSchoolYearPeriodExams(props.registration.schoolYearId), 
      {refetchOnWindowFocus: false ,enabled: props.registration.schoolYearId>0 } );


    const [currentSchoolYearPeriodId, setCurrentSchoolYearPeriodId] = useState<number>(0);
    const [currentSchoolYearPeriodExamId, setCurrentSchoolYearPeriodExamId] = useState<number>(0);
   // const refSchoolYearPeriodId = useRef(0);
    // const {data: marks } = useQuery<IMark[]>(['Marks', currentSchoolYearPeriodId, props.id], () => getMarks(props.id ,currentSchoolYearPeriodId), 
    //   {refetchOnWindowFocus: false ,enabled: currentSchoolYearPeriodId>0 } );

   const [subjects, setSubjects] = useState<ISubjectMark[]>([]);

   const [marks, setMarks] = useState<IMark[]>([]);

    // const {data: extensionEnumerationItems } = useQuery<IEnumerationItem[]>(['SchoolYear','EnumerationItem', watchSchoolYearId], () =>
    //   getExtensionEnumerationItemsBySchoolYear(watchSchoolYearId) );

    // const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Registration'], () => getEnumerationItemsByEnumerationCodes
    //     ( [Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_REGISTRATION_STATUS, Enum_ARTICLE_OPTION,
    //           Enum_STUDY_TYPE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY ] ));

    //const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );

    
    

  //   const [headBillingCells, setHeadBillingCells]  = useState<HeadCell<IBilling>[]>([]);
  //   useEffect(() => {
  //   setHeadBillingCells([            
  //     {id:'id', label : t('Id'),  display: false, type: 'numeric', },
  //     {id:'billingTypeName', label : t('Billing'),  display: true, type: 'string', width: 40,
  //         },
  //     {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 15},
  //     {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 15},
  //     {id:'unpaidAmount', label : t('Unpaid amount'),  display: true, type: 'numeric', width: 15},
  //     {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 15},
  //   ]  )
  // }, [t,i18n])

  // const refAppendBillings = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
  // const refUpdateBilling = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
  // const refRemoveBilling = useRef<(index: number ) => void>(null);

  const [openRegistrationMarkDetails,setOpenRegistrationMarkDetails] = useState<boolean>(false);
  const [currentBilling, setCurrentBilling] = useState<IBilling>(defaultBilling);
  const [subjectIndex, setSubjectIndex] = useState<number>(-1);
  const subjectRowActionIcon = ( subjectMark: ISubjectMark) : ActionIconTableRow<any,ISubjectMark> => {
  
    const res: ActionIconTableRow<any,ISubjectMark> = {
      toolTip: 'Details',
      icon: MoreIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: ISubjectMark) => {
        
        const subject = subjects.find(x => x.schoolYearTemplateSubjectId === row.schoolYearTemplateSubjectId);
        if(isFalsy(subject)) return;

        setMarks(subject.marks);
        setOpenRegistrationMarkDetails(true);
        //(refRemoveBilling.current??emptyFunc)(index);            
      }
    }
    return res;
  }
  
  const handleChangeSchoolYearPeriod = async ( event: React.MouseEvent<HTMLElement>, newSchoolYearPeriodId: number ) => {  
    if( newSchoolYearPeriodId === null || newSchoolYearPeriodId === undefined) 
      return;
    
    const registrationWithMarks = await getMarks(props.reportCard,props.registration.id, props.registration.schoolYearClassId, props.registration.schoolYearId ,newSchoolYearPeriodId, 0);
    if(registrationWithMarks.length > 0)
      setSubjects(registrationWithMarks[0].subjects);
    //setMarks(allMarks.marks);

    setCurrentSchoolYearPeriodId(newSchoolYearPeriodId);
  }

  const handleChangeSchoolYearPeriodExam = async ( event: React.MouseEvent<HTMLElement>, newSchoolYearPeriodExamId: number ) => {  
    if( newSchoolYearPeriodExamId === null || newSchoolYearPeriodExamId === undefined) 
      return;

    const registrationWithMarks = await getMarks(props.reportCard,props.registration.id, props.registration.schoolYearClassId, props.registration.schoolYearId ,currentSchoolYearPeriodId, newSchoolYearPeriodExamId);
    if(registrationWithMarks.length > 0)
      setSubjects(registrationWithMarks[0].subjects);
    //setMarks(allMarks.marks);

    setCurrentSchoolYearPeriodExamId(newSchoolYearPeriodExamId);
  }
  

  useEffect( () => {        
    setSubjects(props.registration.subjects || []);
  }, []);    

  useEffect( () => {        
    //setCurrentFormNameAtom(t('Mark'));
    setCurrentBasicTextFilterProps(basicFilterRegistration);
  }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
           
        
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
         
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Registration', _id);
      }

      

    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                        <Stack flexDirection='column'>       
                          <Box sx={{ mt: 1, width: '100%' }} key={` box button  - xx`}>
                            <Controller
                              
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:`calc(40% - 8px)`}}
                                  id={`key-subject-`}                               
                                  label={t('Report card')} 
                                  >
                                {enumItems && enumItems.filter( e => e.enumerationCode === Enum_STUDY_LANGUAGE ).map( 
                                  (e,ix) => <MenuItem key={e.code} value={e.code}>{e.name}</MenuItem> )
                                }
                                </TextField>
                              )}
                              
                              name={`reportCard`}
                              control={control}
                            />
                          </Box>
                          { ( (props.registration.subjects || []).length <= 0 ) && <Box sx={{ mt: 1, width: '100%' }} key={` box button ${periods?.length}`}>
                            <ToggleButtonGroup size="small"
                              color="primary"  key={` toggle button ${periods?.length} ${currentSchoolYearPeriodId}`}
                              value={currentSchoolYearPeriodId}
                              exclusive
                              onChange={ (event, newSchoolYearPeriodId) => handleChangeSchoolYearPeriod(event, newSchoolYearPeriodId)}
                              aria-label="Small sizes" fullWidth
                            >
                              <ToggleButton key={0} value={0}>{t('Year')}</ToggleButton>
                              { (periods || []).map( ({id, name}) => 
                                  (<ToggleButton key={id} value={id}>{name}</ToggleButton>)
                              )}
                            </ToggleButtonGroup> 
                          </Box> }
                          {(currentSchoolYearPeriodId > 0) && <Box sx={{ mt: 1, width: '100%' }} key={` box button ${periods?.length}`}>
                            <ToggleButtonGroup size="small"
                              color="primary"  key={` toggle button ${exams?.length} ${currentSchoolYearPeriodExamId}`}
                              value={currentSchoolYearPeriodExamId}
                              exclusive
                              onChange={ (event, newSchoolYearPeriodExamId) => handleChangeSchoolYearPeriodExam(event, newSchoolYearPeriodExamId)}
                              aria-label="Small sizes" fullWidth
                            >
                              <ToggleButton key={0} value={0}>{t('Period')}</ToggleButton>
                              { (exams || []).filter(e => e.schoolYearPeriodId === currentSchoolYearPeriodId).map( ({id, name}) => 
                                  (<ToggleButton key={id} value={id}>{name}</ToggleButton>)
                              )}
                            </ToggleButtonGroup> 
                          </Box>}
                          <Box sx={{ mt: 1, width: '100%' }} key={` marks ${props.registration.id} ${currentSchoolYearPeriodId} `} >
                          {/* { generateIntialRegistrationLoading && <Box sx={{ mt: 1, width: '100%' }}>                                     
                                          <ThreeDots
                                                  color='#00BFFF'
                                                  height={'100%'}
                                                  //width={100}
                                                  //timeout={3000} //3 secs
                                          />        
                                  </Box>                                
                              } */}
                            <EnhancedTable<ISubjectMark> rows={subjects || []} 
                              headCells={[            
                                {id:'subject', label : t('Code'),  display: true, type: 'string', },
                                {id:'longName', label : t('Subject'),  display: true, type: 'string', },
                               
                                // {id:'status', label : t('Status'),  display: true, type: 'string', getOptions: getRegistrationStatusList },
                                // {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', },
                                // {id:'schoolFeesDueAmount', label : `${t('Due amount')} (SC)`,  display: true, type: 'numeric', },
                                // {id:'unpaidAmouregistrationIdnt', label : t('Unpaid amount'),  display: true, type: 'numeric', },
                                {id:'mark', label : t('Mark'),  display: true, type: 'numeric', decimalScale: 2},
                                {id:'maxMark', label : t('Max mark'),  display: true, type: 'numeric', decimalScale: 2, },

                                {id:'reportCardMark', label : `${t('Mark')} - ${t('Report card')}`,  display: true, type: 'numeric', decimalScale: 2},
                                
                                {id:'coefficient', label : t('Coefficient'),  display: true, type: 'numeric', decimalScale: 2, },
                                
                              ]} 
                              title={`${t('Mark')}s`} objKey='subject' 
                              stateSelected={undefined} 
                              onRowSelected={undefined} rowCheckedMode='single'
                              onRowCheckedSelectChange={undefined} order='desc' orderBy='subject'
                              onRowDoubleClick={undefined} 
                              rowActionIcon={subjectRowActionIcon}
                              toolbarActions={[
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                              ]}
                            />
                              
                          </Box>
                          { openRegistrationMarkDetails && <FormDialog open={openRegistrationMarkDetails} maxWidth='md'
                            okText='' cancelText='' title={` :: ${t('Mark')}`} onCancel={()=> {}} 
                            onClose={()=> {setOpenRegistrationMarkDetails(false);}} onOk={()=> {setOpenRegistrationMarkDetails(false);}}  >
                                <Stack flexDirection='column'>       
                                  <Box sx={{ mt: 1, width: '100%' }} key={` box button -- ${periods?.length}`}>
                                    <EnhancedTable<IMark> rows={marks || []} 
                                      headCells={[            
                                        {id:'schoolYearPeriodExamName', label : t('Subject'),  display: true, type: 'string', },
   
                                        {id:'weight', label : t('Weight'),  display: true, type: 'numeric', decimalScale: 2, },

                                        {id:'examValue', label : `${t('Mark')} - ${t('Exam')}`,  display: true, type: 'numeric', decimalScale: 2},
                                        {id:'examMaxValue', label : `${t('Max mark')} - ${t('Exam')}`,  display: true, type: 'numeric', decimalScale: 2, },

                                        {id:'subjectValue', label : `${t('Mark')} - ${t('Subject')}`,  display: true, type: 'numeric', decimalScale: 2},
                                        {id:'subjectMaxValue', label : `${t('Max mark')} - ${t('Subject')}`,  display: true, type: 'numeric', decimalScale: 2, },                                        
                                        {id:'subjectValueWeight', label : `${t('Mark')} ${t('Weight')}`,  display: true, type: 'numeric', decimalScale: 2, },    
                                        
                                        {id:'reportCardValue', label : `${t('Mark')} - ${t('Report card')}`,  display: true, type: 'numeric', decimalScale: 2},
                                        {id:'reportCardValueWeight', label : `${t('Mark')} - ${t('Weight')}`,  display: true, type: 'numeric', decimalScale: 2},
                                        
                                      ]} 
                                      title={`${t('Mark')}s`} objKey='schoolYearPeriodExamName' 
                                      stateSelected={undefined} 
                                      onRowSelected={undefined} rowCheckedMode='single'
                                      onRowCheckedSelectChange={undefined} order='desc' orderBy='schoolYearPeriodExamName'
                                      onRowDoubleClick={undefined} 
                                      rowActionIcon={undefined}
                                      toolbarActions={[
                                        // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                      ]}
                                    />
                                  </Box>
                                </Stack>
                          </FormDialog> }
                          
                        </Stack>
                      </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

