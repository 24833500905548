
export interface IAppreciation {
    id: number,
    appreciationSystemCode: string,    
    
    name: string,
    shortName: string,

    altName: string,
    altShortName: string,
    
    description: string,
    isActive: boolean,

    mark: number,
    minMark: number,   
    maxMark: number    
  }


  export const defaultAppreciation : IAppreciation = {
    id: 0,
    appreciationSystemCode: '',    
    
    name: '',
    shortName: '',
    altName: '',
    altShortName: '',
    description: '',
   
    
    isActive: true,

    mark: 0,
    minMark: 0,   
    maxMark: 0    
  }


  export interface IAppreciationSearch {
        
    name: string,
    description: string;
    shortName: string
  }