
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {get} from 'lodash';

import { Controller, FieldArray, FieldArrayMethodProps, FieldPath, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import TokenIcon from '@mui/icons-material/Token';

import { IApplicationSetup, defaultApplicationSetup } from './models/ApplicationSetup';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useApplicationSetupService, { useBasicFilterApplicationSetup } from './services/ApplicationSetup';
import useApplicationQueryService, {useBasicFilterApplicationQuery} from './services/ApplicationQuery';

import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import {  FormHelperText, IconButton, InputAdornment, Link, MenuItem, Popover, Typography, useTheme } from '@mui/material';
import NumberFormat from 'react-number-format';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration from 'features/configuration/models/Enumeration';

import EntityExpression, {IEntityExpressionProps, defaultEntityExpressionProps} from 'components/ui/EntityExpression';


import { defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';

import md5 from 'md5';
import { IAppLogo, IAppTheme, defaultAppTheme, defaultAppLogo, typographyGroupBoxStyling, carouselImage } from 'themes/commonStyles';
import { IBusinessApplication, IApplicationQuery } from './models/ApplicationQuery';
import { DatePicker } from '@mui/x-date-pickers';
import { isFalsy } from 'utility-types';

import { ChromePicker, ColorResult } from 'react-color';

type QueryType = 'intermediary' | 'policy' | 'policyRisk' | 'policyGet' | 'person';

export const ApplicationSetupForm: FC<IApplicationSetup> = (props: IApplicationSetup = defaultApplicationSetup) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 999 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const { createApplicationSetup, updateApplicationSetup, getApplicationTheme, getApplicationLogo,
       defineApplicationTheme, defineApplicationLogo } = useApplicationSetupService();

  //const {getBusinessApplications} = useApplicationQueryService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const {language: lg, schoolYears} = useRecoilValue(currentUserSessionAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterApplicationSetup = useBasicFilterApplicationSetup( 
    (event: React.MouseEvent<unknown>, row: IApplicationSetup) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const refCurrentBusinessApplicationId = useRef(0);

  const [openEntityExpression, setOpenEntityExpression] = useState(false);
  const [currentExpression, setCurrentExpression] = useState(''); 
  const [currentEntityName, setCurrentEntityName] = useState(''); 
  const [expressionField, setExpressionField] = useState<keyof IApplicationSetup>('certificateDocumentExpression');      

  
  const methods = useForm<IApplicationSetup>({defaultValues:defaultApplicationSetup});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const { register: registerTheme, setValue: setValueTheme ,getValues: getValuesTheme, watch: watchTheme, 
    reset: resetTheme ,control: controlThe , formState: { errors: errorsTheme } } = 
          useForm<IAppTheme>({defaultValues:defaultAppTheme});

  const { register: registerLogo, setValue: setValueLogo ,getValues: getValuesLogo, watch: watchLogo, 
    reset: resetLogo ,control: controlLogo , formState: { errors: errorsLogo } } = 
          useForm<IAppLogo>({defaultValues:defaultAppLogo});

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const watchBase64Logo = watchLogo('base64Logo');

  const watchCurrentSchoolYearId = watch('currentSchoolYearId');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IApplicationSetup>,Error,IApplicationSetup>(
      _id>0?updateApplicationSetup:createApplicationSetup, {   
        onSuccess: (data: IResult<IApplicationSetup>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
                setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['ApplicationSetup',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {isLoading: isLoadingTheme, isError: isErrorTheme, isSuccess:isSuccessTheme, error: errorTheme, 
      mutate: mutateAppplicationTheme } = useMutation<IResult<boolean>,Error,IAppTheme>(
        defineApplicationTheme, {   
          onSuccess: (data: IResult<boolean>) => {
            enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
          
            //_setId(data.data.id);
            setOpenApplicationTheme(false);    
          },
          onError: (err: Error) => {          
            console.log(error);
            enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
                setOpenApplicationTheme(false);
          }
        });


    const {isLoading: isLoadingLogo, isError: isErrorLogo, isSuccess:isSuccessLogo, error: errorLogo, 
      mutate: mutateAppplicationLogo } = useMutation<IResult<boolean>,Error,IAppLogo>(
        defineApplicationLogo, {   
          onSuccess: (data: IResult<boolean>) => {
            enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
          
            //_setId(data.data.id);
            setOpenApplicationLogo(false);    
          },
          onError: (err: Error) => {          
            
            enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
                setOpenApplicationLogo(false);
          }
        });

    const [businessApplications, setBusinessApplications] = useState<IBusinessApplication[]>([]);

    const {data: _data, refetch} = useQuery<IApplicationSetup>(['ApplicationSetup', _id], () => retrieveEntity('ApplicationSetup',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const watchWebhookToken = watch('webhookToken');
      
    const [currentQueryType, setCurrentQueryType] = useState<QueryType>('policy');
    const handleClickSearchApplicationQuery = (queryType: QueryType) => (event: any) => {

      //const {mainBusinessApplicationId} = getValues();

      // if(mainBusinessApplicationId < 0) {
      //   enqueueSnackbar( t('Business application is not selected'), { variant: 'error',
      //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      //   return;
      // }


      setCurrentQueryType(queryType);
      //setOpenApplicationQueryFilter(true);
    }

    const handleLogoClick = (event: any) => {
      event.preventDefault();
      // Your custom click handler logic
      alert('Helper text link clicked!');
    };

    const rand = () => {
      return Math.random().toString(36).substring(2);
    };

    const generateToken = (len: number) : string => {
      return (rand() + rand() + rand() + rand()).substring(0,len);
    };


    const handleClickGenerateWebhookToken = (event: any) => {
      setValue('webhookToken', generateToken(26));
    }

    const handleClickGeneratApplicationApiToken = (event: any) => {
      setValue('applicationApiToken', generateToken(26));
    }

    function openFileDialog() {
      (document as any).getElementById("file-upload").click();
    }
    
    const setFile = (_event: any) => {
      let f = _event.target.files![0];
    
      const fileSizeInKB = f.size / 1024;
      // Check if the file size is within your limit (e.g., 200 KB)
      if (fileSizeInKB > 200) {
        alert(t('File size should be less than 200 KB'));
        return;
      }
    
      var reader = new FileReader();
    
      reader.onload = function () {
    
          var binaryString = reader.result as string;
      
          const base64String = binaryString
                                      .replace('data:', '')
                                      .replace(/^.+,/, '');
    
          setValueLogo("base64Logo", base64String);
          
        };
    
        reader.onerror = function () {
          console.log("File load failed");
        };    
        reader.readAsDataURL(f);    
    };

    useEffect( () => {         
        
        if(watchCurrentSchoolYearId <= 0) return;
    
        const schoolYear = schoolYears.find(x => x.id === watchCurrentSchoolYearId);
        if(isFalsy(schoolYear)) return;
    
        setValue('schoolYearStartDate', schoolYear.startDate);
        setValue('schoolYearEndDate', schoolYear.endDate);
    
      }, [watchCurrentSchoolYearId]);
      
      useEffect( () => {        
        setCurrentFormNameAtom(t('Application Setup'));  
        setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);      
      }, []);   

      useEffect( () => {
               
        // if(_id > 0)
        //   retrieveData('ApplicationSetup',_id, refetch);

          retrieveData('ApplicationSetup',999, refetch);  
        }, [/*_id*/] );

      // useEffect( () => {        
      //   refCurrentBusinessApplicationId.current = watchMainBusinessApplicationId;
      // }, [watchMainBusinessApplicationId]);    

      useEffect( () => {        
        setValue('webhookUrl', `${'_APP_'}/api/misc/hookData/v1/${watchWebhookToken}/receive-hookData` )
      }, [watchWebhookToken]);  
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
               
          // if(_id > 0)
          //   retrieveData('ApplicationSetup',_id, refetch);

            retrieveData('ApplicationSetup',999, refetch);  
          }, [/*_id*/] );


          
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
          
            _setId(_data.id);
            reset(_data);
        }
        }, [_data]);
    
      
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => { 
        if(!checkEntitySaveAuthorization('ApplicationSetup', _id)){
          setIsSaveLoading(false);
             return;
        }
          

        const data = getValues(); 

        const {whatsAppPassword} = data;

        if(data.companyName.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
           }
      
           
         mutate({...data, isPasswordChanged: whatsAppPassword !== '********' ,
         whatsAppPasswordMd5: md5(whatsAppPassword), whatsAppPassword: '',   });          
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('ApplicationSetup', _id);
      }

      const [defColor, setDefColor] = useState<FieldPath<IAppTheme>>('background');

      const handleColorClick = (event: React.MouseEvent<HTMLElement>, defCol: FieldPath<IAppTheme> ) => {
        setDefColor(defCol);
        setAnchorEl(event.currentTarget); 
      }

      const [openApplicationTheme, setOpenApplicationTheme] = useState<boolean>(false);
      const actionDataDefineThemeApplicationSetup = async (event: MouseEvent<HTMLButtonElement>) => {     
        const applicationTheme = await getApplicationTheme();   
                
        if(!isFalsy(applicationTheme)) 
          resetTheme(applicationTheme);

        setOpenApplicationTheme(true);
      }

      const [openApplicationLogo, setOpenApplicationLogo] = useState<boolean>(false);
      const actionDataDefineLogoApplicationSetup = async (event: MouseEvent<HTMLButtonElement>) => {     
        const applicationLogo = await getApplicationLogo();   
                
        if(!isFalsy(applicationLogo)) 
          resetLogo(applicationLogo);

        setOpenApplicationLogo(true);
      }

      const handleOkDefineLogoApplicationSetup = async () => { 
        mutateAppplicationLogo(getValuesLogo());
      }

      const handleOkDefineThemeApplicationSetup = async () => { 
        mutateAppplicationTheme(getValuesTheme());
      }

      const [genericTheme, setGenericTheme] = useState<string>('default');
      const handleClickApplyTheme = (event: any) => {
        switch(genericTheme) {
          case 'default': resetTheme(defaultAppTheme);

          default: resetTheme(defaultAppTheme);
        }
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                                                 
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />                                

                                <Button id='btnActionDefineThemeApplicationSetup' onClick={actionDataDefineThemeApplicationSetup} sx={ {display:'none'}}  />
                                <Button id='btnActionDefineLogoApplicationSetup' onClick={actionDataDefineLogoApplicationSetup} sx={ {display:'none'}}  />
                                
                                <TextField sx={{width:'calc(40% - 8px)'}} id="name" 
                                    label={t('Name')} {...register('companyName')} 
                                    inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } }
                                    helperText={ ''
                                      // <FormHelperText>
                                      //   Need help?{' '}
                                      //   <Link
                                      //     href="#"
                                      //     onClick={handleLogoClick}
                                      //     sx={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                                      //   >
                                      //     Click here
                                      //   </Link>
                                      // </FormHelperText>
                                    } />
                                <TextField sx={{width:'calc(60% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >                                                                 
                              <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                    label={`${t('Password complexity?')}`}
                                    control={
                                    <Controller
                                        name='isPasswordComplexityAllowed'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />                                 
                                <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Password duration')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Duration in days')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='passwordDuration'
                                control={control}
                              />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Password rotation')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Number of differents')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='passwordRotation'
                                control={control}
                              />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Max failed count')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Number of failures')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='maxAccessFailedCount'
                                control={control}
                              />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Access lockout duration')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Duration in minutes')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='accessLockoutDuration'
                                control={control}
                              />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Job schedule')} ...`}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                    label={`${t('Send SMS Batch?')}`}
                                    control={
                                    <Controller
                                        name='isSmsOn'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />   
                                <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                    label={`${t('Send WhatsApp Batch?')}`}
                                    control={
                                    <Controller
                                        name='isWhatsAppOn'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />                             
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                    label={`${t('Schedule reports?')}`}
                                    control={
                                    <Controller
                                        name='isReportScheduleOn'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />   
                                <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                    label={`${t('Schedule features?')}`}
                                    control={
                                    <Controller
                                        name='isFeatureScheduleOn'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />                             
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              
                                <TextField sx={{width:'calc(25% - 8px)'}} id="notificationWhatsAppNumber1" label={t('WhatsApp number 1')} {...register('notificationWhatsAppNumber1')} />                            
                                <TextField sx={{width:'calc(25% - 8px)'}} id="notificationWhatsAppNumber2" label={t('WhatsApp number 2')} {...register('notificationWhatsAppNumber2')} />                            
                            </Box>
                        </Stack>                        
                    </Grid>  
                    <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Application API')} ... ${t('Integration')}`}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} > 
                                <TextField sx={{width:'calc(50% - 8px)'}} id="applicationApiToken" label={t('Token')} 
                                  {...register('applicationApiToken')} inputProps={ {autoComplete: 'new-password', 
                                      style: {textTransform: 'none'}}}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickGeneratApplicationApiToken}>
                                          <TokenIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />       
                                <TextField sx={{width:'calc(50% - 8px)'}} id="websiteApplicationApiToken" label={`${('Token')} (${t('Website')})`} 
                                  {...register('websiteApplicationApiToken')} inputProps={ {autoComplete: 'new-password', 
                                      style: {textTransform: 'none'}}}
                                />                          
                            </Box>
                            <Box sx={{ mt: 2.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('WhatsApp API')} ...`}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} > 
                                <TextField sx={{width:'calc(100% - 8px)'}} id="whatsAppBaseUrl" label={('WhatsApp: Base url')} {...register('whatsAppBaseUrl')} inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'}}} />                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >                                  
                                <TextField sx={{width:'calc(50% - 8px)'}} id="whatsAppIdentityToken" label={t('WhatsApp: Identity token')} {...register('whatsAppIdentityToken')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                                            
                                <TextField sx={{width:'calc(25% - 8px)'}} id="whatsAppLogin" label={t('WhatsApp: Login')} {...register('whatsAppLogin')} inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'}}} />
                                <TextField sx={{width:'calc(25% - 8px)'}} id="whatsAppPasswordMd5" label={t('WhatsApp: Password')} {...register('whatsAppPassword')} type="password" inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'}}} />                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} > 
                                <TextField sx={{width:'calc(100% - 8px)'}} id="webhookUrl" label={('WhatsApp: Webhook')} 
                                  {...register('webhookUrl')} inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'}}}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickGenerateWebhookToken}>
                                          <TokenIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />                                
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('School year')} ...`}
                              </Typography>                              
                            </Box>
                            
                            <Box sx={{ mt: 1, width: '100%' }}>
                              <Controller name='currentSchoolYearId' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(60% - 8px)'}} id="schoolYearId"
                                        label={t('School year')} inputProps={ {readOnly: false}} focused >
                                        {schoolYears && schoolYears.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <Controller control={control}
                                  name='schoolYearStartDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Start date')} 
                                      onChange={onChange} disableOpenPicker                    
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    /> )}
                                />
                                
                                <Controller control={control}
                                  name='schoolYearEndDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('End date')} 
                                      onChange={onChange} disableOpenPicker                    
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    /> )}
                                />   
                                                                                                    
                            </Box>
                            
                            { openApplicationTheme && <FormDialog open={openApplicationTheme} maxWidth='sm' height='70vh'
                                  okText={t('OK')} cancelText={t('Cancel')} title={`${t('Application theme')} ...`} onCancel={()=> {setOpenApplicationTheme(false);}} 
                                  onClose={()=> {setOpenApplicationTheme(false);}} onOk={handleOkDefineThemeApplicationSetup}  >
                                <Stack flexDirection='column'>
                                  <Box sx={{ mt: 1, width: '100%' }} >
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'background')}
                                        sx={{ ml: 1, width: '32%', backgroundColor: getValuesTheme().background, color: theme.palette.getContrastText(getValuesTheme().background) }}>
                                        {t('Background')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'primary')}
                                        sx={{ ml: 1, width: '30%', backgroundColor: getValuesTheme().primary, color: theme.palette.getContrastText(getValuesTheme().primary)}}>
                                        {t('Primary')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'secondary')}
                                        sx={{ ml: 1, width: '30%', backgroundColor: getValuesTheme().secondary, color: theme.palette.getContrastText(getValuesTheme().secondary)}}>
                                        {t('Secondary')}
                                    </Button>                                    
                                  </Box>
                                  <Box sx={{ mt: 1, width: '100%' }} >
                                  <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'gridHeader')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().gridHeader, color: theme.palette.getContrastText(getValuesTheme().gridHeader)}}>
                                        {t('Grid header')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'gridAlt')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().gridAlt, color: theme.palette.getContrastText(getValuesTheme().gridAlt)}}>
                                        {t('Grid alt')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'gridActiveRow')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().gridActiveRow, color: theme.palette.getContrastText(getValuesTheme().gridActiveRow)}}>
                                        {t('Grid active row')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'gridSelectedRows')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().gridSelectedRows, color: theme.palette.getContrastText(getValuesTheme().gridSelectedRows)}}>
                                        {t('Grid selected rows')}
                                    </Button>
                                  </Box>
                                  <Box sx={{ mt: 1, width: '100%' }}>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'menuButton')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().menuButton, color: theme.palette.getContrastText(getValuesTheme().menuButton)}}>
                                        {t('Menu button')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'menuIcon')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().menuIcon, color: theme.palette.getContrastText(getValuesTheme().menuIcon)}}>
                                        {t('Menu icon')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'dark')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().dark, color: theme.palette.getContrastText(getValuesTheme().dark)}}>
                                        {t('Dark')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'light')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().light, color: theme.palette.getContrastText(getValuesTheme().light)}}>
                                        {t('Light')}
                                    </Button>
                                  </Box>
                                  <Box sx={{ mt: 1, width: '100%' }}>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'success')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().success, color: theme.palette.getContrastText(getValuesTheme().success)}}>
                                        {t('Success')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'info')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().info, color: theme.palette.getContrastText(getValuesTheme().info)}}>
                                        {t('Info')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'danger')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().danger, color: theme.palette.getContrastText(getValuesTheme().danger)}}>
                                        {t('Danger')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'warning')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().warning, color: theme.palette.getContrastText(getValuesTheme().warning)}}>
                                        {t('Warning')}
                                    </Button>
                                  </Box>
                                  <Box sx={{ mt: 1, width: '100%' }}>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'muted')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().muted, color: theme.palette.getContrastText(getValuesTheme().muted)}}>
                                        {t('Muted')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'border')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().border, color: theme.palette.getContrastText(getValuesTheme().border)}}>
                                        {t('Border')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'inverse')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().inverse, color: theme.palette.getContrastText(getValuesTheme().inverse)}}>
                                        {t('Inverse')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'shaft')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().shaft, color: theme.palette.getContrastText(getValuesTheme().shaft)}}>
                                        {t('Shaft')}
                                    </Button>
                                  </Box>
                                  <Box display='flex' flexDirection='row' alignContent='space-around' justifyContent='center' sx={{ mt: 4.5, width: '100%' }} >                                     
                                      <TextField select onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                              setGenericTheme(event.target.value);}} value={genericTheme} sx={{width:'calc(30% - 8px)'}} id="genericTheme"
                                            label={t('Generic theme')} inputProps={ {readOnly: false}} >
                                          <MenuItem value='default'>{t('Default')}</MenuItem>
                                          <MenuItem value='blue'>{t('Blue')}</MenuItem>
                                          <MenuItem value='green'>{t('Green')}</MenuItem>
                                          <MenuItem value='yellow'>{t('Yellow')}</MenuItem>
                                          <MenuItem value='red'>{t('Red')}</MenuItem>
                                          <MenuItem value='pink'>{t('Pink')}</MenuItem>
                                      </TextField>                                        
                                    <Button variant="outlined" onClick={handleClickApplyTheme} sx={{mb:2, ml: 1 }}>
                                      {t('Apply')}                              
                                    </Button>                                                   
                                  </Box>
                                  <Popover
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={() => {setAnchorEl(null);}}
                                  >
                                    <ChromePicker color={ get(getValuesTheme(), defColor ) as unknown as string } 
                                          onChange={(newColor: ColorResult) => {setValueTheme(defColor, newColor.hex); }} />
                                  </Popover>
                                </Stack>      
                              </FormDialog> } 
                              { openApplicationLogo && <FormDialog open={openApplicationLogo} maxWidth='sm' height='70vh'
                                  okText={t('OK')} cancelText={t('Cancel')} title={`${t('Application theme')} ...`} onCancel={()=> {setOpenApplicationLogo(false);}} 
                                  onClose={()=> {setOpenApplicationLogo(false);}} onOk={handleOkDefineLogoApplicationSetup}  >
                                      <Stack flexDirection='column'>
                                        
                                        <Box sx={{ mt: 1, width: '100%' }} >
                                          <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                                            accept="image/*" />
                                          <div key={'step.id'}>
                                            <Box
                                              component="img"
                                              sx={carouselImage}
                                              //src={`data:image/png;base64,${watchBase64Picture}`}
                                              src={isFalsy(watchBase64Logo)?`/images/product.jpg`:`data:image/png;base64,${watchBase64Logo}`}
                                              style={{ height: '350px', objectFit: 'cover', borderRadius: '2%'}}
                                              onClick={openFileDialog}
                                              //alt={step.id}
                                            ></Box>
                                          </div>
                                        </Box>
                                      </Stack>
                                  </FormDialog> }
                        </Stack>                        
                    </Grid>                  
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

