import { IApplicationSetup, defaultApplicationSetup } from "features/setup/models/ApplicationSetup"
//import { IRequestTypeBusinessApp } from "features/setup/models/RequestType"
import { ISchoolYear } from "features/setup/models/SchoolYear"


export interface IUserCredential {
    userName: string,
    password: string,
    
    language: string,  

    tenantAlias: string,
    hubConnectionId: string,  
  }


  export interface IUserSession {
    isAuthenticated: boolean,
    token: string,
    refreshToken: string,
    language: string,
        
    userName: string,
    userDescription: string,
    intermediaryCode: string,

    shouldResetPassword: boolean,
    isPasswordComplexityAllowed: boolean,
    displayPasswordExpirationWarning: boolean,
    isExtractAndExportAllowed: boolean,

    currentFormName: string,

    currentEntityNameForAction: string,
    currentEntityIdForAction: number,

    isBackendOperation: boolean,

    roleEntities: {
        entityName: string,

        baseEntityName?: string,

        canCreate: boolean,
        canRetreive: boolean,
        canUpdate: boolean,
        printAllowed: boolean,
        worflowAllowed: boolean,
        attachAllowed: boolean,
        relativeViewAllowed: boolean,
        securityAllowed: boolean,
        linkAllowed: boolean,        
        
        caption: string,        
        icon: string,
        name: string,

        roleEntityFeatures: {
              id: number,
              roleEntityId: number,
              featureName: string,
              featureDescription: string,
            }[]
      } [],
    
      schoolYears: ISchoolYear[],
      applicationSetup: IApplicationSetup 
      //requestTypeBusinessApps: IRequestTypeBusinessApp[]
  }


  export const defaultUserSession : IUserSession = {
    isAuthenticated: false,
    token: '',
    refreshToken: '',
    language: 'fr-FR',

    userName: '',
    userDescription: '',
    intermediaryCode: '',

    shouldResetPassword: false,
    isPasswordComplexityAllowed: false,
    displayPasswordExpirationWarning: false,
    isExtractAndExportAllowed: false,
    
    currentFormName: '',

    isBackendOperation: false,

    
    roleEntities: [],
      
    currentEntityNameForAction: '',
    currentEntityIdForAction: 0,

    schoolYears: [],
    applicationSetup: defaultApplicationSetup
    //requestTypeBusinessApps: []
  }


  export interface IPasswordChange {
    userID: number,
    userName: string,
 
    password: string,
    newPassword: string,
    confirmNewPassword: string
  }
  
  export const defaultPasswordChange: IPasswordChange = {
   userID: 0,
   userName: '',
 
   password: '',
   newPassword: '',
   confirmNewPassword: ''
  }