
import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IMenu } from "components/models/Menu";

import { IoSearchCircleSharp } from 'react-icons/io5';


const _ = () => {

    const { t, i18n } = useTranslation();

    const [applicationMenus,setApplicationMenus] = useState<IMenu[]>([]);

    const menu1 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu2 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu3 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }

    const menu4 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu5 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu6 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu7 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }

    //const [applicationMenus,setApplicationMenus] = useState<IMenu[]>();

        useEffect( () => {
          setApplicationMenus(
            [
              {
                ...menu1, name: 'param', text: t('Setting'), iconName:'FiSettings', isOpen: false,
                menuItems: [
                  {name: 'applicationSetup', text: t('Application Setup'), iconName: 'MdSettingsApplications', entityName: 'ApplicationSetup', routeToGo: '/applicationSetup',   order: 1, group: 1},
                  {name: 'applicationQuery', text: t('Application Query'), iconName: 'AiOutlineConsoleSql', entityName: 'ApplicationQuery', routeToGo: '/applicationQuery',   order: 2, group: 1},
                  {name: 'entityActionReport', text: t('Entity Action Report'), iconName: 'AiOutlineConsoleSql', entityName: 'EntityActionReport', routeToGo: '/entityActionReport',   order: 3, group: 1},
                  
                  {name: 'billingType', text: t('Billing type'), iconName: 'TbBrandProducthunt', entityName: 'BillingType', routeToGo: '/billingType',  order: 1, group: 2},
                   
                  {name: 'busDestination', text: t('Bus destination'), iconName: 'BiDirections', entityName: 'BusDestination', routeToGo: '/busDestination',  order: 3, group: 2},

                  {name: 'classroom', text: t('Classroom'), iconName: 'SiGoogleclassroom', entityName: 'Classroom', routeToGo: '/classroom',  order: 1, group: 3},
                  {name: 'appreciation', text: t('Appreciation'), iconName: 'BsAward', entityName: 'Appreciation', routeToGo: '/appreciation',  order: 2, group: 3},

                  {name: 'resourceType', text: t('Resource type'), iconName: 'BiDirections', entityName: 'ResourceType', routeToGo: '/resourceType',  order: 1, group: 4},
                  
                  
                  //{name: 'requestType', text: t('Request type'), iconName: 'GrDocumentConfig', entityName: 'RequestType', routeToGo: '/requestType',  order: 2, group: 3},           

                  //{name: 'coverage', text: `${t('Coverage')} - ${t('Extra')}`, iconName: 'GiProtectionGlasses', entityName: 'Coverage', routeToGo: '/coverage',  order: 1, group: 4},           
                  //{name: 'productType', text: t('Product type'), iconName: 'FiType', entityName: 'ProductType', routeToGo: '/productType',  order: 2, group: 4},           
                  //{name: 'product', text: t('Product'), iconName: 'TbBrandProducthunt', entityName: 'Product', routeToGo: '/product',  order: 3, group: 4},           
                ]
              }, 
              
              { 
                ...menu2, name: 'config', text: t('Configuration'), iconName:'MdPermDataSetting', isOpen: false,
                menuItems: [
                  {name: 'extensionType', text: t('Extension type'), iconName: 'VscExtensions' , entityName: 'ExtensionType',routeToGo: '/extensionType', order: 0, group: 1},
                  {name: 'enumeration', text: t('Codification'), iconName: 'RiQrCodeFill' , entityName: 'Enumeration',routeToGo: '/enumeration', order: 1, group: 1},
                  // {name: 'matrix', text: 'Matrix', entityName: 'Matrix',routeToGo: '/', order: 2, group: 1},
                  // {name: 'vector', text: 'Vector', entityName: 'Vector',routeToGo: '/', order: 2, group: 1},
                  {name: 'report', text: t('Report'), iconName: 'TbFileReport', entityName: 'Report',routeToGo: '/report', order: 0, group: 2},

                  {name: 'role', text: t('Role'), iconName: 'TbLockAccess', entityName: 'Role', routeToGo: '/role',  order: 0, group: 3},
                  //{name: 'group', text: 'Group', entityName: 'Role', routeToGo: '/',  order: 1, group: 1},
                  //{name: 'profile', text: 'Profile', entityName: 'Profile', routeToGo: '/',  order: 1, group: 1},
                  {name: 'user', text: t('User'), iconName: 'TbUserCheck', entityName: 'User', routeToGo: '/user',order: 1, group: 3},
                  {name: 'group', text: t('Group'), iconName: 'MdGroup', entityName: 'Group', routeToGo: '/group',order: 2, group: 3},

                  {name: 'externalLibrary', text: t('Api file'), iconName: 'VscFileBinary', entityName: 'ExternalLibrary',routeToGo: '/externalLibrary', order: 0, group: 4},

                   //{name: 'matrix', text: t('Matrix'), iconName: 'SiMatrix', entityName: 'Matrix',routeToGo: '/matrix', order: 1, group: 3},
                   //{name: 'tarification', text: t('Tarification'), iconName: 'MdCalculate', entityName: 'Tarification',routeToGo: '/tarification', order: 2, group: 3},
                  //{name: 'tarification', text: t('Tarification'), entityName: 'Tarification',routeToGo: '/tarification', order: 1, group: 1},
                 
                 
                  //{name: 'workflow', text: 'Workflow', entityName: 'Workflow',routeToGo: '/', order: 1, group: 2},
                  //{name: 'entityData', text: 'EntityData', entityName: 'EntityData',routeToGo: '/', order: 1, group: 3},
                  //{name: 'queryData', text: 'QueryData', entityName: 'QueryData',routeToGo: '/', order: 2, group: 3},
                  //{name: 'report', text: 'Report', entityName: 'Report',routeToGo: '/', order: 1, group: 4},            
      
                ]
              },
              { 
                ...menu3, name: 'ressources', text: t('Resources'), iconName:'GrArticle', isOpen: false,
                menuItems: [                                                                                 
                  {name: 'resourceDashboard', text: t('Dashboard'), iconName: 'TbMathSymbols' , entityName: 'ResourceDashboard',routeToGo: '/resourceDashboard', order: 1, group: 1},       

                  {name: 'schoolYearBus', text: t('School bus'), iconName: 'BiBusSchool', entityName: 'SchoolYearBus', routeToGo: '/schoolYearBus',  order: 3, group: 2},

                  {name: 'delivery', text: t('Delivery of items'), iconName: 'VscExtensions' , entityName: 'Delivery',routeToGo: '/delivery', order: 0, group: 3},
                  
                  
                  {name: 'stockMovement', text: `${t('Stock movement')}`, iconName: 'FaArrowDown' , entityName: 'StockMovement',routeToGo: '/stockMovement', order: 2, group: 3},

                  {name: 'physicalInventory', text: `${t('Physical inventory')}`, iconName: 'FaBoxes', entityName: 'PhysicalInventory', routeToGo: '/physicalInventory',  order: 1, group: 4},

                  {name: 'expense', text: `${t('Expense')} - ${t('Pur. Order')}`, iconName: 'IoIosCash', entityName: 'Expense', routeToGo: '/expense',  order: 1, group: 5},

                  {name: 'resource', text: t('Resource'), iconName: 'IoIosConstruct', entityName: 'Resource', routeToGo: '/resource',  order: 1, group: 6}, 
      
                ]
              },
              { 
                ...menu4, name: 'schoolLife', text: t('School life'), iconName:'FaUniversity', isOpen: false,
                menuItems: [

                  {name: 'registration', text: t('Registration'), iconName: 'FaCashRegister', entityName: 'Registration',routeToGo: '/registration', order: 1, group: 1}, 
                  {name: 'admission', text: t('Admission'), iconName: 'FaRegAddressCard', entityName: 'Admission', routeToGo: '/admission',  order: 2, group: 1},

                  {name: 'schoolYear', text: `${t('School year')} - Details`, iconName: 'TbSchool', entityName: 'SchoolYear', routeToGo: '/schoolYear',  order: 1, group: 2},

                  {name: 'basicSchoolYear', text: `${t('School year')} - ${t('Base')}`, iconName: 'TbSchool', entityName: 'SchoolYear', routeToGo: '/basicSchoolYear',  order: 2, group: 2},                  
                  {name: 'basicSchoolYear', text: `${t('School year')} - ${t('Billing')}`, iconName: 'TbSchool', entityName: 'SchoolYear', routeToGo: '/billingSchoolYear',  order: 2, group: 2},
                  {name: 'basicSchoolYear', text: `${t('School year')} - ${t('Class')}`, iconName: 'TbSchool', entityName: 'SchoolYear', routeToGo: '/classSchoolYear',  order: 2, group: 2},

                  
                  {name: 'schoolYearClass', text: `${t('Class')} - ${t('Student')}`, iconName: 'SiFuturelearn', entityName: 'SchoolYearClass',routeToGo: '/schoolYearClass', order: 1, group: 3}, 
                  {name: 'timeTable', text: `${t('Time table')}`, iconName: 'MdOutlineCalendarViewMonth', entityName: 'SchoolYearClass',routeToGo: '/timeTable', order: 2, group: 3}, 
                  {name: 'classCouncil', text: `${t('Class council')}`, iconName: 'FaUsers', entityName: 'ClassCouncil',routeToGo: '/classCouncil', order: 2, group: 3}, 


                  {name: 'student', text: t('Student'), iconName: 'BsPersonCheck', entityName: 'Student',routeToGo: '/student', order: 1, group: 4},
                  {name: 'person', text: t('Person'), iconName: 'MdPeopleOutline', entityName: 'Person',routeToGo: '/person', order: 2, group: 4}, 

                  // {name: 'extensionType', text: t('Extension type'), iconName: 'VscExtensions' , entityName: 'ExtensionType',routeToGo: '/extensionType', order: 0, group: 1},
                  // {name: 'enumeration', text: t('Codification'), iconName: 'RiQrCodeFill' , entityName: 'Enumeration',routeToGo: '/enumeration', order: 1, group: 1},
                  // // {name: 'matrix', text: 'Matrix', entityName: 'Matrix',routeToGo: '/', order: 2, group: 1},
                  // // {name: 'vector', text: 'Vector', entityName: 'Vector',routeToGo: '/', order: 2, group: 1},
                  //  {name: 'report', text: t('Report'), iconName: 'TbFileReport', entityName: 'Report',routeToGo: '/report', order: 1, group: 2},
                  //  {name: 'externalLibrary', text: t('Api file'), iconName: 'VscFileBinary', entityName: 'ExternalLibrary',routeToGo: '/externalLibrary', order: 2, group: 2},

                   //{name: 'matrix', text: t('Matrix'), iconName: 'SiMatrix', entityName: 'Matrix',routeToGo: '/matrix', order: 1, group: 3},
                   //{name: 'tarification', text: t('Tarification'), iconName: 'MdCalculate', entityName: 'Tarification',routeToGo: '/tarification', order: 2, group: 3},
                  //{name: 'tarification', text: t('Tarification'), entityName: 'Tarification',routeToGo: '/tarification', order: 1, group: 1},
                 
                 
                  //{name: 'workflow', text: 'Workflow', entityName: 'Workflow',routeToGo: '/', order: 1, group: 2},
                  //{name: 'entityData', text: 'EntityData', entityName: 'EntityData',routeToGo: '/', order: 1, group: 3},
                  //{name: 'queryData', text: 'QueryData', entityName: 'QueryData',routeToGo: '/', order: 2, group: 3},
                  //{name: 'report', text: 'Report', entityName: 'Report',routeToGo: '/', order: 1, group: 4},            
      
                ]
              },
              // 
              {
                ...menu5, name: 'education', text: t('Education'), iconName:'FaChalkboardTeacher', isOpen: false,
                menuItems: [
                  // {name: 'person', text: t('Person'), entityName: 'Person',routeToGo: '/person', order: 1, group: 1}, 
                  // {name: 'distributionChanel', text: t('Distr. Chanel'), entityName: 'DistributionChanel',routeToGo: '/distributionChanel', order: 1, group: 2},       
                  // {name: 'intermediary', text: t('Intermediary'), entityName: 'Intermediary',routeToGo: '/intermediary', order: 2, group: 2}, 
                  // {name: 'agent', text: 'Agent', entityName: 'Agent',routeToGo: '/agent', order: 3, group: 2}, 
                  
                  {name: 'teacher', text: `${t('Teacher')} - ${t('Employee')}`, iconName: 'FaChalkboardTeacher', entityName: 'Teacher',routeToGo: '/teacher', order: 1, group: 1}, 
                  {name: 'teacherTimeTable', text: `${t('Time table')} - ${t('Teacher')}`, iconName: 'MdOutlineCalendarViewMonth', entityName: 'Teacher',routeToGo: '/teacherTimeTable', order: 2, group: 1}, 

                  //{name: 'employee', text: t('Employee'), iconName: 'MdOutlineCancel', entityName: 'Employee',routeToGo: '/employee', order: 2, group: 1}, 
                  {name: 'classTemplate', text: t('Study level'), iconName: 'SiOpslevel', entityName: 'ClassTemplate', routeToGo: '/classTemplate',  order: 1, group: 2},
                  {name: 'schoolYearClassTemplate', text: `${t('Class')} - ${t('Subject')}`, iconName: 'GiTeacher', entityName: 'SchoolYearClassTemplate',routeToGo: '/schoolYearClassTemplate', order: 2, group: 2}, 
                  {name: 'schoolYearClassTemplateExam', text: t('Exam_s program'), iconName: 'MdOutlineCalendarViewMonth', entityName: 'SchoolYearClassTemplate', routeToGo: '/schoolYearClassTemplateExam',  order: 3, group: 2},
                  
                  {name: 'classExamMark', text: t(`Mark of exam`), iconName: 'IoCheckmarkDoneOutline', entityName: 'SchoolYearClassSubjectMark',routeToGo: '/classExamMark', order: 1, group: 3}, 

                  {name: 'reportCards', text: `${t('Report cards')}`, iconName: 'IoCheckmarkDoneOutline', entityName: 'ReportCard',routeToGo: '/reportCard', order: 2, group: 3}, 
                  
                  
                  //{name: 'requestDataEx', text: t('Request') + ' (Ex)', iconName: 'IoGitPullRequestOutline', entityName: 'RequestData',routeToGo: '/requestDataEx', order: 1, group: 1}, 
                  //{name: 'intermerdiay', text: `${t('Request')}/${t('Intermediary')}`, iconName: 'HiOfficeBuilding', entityName: 'Intermediary',routeToGo: '/intermediary', order: 1, group: 2}, 

                  //{name: 'policy', text: `${t('Policy')}`, iconName: 'FaFileContract', entityName: 'Policy',routeToGo: '/policy', order: 1, group: 3}, 

                  //{name: 'exportation', text: `${t('Exportation')}`, iconName: 'TbDatabaseExport', entityName: 'Exportation',routeToGo: '/exportation', order: 1, group: 3},
                ]
              },
              {
                ...menu6, name: 'finance', text: t('Finance'), iconName:'SiWebmoney', isOpen: false,
                menuItems: [
                  {name: 'schoolYearFinance', text: t('Dashboard'), iconName: 'TbMathSymbols' , entityName: 'SchoolYearFinance',routeToGo: '/schoolYearFinance', order: 1, group: 1}, 
                  {name: 'tellerOperationDashboard', text: `${t('Dashboard')} / ${t('Teller operation')}`, iconName: 'TbMathSymbols' , entityName: 'TellerOperationDashboard',routeToGo: '/tellerOperationDashboard', order: 1, group: 1},      

                  {name: 'billing', text: t('Billing'), iconName: 'FaMoneyBill',entityName: 'Billing',routeToGo: '/billing', order: 1, group: 2},       
                  
                  {name: 'tellerOperation', text: t('Teller operation'), iconName: 'GiReceiveMoney', entityName: 'TellerOperation',routeToGo: '/tellerOperation', order: 1, group: 2},   
                  //{name: 'tellerOperationEx', text: `${t('Teller operation')} (${t('articles')})`, iconName: 'GiReceiveMoney', entityName: 'TellerOperationEx',routeToGo: '/tellerOperationEx', order: 2, group: 1},   

                  {name: 'tellerDayCloging', text: t('Day closing'), iconName: 'TbReportMoney', entityName: 'TellerDayClosing',routeToGo: '/tellerDayClosing', order: 3, group: 2},   
                  //{name: 'paymentOperation', text: 'Payment Operation', entityName: 'Payment Operation',routeToGo: '/', order: 1, group: 2},

                  {name: 'payment', text: t('Payment'), iconName: 'MdMoneyOff', entityName: 'Payment',routeToGo: '/payment', order: 1, group: 3},   
                  {name: 'paymentOperation', text: t('Payment operation'), iconName: 'GiPayMoney', entityName: 'PaymentOperation',routeToGo: '/paymentOperation', order: 2, group: 3},   

                ]
              },
              {
                ...menu7, name: 'more', text: t('More'), iconName:'CgMoreO', isOpen: false,
                menuItems: [
                  // {name: 'editionConfig', text: 'EditionConfig', entityName: 'EditionConfig',routeToGo: '/', order: 1, group: 1},       
                  // {name: 'objectEdition', text: 'ObjectEdition', entityName: 'ObjectEdition',routeToGo: '/', order: 1, group: 1}, 
                  // {name: 'routineObjectData', text: 'RoutineObjectData', entityName: 'RoutineObjectData',routeToGo: '/', order: 1, group: 1},
                  // {name: 'routineFilter', text: 'RoutineFilter', entityName: 'RoutineFilter',routeToGo: '/', order: 1, group: 1},
                  {name: 'smsApi', text: t('SmsApi'), iconName: 'TbMessages', entityName: 'SmsApi',routeToGo: '/smsApi', order: 1, group: 1},
                  {name: 'whatsAppApi', text: t('WhatsAppApi'), iconName: 'TbBrandWhatsapp', entityName: 'ApplicationSetupWhatsApp',routeToGo: '/applicationSetupWhatsApp', order: 2, group: 1},
                  {name: 'mailApi', text: t('MailApi'), iconName: 'TbBrandGmail', entityName: 'ApplicationSetupEmail',routeToGo: '/applicationSetupEmail', order: 3, group: 1},

                  {name: 'job', text: t('Job schedule'), iconName: 'MdScheduleSend', entityName: 'Job',routeToGo: '/job', order: 1, group: 2},

                  //{name: 'certificateTemplate', text: t('Certificate Templ.'), iconName: 'VscNotebookTemplate', entityName: 'CertificateTemplate',routeToGo: '/certificateTemplate', order: 1, group: 3},
                  //{name: 'certificateAllocation', text: t('Certificate Alloc.'), iconName: 'TbFileCertificate', entityName: 'CertificateAllocation',routeToGo: '/certificateAllocation', order: 2, group: 3},

                  //{name: 'hookDataTemplate', text: t('HookData Templ.'), iconName: 'VscNotebookTemplate', entityName: 'HookDataTemplate',routeToGo: '/hookDataTemplate', order: 1, group: 4},
                  //{name: 'hookData', text: t('HookData'), iconName: 'TbFileCertificate', entityName: 'HookData',routeToGo: '/hookData', order: 2, group: 4},

                  {name: 'edition', text: t('Desktop publishing'), iconName: 'TbReportAnalytics', entityName: 'Edition',routeToGo: '/edition', order: 0, group: 3},

                  {name: 'instantSms', text: t('Instant SMS'), iconName: 'TbReportAnalytics', entityName: 'InstantSms',routeToGo: '/instantSms', order: 0, group: 4},
                  
                  // {name: 'postingRule', text: 'PostingRule', entityName: 'PostingRule',routeToGo: '/', order: 1, group: 3},
                ]
              },
              ]
          );
        },[t, i18n]);

        return {
            applicationMenus, setApplicationMenus
        }
  }

  export default _;