

export interface ITeacher {

    id: number,
    
    personId: number,
    registrationNumber: string,
    hiringDate: Date,
    status: string,
    statusDate: Date,

    lastDiploma: string,
    specialization: string,
    diplomaDate: Date,

    hasSubject: boolean,
    isDriver: boolean,

    serviceCode: string,
    functionCode: string,
    employeeType: string,
           
    firstName: string,
    lastName: string,
    particularSign: string,
    birthDate: Date,
    birthPlace: string,
    civility: string,
    gender: string,

    portable1: string,
    portable2: string,
    email1: string,
    email2: string,

    defaultLanguage: string,

    nationality: string,
    identificationType: string,
    identificationNumber: string, 
    identificationDelivrancePlace: string, 
    identificationStartDate: Date, 
    identificationEndDate: Date,
    base64Picture: string,
  }

  export interface ITeacherSheet {
    id: number,
    teacherId: number,
    schoolYearId: number,

    isCancelled: boolean,
    cancellationPurpose: string,
    type: 'disciplinary' | 'absence',
    absenceType: string,
    absencePurpose: string,
    absencePurposeDescription: string,
    justifedAbsence: boolean,
    disciplinaryType: string,
    disciplinaryEventSummary: string,
    disciplinaryEventDescription: string,
    disciplinaryEventDate: Date,
    observationPurpose: string,
    observationDescription: string,
    observationDate: Date
  }

  
  export const defaultTeacher : ITeacher = {
    id: 0,
    
    personId: 0,
    registrationNumber: '',
    hiringDate: new Date(),
    status: '10',
    statusDate: new Date(),

    lastDiploma: '',
    specialization: '',
    diplomaDate: new Date(),
    
    hasSubject: true,
    isDriver: false,

    serviceCode: '',
    functionCode: '',
    employeeType: '',

    firstName: '',
    lastName: '',
    particularSign: '',
    birthDate: new Date(),
    birthPlace: '',
    civility: '',
    gender: '',
    
    portable1: '',
    portable2: '',
    email1: '',
    email2: '',

    defaultLanguage: '',

    nationality: '',
    identificationType: '',
    identificationNumber: '', 
    identificationDelivrancePlace: '', 
    identificationStartDate: new Date(), 
    identificationEndDate: new Date(),

    base64Picture: '',
  }

  export const defaultTeacherSheet : ITeacherSheet = {
    id: 0,
    teacherId: 0,
    schoolYearId: 0,
    
    isCancelled: false,
    cancellationPurpose: '',
    type: 'disciplinary',
    absenceType: '',
    absencePurpose: '',
    absencePurposeDescription: '',
    justifedAbsence: false,
    disciplinaryType: '',
    disciplinaryEventSummary: '',
    disciplinaryEventDescription: '',
    disciplinaryEventDate: new Date(),

    observationPurpose: '',
    observationDescription: '',
    observationDate: new Date()
  }

  export interface ITeacherSearch {
    
    firstName: string,
    lastName: string,        
  }