
export type ClassCouncilType = 'annual' | 'periodic' | 'exceptionnal';

export interface IClassCouncil {
    id: number,

    type: ClassCouncilType,
    
    meetingDate: Date,
    schoolYearClassId: number,
    meetingSummary: string,

    status: string,
    statusDate: Date,
    statusPurpose: string,
    
    schoolYearPeriodId: number,

    schoolYearClassName: string,
    schoolYearPeriodName: string,

    schoolYearId: number,
    classTemplateName: string,
    isActive: boolean,
    classroomName: string,
    studyType: string,
    fullTeacherName: string,
    substituteTeacherName: string,
    countStudents: number,
    countBoys: number,
    countGirls: number,



    classCouncilRegistrations: IClassCouncilRegistration[]
  }

  export interface IClassCouncilRegistration {

    id: number,

    type: ClassCouncilType,

    registrationId: number,
    classCouncilId: number,
    excludedFromSchool: boolean,
    succeededInHigherClass: boolean,
    observation: string,
    exclusionDate: Date,
    exclusionPurpose: string,
    
    disciplinaryMark: number,

    schoolYearClassId: number,
    firstName: string,
    lastName: string,

    exclusionDateInput: string // for file importation only.
  }


  export const defaultClassCouncil : IClassCouncil = {    
    id: 0,

    type: 'annual',
    
    meetingDate: new Date(),
    schoolYearClassId: 0,
    meetingSummary: '',

    status: '95',
    statusDate: new Date(),
    statusPurpose: '',
    
    schoolYearPeriodId: 0,    

    schoolYearClassName: '',
    schoolYearPeriodName: '',

    schoolYearId: 0,
    classTemplateName: '',
    isActive: true,
    classroomName: '',
    studyType: '',
    fullTeacherName: '',
    substituteTeacherName: '',
    countStudents: 0,
    countBoys: 0,
    countGirls: 0,

    classCouncilRegistrations: []
  }


  export const  defaultClassCouncilRegistration : IClassCouncilRegistration = {

    id: 0,

    type: 'annual',

    registrationId: 0,
    classCouncilId: 0,
    excludedFromSchool: false,
    succeededInHigherClass: false,
    observation: '',
    exclusionDate: new Date(),
    exclusionPurpose: '',
    
    disciplinaryMark: 0,

    schoolYearClassId: 0,
    firstName: '',
    lastName: '',

    exclusionDateInput: ''
  }

  export interface IClassCouncilSearch {
    schoolYearId: number,
    name: string,
    studyType: string,
    studyLanguage: string,    
    studyLevel: string,   
  }