import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


export const exportToExcel = (data: any, fileName: string, sheetName?: string) : void => {

    
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(data);
    //const wb = { Sheets: { data: ws }, SheetNames: [sheetName || 'data'] };

    const name = sheetName || 'data';

    const wb = { Sheets: { [name]: ws }, SheetNames: [name] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + fileExtension);
}

export function exportToExcelEx<T>(items: T[], fileName: string, sheetName?: string, columns2Export?: (keyof T)[]) : void  {

    // Filter the data to include only specified columns
    const filteredData = columns2Export
        ? items.map(item => {
            const filteredItem: Partial<T> = {};
            columns2Export.forEach(col => {
                filteredItem[col] = item[col]; // Copy only selected columns
            });
            return filteredItem;
        })
        : items; // If no columns are specified, use all data as-is

    
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(filteredData);
    //const wb = { Sheets: { data: ws }, SheetNames: [sheetName || 'data'] };

    const name = sheetName || 'data';

    const wb = { Sheets: { [name]: ws }, SheetNames: [name] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + fileExtension);
}


export function exportToExcelExMultipleSheets(
    dataWithSheetNames: { items: any[], sheetName: string, columns2Export?: string[] }[],
    fileName: string
  ): void {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    
    const wb: XLSX.WorkBook = { Sheets: {}, SheetNames: [] };
  
    dataWithSheetNames.forEach(({ items, sheetName, columns2Export }) => {

        // If columns2Export is defined, create an empty object to use as headers
        const headerRow = columns2Export
        ? columns2Export.reduce((acc, col) => {
            acc[col] = ''; //col; // Set each column header to its name
            return acc;
        }, {} as Record<string, string>)
        : {};

        const filteredData = columns2Export
        ? (items.length > 0 
            ? items.map(item => {
                const filteredItem: Partial<typeof item> = {};
                columns2Export.forEach(col => {
                filteredItem[col] = item[col]; // Copy only selected columns
                });
                return filteredItem;
            })
            : [headerRow]) // No items, just use the header row
        : items;

      // Filter the data to include only specified columns
    //   const filteredData = columns2Export
    //     ? items.map(item => {
    //         const filteredItem: Partial<typeof item> = {};
    //         columns2Export.forEach(col => {
    //           filteredItem[col] = item[col]; // Copy only selected columns
    //         });
    //         return filteredItem;
    //       })
    //     : items; // If no columns are specified, use all data as-is
  
      // Convert the filtered data to a worksheet
      const ws = XLSX.utils.json_to_sheet(filteredData);
  
      // Ensure the sheet name length is within the limit (31 characters)
      const validSheetName = sheetName.length > 31 ? sheetName.substring(0, 31) : sheetName;
  
      // Add the worksheet to the workbook
      wb.Sheets[validSheetName] = ws;
      wb.SheetNames.push(validSheetName);
    });
  
    // Write the workbook to a buffer
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
    // Save the buffer as an Excel file
    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + fileExtension);
  }

/*
export function exportToExcelExMultipleSheets<T>(
    dataWithSheetNames: { items: T[], sheetName: string, columns2Export?: (keyof T)[] }[],
    fileName: string
  ): void {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      
      const wb: XLSX.WorkBook = { Sheets: {}, SheetNames: [] };
  
      dataWithSheetNames.forEach(({ items, sheetName, columns2Export }) => {
          // Filter the data to include only specified columns
          const filteredData = columns2Export
              ? items.map(item => {
                  const filteredItem: Partial<T> = {};
                  columns2Export.forEach(col => {
                      filteredItem[col] = item[col]; // Copy only selected columns
                  });
                  return filteredItem;
              })
              : items; // If no columns are specified, use all data as-is
  
          // Convert the filtered data to a worksheet
          const ws = XLSX.utils.json_to_sheet(filteredData);
  
          // Ensure the sheet name length is within the limit (31 characters)
          const validSheetName = sheetName.length > 31 ? sheetName.substring(0, 31) : sheetName;
  
          // Add the worksheet to the workbook
          wb.Sheets[validSheetName] = ws;
          wb.SheetNames.push(validSheetName);
      });
  
      // Write the workbook to a buffer
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      
      // Save the buffer as an Excel file
      const blobData = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blobData, fileName + fileExtension);
  }
  */
