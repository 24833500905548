
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IAppreciation, IAppreciationSearch } from "../models/Appreciation";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { currentUserSessionAtom } from 'library/store';

const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createAppreciation = async (appreciation: IAppreciation)  =>       
        await (await axios.post('/api/setup/appreciation/create', appreciation)).data;       
        
    const updateAppreciation = async (appreciation: IAppreciation)  =>       
        await (await axios.post('/api/setup/appreciation/update', appreciation)).data; 
    
    const getAppreciation = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/appreciation/get-appreciation/${id}`));
      return await data;
    }
   

    const getAppreciations = async (criteria: IAppreciationSearch) : Promise<IAppreciation[]> => {

      const {shortName, name, description} = criteria;
      const {data} = (await axios.get(`/api/setup/appreciation/get-appreciations?shortName=${shortName}&name=${name}&description=${description}`));
      return await data;
    }

    const getAppreciationBySystemCode = async (appreciationSystemCode: string) : Promise<IAppreciation[]> => {

      const {data} = (await axios.get(`/api/setup/appreciation/get-appreciations-by-systemCode?appreciationSystemCode=${appreciationSystemCode}`));
      return await data;
    }

    
    
    return {    
      createAppreciation,
      updateAppreciation,
      getAppreciation,
      getAppreciations,

      getAppreciationBySystemCode 
    } 
}

export default _;

export interface IFilterAppreciationOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IAppreciation[], React.Dispatch<React.SetStateAction<IAppreciation[]>>],
}

const defaultFilterProductOption: IFilterAppreciationOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterAppreciation = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IAppreciation) => void,
                                            filterOption?: IFilterAppreciationOption  ) => {

  const { getAppreciations } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;



  const [headAppreciationCells, setHeadAppreciationCells]  = useState<HeadCell<IAppreciation>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'mark', label : t('Mark'),  display: true, type: 'numeric', },
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'shortName', label : t('Short name'),  display: true, type: 'string', },

    {id:'minMark', label : t('Min mark'),  display: true, type: 'numeric', decimalScale: 4 },
    {id:'maxMark', label : t('Max mark'),  display: true, type: 'numeric', decimalScale: 4 },

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [         
      {name: 'shortName', text: t('Short name'), value: ''},
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},      
    ]);    

  const [filteredAppreciations, ] = useState<IAppreciation[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IAppreciation[]> => {
    
    
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const shortName = filterElements.find( elt => elt.name === 'shortName')?.value || '';

    const arr = await getAppreciations( {shortName, name,description} );
    
    return arr;
  }

  const objKey: keyof IAppreciation = 'id';

  return {
    title: t('Appreciation'), headCells: headAppreciationCells, objKey,
    filterElements, rows: filteredAppreciations, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}


