
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';

import SelectAllIcon from '@mui/icons-material/SelectAll';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useTeacherService, { useBasicFilterTeacher } from './services/Teacher';
import useRegistrationService, { useBasicFilterRegistration } from 'features/production/services/Registration';

import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, ToggleButtonGroup, ToggleButton, FormHelperText, Link } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, 
    Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE,
    Enum_CLASS_COUNCIL_EXCLUSION_PURPOSE, Enum_OPERATION_STATUS, Enum_CLASS_COUNCIL_STATUS_PURPOSE} from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';


import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';

import useSchoolYearService, { useBasicFilterSchoolYear, useBasicFilterSchoolYearClass, useBasicFilterSchoolYearClassTemplate } from 'features/setup/services/SchoolYear';
import { ISchoolYear,  defaultSchoolYearClassTemplate, ISchoolYearTemplateSubject, defaultSchoolYear, defaultSchoolYearTemplateSubject,
   ISchoolYearTemplateSubjectExamSchedule, ISchoolYearPeriod, ISchoolYearPeriodExam, 
   ISchoolYearClass} from 'features/setup/models/SchoolYear';

import { ClassCouncilType, IClassCouncil, IClassCouncilRegistration, defaultClassCouncil, defaultClassCouncilRegistration,  } from 'features/setup/models/ClassCouncil';

import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { IClassTemplate } from 'features/setup/models/ClassTemplate';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';



import { getUnixTime } from 'date-fns';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IRegistration } from 'features/production/models/Registration';

import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';
import useClassCouncilService, { useBasicFilterClassCouncil } from './services/ClassCouncil';
import { exportToExcelEx } from 'library/xlsx-export';

export const ClassCouncilForm: FC<IClassCouncil> = (props: IClassCouncil = defaultClassCouncil) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();

  const theme = useTheme();

  const {language: lg, schoolYears, applicationSetup, roleEntities} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer,
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  const { getTeachersBySubjectExamSchedule } = useTeacherService();

  const { getRegistrationsBySchoolYearClass } = useRegistrationService();

  const { updateClassCouncil, createClassCouncil } = useClassCouncilService();

  const { createSchoolYearClass,updateSchoolYearClass, getSchoolYearClassTemplates, getSchoolYearTemplateSubjects,
      getSchoolYearPeriods, getSchoolYearPeriodExams, getSchoolYearClasses, getSchoolYearTemplateSubjectExamSchedules,
      manageSubjectExamSupervisors } = useSchoolYearService();

 
  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const refSchoolYears = useRef<ISchoolYear[]>();

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterClassCouncil = useBasicFilterClassCouncil( 
    (event: React.MouseEvent<unknown>, row: IClassCouncil) => {
        setIsSearchBoxShow(false);

        setValue('schoolYearId', row.schoolYearId);

        _setId(row.id);
      }
  );


  const [openSchoolYearClassFilter, setOpenSchoolYearClassFilter] = useState(false);
  const basicFilterSchoolYearClass = useBasicFilterSchoolYearClass( 
      async (event: React.MouseEvent<unknown>, row: ISchoolYearClass) => {

        const { classTemplateId, classTemplateName, name ,id,  schoolYearId } = row;
              
        setValue('schoolYearClassId', id);
        setValue('schoolYearId', schoolYearId);
        setValue('schoolYearClassName', name);
        setValue('classTemplateName', classTemplateName);
        setValue('classTemplateName', classTemplateName);
                                  
        setOpenSchoolYearClassFilter(false);
      }
  );


  const [openRegistrationFilter, setOpenRegistrationFilter] = useState(false);
  const basicFilterRegistration = useBasicFilterRegistration( 
      async (event: React.MouseEvent<unknown>, row: IRegistration) => {
          const {id,registrationCode, firstName, lastName, schoolYearClassId, schoolYearName, status} = row;

          if(getValues().schoolYearClassId !== schoolYearClassId) {
            enqueueSnackbar( t('This student is not in this class'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
            return;
          }

          if(status !== '10') {
            enqueueSnackbar( `${t('Invalid data !!!')} - ${t('Status')} `, { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
            return;
          }

          if(getValues().classCouncilRegistrations.some(r => r.registrationId === id)) {
            setOpenRegistrationFilter(false);
            return;
          }

          (refAppendClassCouncilRegistrations.current??emptyFunc)({
              ...defaultClassCouncilRegistration, registrationId: id, type: getValues().type,
              schoolYearClassId, firstName, lastName });

          setOpenRegistrationFilter(false);
      }
  );

  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();

  
  //const inputSchoolYear = useRef();
  const refSchoolYearId = useRef<number>(0);
  
  type TeacherUsage = 'full' | 'substitute' | 'subject' | 'supervisor' | 'subject-all';
  const [currentTeacherUsage, setCurrentTeacherUsage] = useState<TeacherUsage>('full');

  

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  
  const emptyFunc = (obj: any) => {}
  
  const methods = useForm<IClassCouncil>({defaultValues:defaultClassCouncil});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchId = watch('id');
  const watchSchoolYearClassId = watch('schoolYearClassId');

  const watchSchoolYearPeriodId = watch('schoolYearPeriodId');

  const watchCouncilType = watch('type');

  // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  
  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IClassCouncil>,Error,IClassCouncil>(
    _id>0?updateClassCouncil:createClassCouncil, {   
        onSuccess: (data: IResult<IClassCouncil>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          setCurrentFormNameAtom(`${t('Class council')} : # ${data.data.id} # ` );
          queryClient.invalidateQueries(['ClassCouncil',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

  
    
    


    const {data: _data, refetch} = useQuery<IClassCouncil>(['ClassCouncil', _id], () => retrieveEntity('ClassCouncil',_id), 
      {refetchOnWindowFocus: false ,enabled: false, } );

    
    const {data: enumItems, refetch: refetchEnumerationItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'SchoolYearClass'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_CLASS_COUNCIL_EXCLUSION_PURPOSE,           
              Enum_OPERATION_STATUS, Enum_CLASS_COUNCIL_STATUS_PURPOSE ] ));
   
    const [schoolYearPeriods, setSchoolYearPeriods] = useState<ISchoolYearPeriod[]>([]);

    const [schoolYearTemplateSubjects, setSchoolYearTemplateSubjects] = useState<ISchoolYearTemplateSubject[]>([]);

    const [schoolYearPeriodExams, setSchoolYearPeriodExams] = useState<ISchoolYearPeriodExam[]>([]);

    const [schoolYearTemplateSubjectExamSchedules, setSchoolYearTemplateSubjectExamSchedules] = useState<ISchoolYearTemplateSubjectExamSchedule[]>([]);

    const [registrations, setRegistrations] = useState<IRegistration[]>([]);
  
    const handleClickOpenSchoolYearClass = async (event: any) => {
      if(getValues().id > 0 && getValues().countStudents > 0) {
        enqueueSnackbar( t('Class can not be changed, you have to add new.'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2500 }); 
        return;
      }
      
      setOpenSchoolYearClassFilter(true);      
    }
    
  
  const getExlusionPurposeList = (row: IClassCouncilRegistration, cellId: keyof IClassCouncilRegistration, 
    opts: {value: string, name: string}[]) => {        
    
    return getAsOptions(refEnumItems.current ?? [],Enum_CLASS_COUNCIL_EXCLUSION_PURPOSE);
  }

  const cellEditableRegistration = (row: IClassCouncilRegistration, cellId: keyof IClassCouncilRegistration) => {
    return true;
  }

  const [headClassCouncilRegistrationCells, setHeadClassCouncilRegistrationCells]  = useState<HeadCell<IClassCouncilRegistration>[]>([]);
  useEffect( () => {
    setHeadClassCouncilRegistrationCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },  
      {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string' },
      {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string' },

      {id:'excludedFromSchool', label : t('Excluded ?'),  display: true, type: 'boolean', width: 15, isEditable: cellEditableRegistration, },
      //{id:'exclusionDate', label : t('Ex. date'),  display: true, type: 'date', width: 10, isEditable: cellEditableRegistration,},
      //{id:'exclusionPurpose', label : t('Ex. purpose'),  display: true, type: 'string', width: 15, 
      // isEditable: cellEditableRegistration, getOptions: getExlusionPurposeList},
      {id:'succeededInHigherClass', label : t('Higher class ?'),  display: true, type: 'boolean', isEditable: cellEditableRegistration, },
      
      {id:'disciplinaryMark', label : t('Disciplinary mark'),  display: watchCouncilType === 'periodic' , type: 'numeric', decimalScale: 2 , isEditable: cellEditableRegistration, },

      //{id:'observation', label : t('Observation'),  display: true, type: 'string', width: 25, isEditable: cellEditableRegistration, },

      
    ]  )
  }, [t,i18n, watchCouncilType])

  const refAppendClassCouncilRegistrations = useRef<(value: Partial<FieldArray<IClassCouncil>> | Partial<FieldArray<IClassCouncil>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateClassCouncilRegistration = useRef<(index: number,value: Partial<FieldArray<IClassCouncil>> ) => void>(null);
  const refRemoveClassCouncilRegistration = useRef<(index: number ) => void>(null);

  const handleAddClassCouncilRegistrations = (event: any) => {

    const {schoolYearClassId} = getValues();    
    if(schoolYearClassId <= 0) return;
    //const registrationIds = getValues().classCouncilRegistrations.map(x => x.registrationId);
       
    setOpenRegistrationFilter(true);
  }

  const handleAddAllActiveStudents = async (event: any) => {
    const {schoolYearClassId, classCouncilRegistrations} = getValues();    
    if(schoolYearClassId <= 0) return;

    const arr = await getRegistrationsBySchoolYearClass(schoolYearClassId);

    const newRegistrations = arr.filter(r => r.status === '10' && 
        !classCouncilRegistrations.some(x => x.registrationId === r.id) );

        console.log(newRegistrations);

    (refAppendClassCouncilRegistrations.current??emptyFunc)(newRegistrations.map( r => (
      {...defaultClassCouncilRegistration, registrationId: r.id, schoolYearClassId: r.schoolYearClassId,
        firstName: r.firstName, lastName: r.lastName } as unknown as IClassCouncilRegistration ) ) );

  }

  const handleDownloadCouncilRegistrations = (event: any) => {
    const {schoolYearClassId} = getValues();    
    if(schoolYearClassId <= 0) return;

    const { classCouncilRegistrations, schoolYearClassName, type } = getValues();

    exportToExcelEx(classCouncilRegistrations, `${t('Class council')}-${schoolYearClassName}-${type}.xlsx`,
      t('Data sheet'), [ lg.includes('en')?'firstName':'lastName', lg.includes('en')?'lastName':'firstName', 'disciplinaryMark',
          'succeededInHigherClass', 'excludedFromSchool', 'exclusionPurpose', 'exclusionDateInput']);

  }
  
  const handleLoadCouncilRegistrations = (event: any) => {
    const {schoolYearClassId} = getValues();    
    if(schoolYearClassId <= 0) return;

  }
  
  
    

  
  const classCouncilRegistrationRowActionIcon = ( schoolYearClass: IClassCouncilRegistration) : ActionIconTableRow<IClassCouncil,IClassCouncilRegistration> => {
  
    const res: ActionIconTableRow<IClassCouncil,IClassCouncilRegistration> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any, index: number, row: IClassCouncilRegistration) => {
        
        
        (refRemoveClassCouncilRegistration.current??emptyFunc)(index);         
      }
    }
    return res;
}

const cellEditableClassSubjectSchedule = (row: IClassCouncilRegistration, cellId: keyof IClassCouncilRegistration) => {
  
  const {type} = row;

  return true;
}


const [currentSchoolYearPeriodId, setCurrentSchoolYearPeriodId] = useState<number>(0);
const handleChangeSchoolYearPeriod = ( event: React.MouseEvent<HTMLElement>, newSchoolYearPeriodId: number ) => {  
  if(isFalsy(newSchoolYearPeriodId) ) 
    return;

  setCurrentSchoolYearPeriodId(newSchoolYearPeriodId);
}


const handleRegistrationDoubleClick = (event: React.MouseEvent<unknown>,index: number,row: IClassCouncilRegistration) => {      
  const roleEntityStudent = roleEntities.find(re => re.entityName === 'Student');
  if(isFalsy(roleEntityStudent)) return;
  
  navigate(`/student/${row.registrationId}`);
}


const handleCouncilType = ( event: React.MouseEvent<HTMLElement>, newCouncilType: ClassCouncilType ) => {

  if(newCouncilType === null) return;
  setValue('type', newCouncilType);
};
  

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems])

  
  useEffect( () => {        
    setCurrentFormNameAtom(`${t('Class council')} ...`);
    setCurrentBasicTextFilterProps(basicFilterClassCouncil);
    //setCurrentBasicTextFilterProps(basicFilterTeacher);
  }, []);    
    
  /********** This use effect call retreive data wich will call refetch and _data will be updated. 
    and the new useEffect will take place ********************/
  useEffect( () => {
      // setCurrentFormName(t('Billing'));        
      
      if(_id > 0)
        retrieveData('ClassCouncil',_id, refetch);  
    }, [_id] );


  useEffect( () => {       
    setCurrentFormNameAtom(`${t('Class council')} ...`);
    if(_data && _data.id > 0) {      
      setCurrentFormNameAtom(`${t('Class council')} : # ${_data.id} # ` ); 
        reset({..._data});          
    }
  }, [_data]);

      
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultClassCouncil);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {    
        if(!checkEntitySaveAuthorization('ClassCouncil', _id)){
          setIsSaveLoading(false);
             return;
        }
        

          const data = getValues(); 
          if(data.schoolYearId <= 0 ) {
              enqueueSnackbar( t('Invalid data !!!'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
            }      
          
          mutate({...data });
      }
    
    const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
      openEntityActionDrawer('ClassCouncil', _id);
    }
    
    
   
    
    
    const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
      openEntityPrintDrawer('ClassCouncil', _id);
    }

    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
      queryClient.invalidateQueries(['ClassCouncil',_id]);        
      await retrieveData('ClassCouncil',_id, refetch);        
      reset(_data);          
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                                
                                <Controller name='schoolYearId' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearId"
                                        label={t('School year')} inputProps={ {readOnly: true}} focused >
                                        {schoolYears && schoolYears.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                 
                                <TextField sx={{width:'calc(50% - 8px)'}} id="classTemplateName" label={t('Study level')} 
                                  {...register('classTemplateName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenSchoolYearClass}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                                />
                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(50% - 8px)'}} id="id" label={t('Class')} 
                                  {...register('schoolYearClassName')} 
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenSchoolYearClass}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}/> 
                               
                                <TextField sx={{width:'calc(50% - 8px)'}} id="classroomName" label={t('Room')} 
                                  {...register('classroomName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                   
                                />
                                                                 
                              { openSchoolYearClassFilter && <FormDialog open={openSchoolYearClassFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Class')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenSchoolYearClassFilter(false);}} onOk={()=> {setOpenSchoolYearClassFilter(false);}}  >
                                      <BasicTextFilterForm<ISchoolYearClass> {...basicFilterSchoolYearClass } />
                                </FormDialog> } 
                                { openRegistrationFilter && <FormDialog open={openRegistrationFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Students')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenRegistrationFilter(false);}} onOk={()=> {setOpenRegistrationFilter(false);}}  >
                                      <BasicTextFilterForm<IRegistration> {...basicFilterRegistration } />
                                </FormDialog> }
                            </Box>
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ToggleButtonGroup value={watchCouncilType} exclusive key={watchCouncilType} size="small" onChange={handleCouncilType} aria-label="text alignment" fullWidth >
                                <ToggleButton value="annual" aria-label="centered">
                                  {t('Annual')}
                                </ToggleButton>
                                <ToggleButton value="periodic" aria-label="centered">
                                  {t('Periodic')}
                                </ToggleButton>       
                                <ToggleButton value="exceptional" aria-label="centered">
                                  {t('Exceptional')}
                                </ToggleButton>               
                              </ToggleButtonGroup>                                                             
                            </Box>  
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller control={control}
                                  name='meetingDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Council date')} 
                                      onChange={onChange}                
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(35% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(18% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="meetingSummary" label={t('Summary')} 
                                  {...register('meetingSummary')} multiline rows={3}
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }                                  
                                />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='status' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(65% - 8px)'}} id="status"
                                        label={t('Status')} inputProps={ {readOnly: true}} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <Controller control={control}
                                  name='statusDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Status date')} 
                                      onChange={onChange} disableOpenPicker readOnly                    
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(35% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(18% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>
                            { !['10','95'].includes(getValues().status) && <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='status' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="status"
                                        label={t('Status')} inputProps={ {readOnly: true}} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_CLASS_COUNCIL_STATUS_PURPOSE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                            </Box>}
                            
                            
                        </Stack>                        
                      </Grid>
                      
                        
                  <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                    <Stack flexDirection='column'>
                      
                      <Box key={`${watchCouncilType} xxx`} sx={{ mt: 3, width: '100%' }} >
                        <ArrayFieldTableEx<IClassCouncil,IClassCouncilRegistration,'id'> 
                            key={`Report field - IReportTableDefaultParameter ${'reportTable.name'}`}
                            mainObject={getValues()} fieldKey='id' 
                            headCells={headClassCouncilRegistrationCells} 
                            rowsPathName={`classCouncilRegistrations`}  
                            title={t(`Students`)} rowActionIcon={classCouncilRegistrationRowActionIcon}  

                            onRowDoubleClick={handleRegistrationDoubleClick}
                            
                            //onRowSelected={handleSchoolYearTemplateSubjectSelected}                                                    
                            refAppend={refAppendClassCouncilRegistrations as MutableRefObject<(value: Partial<FieldArray<IClassCouncil>> | Partial<FieldArray<IClassCouncil>>[], options?: FieldArrayMethodProps) => void>}
                            refUpdate={refUpdateClassCouncilRegistration as MutableRefObject<(index: number,value: Partial<FieldArray<IClassCouncil>>) => void>}
                            refRemove={refRemoveClassCouncilRegistration as MutableRefObject<(index: number) => void>}
                                              //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                            toolbarActions={[
                              { toolTip: `${t('Add')}...`, onClickIcon: handleAddClassCouncilRegistrations ,icon: AddCircleIcon,  },
                              
                              { toolTip: `${t('Add all students')}...`, onClickIcon: handleAddAllActiveStudents ,icon: SelectAllIcon,  },
                              
                              { toolTip: `${t('Download items as Excel file and prepare for upload')}...`, onClickIcon: handleDownloadCouncilRegistrations,icon: FileDownloadIcon,  },

                              { toolTip: `${t('Upload items from file')}...`, onClickIcon: handleLoadCouncilRegistrations,icon: FileUploadIcon,  },

                            ]}
                          />
                        
                      </Box>

                      { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                          {...{open: displayEnumerationItemDialog, 
                                title: '', 
                                enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                afterSave: async () => {await refetchEnumerationItems()}}} 
                          />
                      }
                      
                    </Stack>
                  </Grid>                              
                </Grid>
            </Box>
        </FormProvider> 
  )
}

