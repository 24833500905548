
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import { IRegistrationMark, IRegistrationMarkSearch, ISchoolYearClassReportCard, ISchoolYearClassSubjectMark } from "../models/RegistrationMark";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
import { IRegistration } from '../models/Registration';


const _ = () => {

    const axios = useAxios(); 

    const createRegistrationMark = async (registration: IRegistrationMark)  =>       
        await (await axios.post('/api/production/registration/create', registration)).data;       
        
    const updateRegistrationMark = async (registration: IRegistrationMark)  =>       
        await (await axios.post('/api/production/registration/update', registration)).data; 


    const saveSchoolYearClassSubjectMark = async (schoolYearClassSubjectMark: ISchoolYearClassSubjectMark)  =>       
        await (await axios.post('/api/production/registration/save-schoolYear-class-subject-mark', schoolYearClassSubjectMark)).data;       
        
    const saveSchoolYearClassReportCard = async (schoolYearClassReportCard: ISchoolYearClassReportCard)  =>       
      await (await axios.post('/api/production/registration/save-schoolYear-class-report-card', schoolYearClassReportCard)).data;       

    // const updateSchoolYearClassSubjectMark = async (schoolYearClassSubjectMark: ISchoolYearClassSubjectMark)  =>       
    //     await (await axios.post('/api/production/registration/update-schoolYear-class-subject-mark', schoolYearClassSubjectMark)).data; 
    
    // const getRegistrationMark = async (id  : number )  => {
    //   const {data} = (await axios.get(`/api/production/registration/get-registration/${id}`));
    //   return await data;
    // }
   
    const getRegistrationMarks = async (criteria: IRegistrationMarkSearch) : Promise<IRegistrationMark[]> => {

      const { firstName, lastName} = criteria;

      const {data} = (await axios.get(`/api/production/registration/get-registrations?firstName=${firstName}&lastName=${lastName}`));
      return await data;
    }

    const getMarks = async (reportCard: string, registrationId: number, schoolYearClassId: number, schoolYearId: 
                number ,schoolYearPeriodId: number, schoolYearPeriodExamId: number) : Promise<IRegistration[]> => {
  
        const {data} = (await axios.get(`/api/production/registration/get-marks-by-registration?reportCard=${reportCard}&registrationId=${registrationId}&schoolYearClassId=${schoolYearClassId}
          &schoolYearId=${schoolYearId}&schoolYearPeriodId=${schoolYearPeriodId}&schoolYearPeriodExamId=${schoolYearPeriodExamId}`));
        return await data;
      }
       
    
      
    return {    
      createRegistrationMark,
      updateRegistrationMark,

      saveSchoolYearClassSubjectMark,
      saveSchoolYearClassReportCard,

      getMarks,
      //updateSchoolYearClassSubjectMark,

      // getRegistrationMark,
      getRegistrationMarks
      
    } 
}

export default _;

export interface IFilterRegistrationMarkOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IRegistrationMark[], React.Dispatch<React.SetStateAction<IRegistrationMark[]>>],
}

const defaultFilterRegistrationMarkOption: IFilterRegistrationMarkOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterRegistrationMark = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IRegistrationMark) => void,
                                            filterOption?: IFilterRegistrationMarkOption  ) => {

  const { getRegistrationMarks } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterRegistrationMarkOption;



  const [headRegistrationMarkCells, setHeadRegistrationMarkCells]  = useState<HeadCell<IRegistrationMark>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },    
    {id:'firstName', label : t('First name'),  display: true, type: 'string', },
    {id:'lastName', label : t('Last name'),  display: true, type: 'string', },
    
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [         
      
      {name: 'firstName', text: t('First name'), value: ''},
      {name: 'lastName', text: t('Last name'), value: ''},
      
    ]);    

  const [filteredRegistrationMarks, ] = useState<IRegistrationMark[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IRegistrationMark[]> => {
    
    
    const firstName = filterElements.find( elt => elt.name === 'firstName')?.value || '';
    const lastName = filterElements.find( elt => elt.name === 'lastName')?.value || '';       

    const arr = await getRegistrationMarks( { firstName,lastName} );
   
    return arr;
  }

  const objKey: keyof IRegistrationMark = 'id';

  return {
    title: t('Registration'), headCells: headRegistrationMarkCells, objKey,
    filterElements, rows: filteredRegistrationMarks, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
